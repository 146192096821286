// Import necessary components and styles
import React from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import formatTimestamp from "../lib/formatTimestamp";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";


export default function UserDisplay({ userData,post }) {
  // Check if userData is defined before accessing properties
  if (!userData) {
    return null; // or return a loading state/component
  }

console.log("Post in userDisplay",post);

  return (
    <div className="d-flex align-items-center p-2">
      {/* Avatar */}
      <div className="avatar card-title me-1" style={{ height: "26px", width: "26px" }}>
      <Link to={`/userProfile/${userData.id}`}>
          <ProfilePicture userData={userData} className='avatar-img rounded-circle' />
     </Link>
       </div>
      {/* Info */}
      <div>
        <div className="nav nav-divider d-flex flex-column">
          <h6 className="nav-item card-title mb-0">
            <Link
              to={`/userProfile/${userData.id}`}
           
            >
              {userData.userName
                ? userData.userName
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : ""}
            </Link>
          </h6>
          {/* <span className="small"> 12-12-2024</span> */}
          <span className=" small">
            {" "}
            {formatTimestampForPost(post?.dateCreated)} 
          </span>
        </div>
        {/* <p className="mb-0 small">
      {userData.userTitle ? userData.userTitle : `Member since ${formatTimestamp(userData.timestamp)}`}
    </p> */}
      </div>
    </div>
  );

  
}
