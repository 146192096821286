import React, { useState, useEffect } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig"; // Adjust the import path as necessary
import { Typography } from 'antd';
import referralLink from '../lib/referralLink';

const { Paragraph } = Typography;
const InviteClicks = ({ userId }) => {
 const [inviteClicks, setInviteClicks] = useState(0);
 const [referralCode, setReferralCode] = useState("");
 const [loading, setLoading] = useState(true);

 const referralLinkURL = referralLink(referralCode)

 useEffect(() => {
    const fetchInviteClicks = async (userId) => {
      try {
        if (userId) {
          const userDocRef = doc(db, "users", userId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const clicks = userData?.inviteClicks || 0;
            setInviteClicks(clicks);
            setReferralCode(userData?.referralCode);
          } else {
            console.log("User document does not exist");
          }
        } else {
          console.log("Invalid userId");
        }
      } catch (error) {
        console.error("Error getting invite clicks:", error);
      }finally {
        setLoading(false); // Set loading to false when fetching is completed
      }
    };

    fetchInviteClicks(userId);
 }, [userId]); // Depend on userId to refetch if it changes

 return (
    <div>
      <div className='d-flex justify-content-between'>
      <h5>Invite Clicks</h5>
      {!loading && <Paragraph copyable={{ text: `${referralLinkURL}` }}>Copy invite link </Paragraph>}
      </div>
      <p>Number of invite clicks: {inviteClicks}</p>
    </div>
 );
};

export default InviteClicks;
