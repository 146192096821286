import Glightbox from "glightbox";
import { useEffect, useState } from "react";
import "../NewsFeed/Glight.css";
import { Link } from "react-router-dom";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import { getDisplayName } from "../lib/getSupplierDisplayName";
import useActiveBrandsStore from "../StatesStore/ActiveBrands";
import formatPrice from "../lib/formatPrice";

const GlightImage = ({ product, image, imageIndex }) => {
  const dynamicDescClass = `custom-desc${product.id + imageIndex}`;
  const [brandData, setBrandData] = useState(null);
  const [hasLoadedBrandData, setHasLoadedBrandData] = useState(false);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  //for detching brands logo
  useEffect(() => {
    fetchBrandData();
  }, []);
  const fetchBrandData = async () => {
    const brandRef = doc(db, "brands", product?.supplier);
    try {
      const brandSnapshot = await getDoc(brandRef);
      if (brandSnapshot.exists()) {
        setBrandData(brandSnapshot.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
    setHasLoadedBrandData(true);
  };
  //For lightbox creation  */}
  useEffect(() => {
    if (brandData) {
      // Once brandData is loaded, initialize GLightbox
      const lightbox = Glightbox({
        selector: `[data-morepostglightbox="${product}"]`,
      });
      const batch = writeBatch(db);
      const incrementViews = {
        views: product.views ? product.views + 1 : 1,
      };
      batch.update(doc(db, "products", product?.id), incrementViews);
      batch.commit();
      // Clean up the lightbox instance when the component is unmounted
      return () => {
        lightbox.destroy();
      };
    }
  }, [brandData, product.id, imageIndex]);
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Link
        to={product?.imageUrl}
        data-gallery="image-popup"
        className="glightbox"
        data-morepostglightbox={product}
        data-glightbox={`description: .${dynamicDescClass}; descPosition: left;`}
      >
        <img
          className="rounded img-fluid"
          src={product?.imageUrl}
          alt=""
          style={{ height: "100%", width: "100%", objectFit: "cover" }}
        />
      </Link>
      {/**Glight body start */}
      <div className={`glightbox-desc ${dynamicDescClass}`}>
        {/**Top Bar in Sidebar */}
        <div className="d-flex align-items-center justify-content-between">
          {/**Post Owner */}
          <div className="d-flex align-items-center">
            {/**Post owner Image */}
            <div className="avatar me-2">
              <Link to={"/BrandHome/" + product?.supplier}>
                <img
                  className="avatar-img rounded-circle"
                  src={`assets/images/brands/${brandData?.logo}`}
                  alt="Brand profile"
                />
              </Link>
            </div>
            {/**Post owner details */}
            <div>
              <div className="nav nav-divider">
                {/**Post owner Name */}
                <h6 className="nav-item card-title mb-0">
                  {getDisplayName(product?.supplier, activeBrands)}
                </h6>
              </div>
              {/**Any tagline / status line if there is in bio of post owner  */}
              <p className="mb-0 small">
                <span className="nav-item small">
                  {formatTimestampForPost(product?.dateCreated)}
                </span>
              </p>
            </div>
          </div>
        </div>
        {/**Post Caption */}
        <h6 className="mt-3 text-wrap "> {product?.name} </h6>
        <div className="pt-1">
          {product?.discount ? (
            <ul className="nav nav-stack small mt-1">
              {/*If sdiscount  */}
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                Rs. {formatPrice(product?.newPrice)}Pkr
              </li>
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                <strike
                  style={{
                    textDecoration: "line-through",
                    textDecorationColor: "red",
                  }}
                >
                  Rs. {formatPrice(product?.oldPrice)}
                </strike>
              </li>
              <li
                className="nav-item badge bg-danger text-white ms-auto"
                style={{ fontSize: "0.85rem" }}
              >
                {product?.discount}% off
              </li>
            </ul>
          ) : (
            <ul
              className="nav nav-stack small mt-1"
              style={{ fontSize: "0.85rem" }}
            >
              {/*If no discount  */}
              <li className="nav-item">
                Price: Rs. {formatPrice(product?.newPrice)}
              </li>
            </ul>
          )}
          {/* Category and subcategory */}
          <ul className="nav nav-stack small mx-auto mt-1">
            {/*If sdiscount  */}
            {product?.category ? (
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                Category: {product?.category}
                <span style={{ fontSize: "0.80rem" }}>
                  {" "}
                  {product?.subCategory}{" "}
                  <span style={{ fontSize: "0.75rem" }}>
                    {" "}
                    {product?.subSubCategory}
                  </span>{" "}
                </span>
              </li>
            ) : null}
          </ul>
          {/* Category and subcategory */}
          <ul className="nav nav-stack small mt-1">
            <li className="nav-item d-flex flex-grow-1">
              <Link
                to={`/ProductDetails/${product?.id}`}
                className="dropdown-item btn btn-primary-soft btn-sm my-0 text-center py-2"
              >
                Visit
              </Link>
            </li>
          </ul>
          {/**Like comment Share options */}
          <ul className="nav nav-stack mt-1 small">
            {/**Like Button */}
            <li className="nav-item">
              <i className="bi bi-hand-thumbs-up-fill pe-1"></i> (
              {product?.likes})
            </li>
            {/**Comment button  */}
            <li className="nav-item">
              <i className="bi bi-eye-fill pe-1"></i>({product?.views})
            </li>
            <li className="nav-item">
              <i className="bi bi-star-fill pe-1"></i> (
              {product?.favourites ?? 0})
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default GlightImage;
