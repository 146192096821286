import React from "react";
import { SideNavPriceRangeDropDown ,SideNavGenderDropDown ,SideNavBrandDropdown} from "./SideNavSearchDropDown";

export default function SearchPageSideNav() {
// console.log('Results are',Result);
  return (
    <div className="offcanvas-body d-block bg-mode rounded-2 p-0" >
      {/* Card START */}
      <p className="fw-semibold m-0 fs-2 px-4 py-4">Filter &nbsp;</p>
      {/* GENDER */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseGender"
          aria-expanded="false"
          aria-controls="collapseGender"
        >
          Gender &nbsp;
        </p>
          <SideNavGenderDropDown dataId={"collapseGender"} />
      </div>
      <hr className="m-0" />

      

      {/* PRICE RANGE */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapsePriceRange"
          aria-expanded="false"
          aria-controls="collapsePriceRange"
        >
          Price &nbsp;
        </p>
          <SideNavPriceRangeDropDown dataId={"collapsePriceRange"} />
      </div>
      <hr className="m-0" />
      
{/* BRANDS */}
<div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseBrands"
          aria-expanded="false"
          aria-controls="collapseBrands"
        >
          Brand &nbsp;
        </p>
          <SideNavBrandDropdown dataId={"collapseBrands"} />
      </div>
      <hr className="m-0" />
     
    </div>
  );
}
