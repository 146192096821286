import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig"; // Adjust the import path as necessary

const InvitesList = ({ userId }) => {
 const [invites, setInvites] = useState([]);

 useEffect(() => {
    const fetchInvites = async (userId) => {
      try {
        const invitesRef = collection(db, "users", userId, "invites");
        const q = query(invitesRef, orderBy("dateCreated", "desc"));
        const querySnapshot = await getDocs(q);
        const invitesData = querySnapshot.docs.map(doc => ({
          email: doc.data().email,
          dateCreated: doc.data().dateCreated.toDate() // Convert to Date object for easier handling
        }));
        setInvites(invitesData);
      } catch (error) {
        console.error("Error fetching invites:", error);
      }
    };

    fetchInvites(userId);
 }, [userId]); // Depend on userId to refetch if it changes

 return (
    <div>
      <h5>Invite Joined</h5>
      {invites.length > 0 ? (
        invites.map((invite, index) => (
          <div key={index} className='d-flex justify-content-between'>
            <p>Email: {invite.email}</p>
            <p>Joined on: {invite.dateCreated.toLocaleDateString('en-GB', { year: '2-digit', month: '2-digit', day: '2-digit' })}</p>
          </div>
        ))
      ) : (
        <p>No invites found.</p>
      )}
    </div>
 );
};

export default InvitesList;
