import React, { useEffect, useState } from "react";
import { collection, getDocs, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { db } from "../FirebaseConfig";

import AddInvitedUser from "./AddInvitedUser";
import JoinedUser from "./JoinedUser";
import PostLike from "./PostLike";
import PostComment from "./PostComment";
import FollowedMe from "./FollowedMe";
import ShoppingListChatMessage from "./ShoppingListChatMessage";
import { Link } from "react-router-dom";
import FirstTimeListView from "./FirstTimeListView";
import ListProductLike from "./ListProductLike";

function Notification({ user }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (!user) return;
  
    const notificationsRef = collection(db, `users/${user.uid}/notifications`);
    const q = query(notificationsRef, orderBy("timestamp", "desc"), limit(5));
  
    // Set up real-time listener for notifications
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        const notificationData = querySnapshot.docs.map((docSnapshot) => ({
          notificationId: docSnapshot.id, // Get the document ID along with the data
          ...docSnapshot.data(),
        }));
        setNotifications(notificationData); // Update state with new data
      },
      (error) => {
        console.error("Error fetching notifications in real-time: ", error);
      }
    );
  
    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [user]);
  console.log("notificationData 1",notifications);
  return (
    <li className="nav-item dropdown ms-2">
      <a
        className="nav-link icon-md btn btn-light p-0"
        href="#"
        id="notifDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="outside"
      >
        {notifications.length > 0 && <span className="badge-notif animation-blink"></span>}
        <i className="bi bi-bell-fill fs-6"></i>
      </a>
      <div
        className="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0"
        aria-labelledby="notifDropdown"
      >
        <div className="card">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h6 className="m-0">
              Notifications{" "}
              <span className="badge bg-danger bg-opacity-10 text-danger ms-2">
                {notifications.length} new
              </span>
            </h6>
            {/* <a className="small" href="#">
              Clear all
            </a> */}
          </div>
          <div className="card-body p-0">
            {notifications.length >0 && <ul className="list-group list-group-flush list-unstyled p-2">
              {notifications.map((notification) => {
                if (notification.type === "add") {
                  return (
                    <AddInvitedUser
                      notification={notification}
                      user={user}
                    />
                  );
                } else if (notification.type === "joined") {
                  return (
                    <JoinedUser
                      notification={notification}
                    />
                  );
                }else if (notification.type === "postLike") {
                  return (
                    <PostLike
                      notification={notification}
                    />
                  );
                }
                else if (notification.type === "postComment") {
                  return (
                    <PostComment
                      notification={notification}
                    />
                  );
                }else if (notification.type === "followed") {
                  return (
                    <FollowedMe
                      notification={notification}
                    />
                  );
                }else if (notification.type === "listChat") {
                  return (
                    <ShoppingListChatMessage
                      notification={notification}
                    />
                  );
                }else if (notification.type === "firstTimeListView") {
                  return (
                    <FirstTimeListView
                      notification={notification}
                    />
                  );
                }
                else if (notification.type === "listProductLike") {
                  return (
                    <ListProductLike
                      notification={notification}
                    />
                  );
                }
                return null; // Ensure that a valid element is returned for other types
              })}
            </ul>}
          </div>
          {notifications.length > 4 && <div className="card-footer text-center">
            <Link to="/notifications" className="btn btn-sm btn-primary-soft">
              See all notifications
            </Link>
          </div>}
        </div>
      </div>
    </li>
  );
}

export default Notification;
