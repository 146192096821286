import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { UserAuth } from "../Context";
import { db } from "../FirebaseConfig";
import BrandPosts from "./BrandPosts";
import BrandsHomeSideNav from "../SideNav/BrandsPageSideNav";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import SideNavSearch from "../SideNav/SideNavSearch";
import SideNavBrands from "../SideNav/SideNavBrands";
import DiscountCount from "./DiscountCount";
import BrandProducts from "./BrandProducts";
import handlefollowBrand from "../hooks/brand/followBrand";
import handleUnfollowBrand from "../hooks/brand/unfollowBrand";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useModalStore from "../StatesStore/ModalStore";
import GoToTop from "../components/gotoTop";

function BrandHome() {
  let { brandName } = useParams() || "";
  const { user } = UserAuth();
  const [brandInfo, setBrandInfo] = useState();
  const [womenCategories, setWomenCategories] = useState(null);
  const [menCategories, setMenCategories] = useState(null);
  const [showBrandPosts, setShowBrandPosts] = useState(false);
  const collectionRef = collection(db, "brands");
  const [FollowButton, setFollowButton] = useState(false);
  const [FollowerCount, setFollowerCount] = useState(0);
  const { openModal } = useModalStore();
  const renderDropdownItems = (categories, category) => {
    return Object.keys(categories)
      .sort()
      .map((categoryName) => (
        <li
          className="dropdown-submenu dropstart "
          key={categoryName}
          onClick={() => setShowBrandPosts(false)}
        >
          <button
            className="dropdown-item dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {categoryName}
          </button>
          <ul className="dropdown-menu dropdown-menu-end" data-bs-popper="none">
            {categories[categoryName]
              .filter(
                (subCategory) =>
                  subCategory.name !== "None" &&
                  subCategory.displayName !== "None"
              )
              .map((subCategory, index) => (
                <li key={index}>
                  <Link
                    className="dropdown-item"
                    to={`/BrandHome/${brandName}/${category}/${categoryName}/${subCategory.name}`}
                  >
                    {subCategory.displayName}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      ));
  };
  const fetchData = async (brandname) => {
    try {
      console.log("brandname:", brandname);
      if (!brandname) {
        console.error("brandname is undefined or null");
        return;
      }

      if (!user?.uid) {
        console.error("User ID is undefined or null");
        return;
      }

      const checkBrandSnapshot = doc(
        db,
        "users",
        user?.uid,
        "following",
        brandname
      );
      const checkBrandData = await getDoc(checkBrandSnapshot);

      if (checkBrandData.exists()) setFollowButton(true);
      else setFollowButton(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCount = async () => {
    const brandFollowerRef = collection(collectionRef, brandName, "followers");
    const brandFollowerData = await getDocs(brandFollowerRef);
    setFollowerCount(brandFollowerData.size);
  };
  useEffect(() => {
    const getBrandDetails = async () => {
      const q = query(collectionRef, where("name", "==", brandName));
      const snapshot = await getDocs(q);
      const brandDoc = snapshot.docs[0];
      setBrandInfo(snapshot.docs[0].data());

      // Increment views in the database
      const brandDocRef = doc(db, "brands", brandDoc.id);
      await updateDoc(brandDocRef, {
        views: brandDoc.data().views + 1,
      });
    };
    getBrandDetails();
    fetchData(brandName);
    fetchCount();
  }, [brandName]);

  useEffect(() => {
    setWomenCategories(null);
    setMenCategories(null);

    const getCategories = async () => {
      // if(user){
      //   const batch = writeBatch(db);
      //   const userRef = doc(db, 'users', user.uid);
      //   const userLogsUpdate = {};
      //   const brandname  = brandName;
      //   const timestamp = new Date(); // Get server timestamp
      //   const likedProduct = { timestamp, brandname };
      //   userLogsUpdate[`userlogs.visitedBrand`] = arrayUnion(likedProduct);
      //   batch.update(userRef, userLogsUpdate);
      //   await batch.commit();
      // }

      // console.log('Visiting Brand PAgeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );

      // Check if "Women" is present in brandInfo categories
      if (
        brandInfo?.category.includes("Women") &&
        brandInfo?.category.includes("Men")
      ) {
        // If both Men and Women categories are present, render separate dropdowns
        setWomenCategories(categoriesData[1]);
        setMenCategories(categoriesData[0]);
      } else if (brandInfo?.category.includes("Women")) {
        // If only Women category is present, set categories to Women

        setWomenCategories(categoriesData[1]);
      } else if (brandInfo?.category.includes("Men")) {
        // If only Men category is present, set categories to Men

        setMenCategories(categoriesData[0]);
      }
    };

    getCategories();
  }, [brandInfo]);

  useEffect(() => {
    fetchData(brandName);
  }, [FollowButton]);
  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", "", ""); // Open modal here
    } else {
      const DATA = {
        displayName: brandInfo.displayName,
        FollowButton: setFollowButton,
        FetchCount: fetchCount,
        UID: user.uid,
        brandName: brandName,
      };
      openModal("ConfirmUnfollow", "brand", DATA); // Open modal here
    }
  };

  return (
    <>
      <GoToTop />
      <SideNavBrands brandName={brandName} />
      <div
        className="col-md-8 col-lg-6 vstack gap-4"
      >
        <div className="card">
          {/* Cover image */}
          <div
            className="h-200px rounded-top"
            style={{
              backgroundImage: `url(${"../assets/images/brands/" +
                brandInfo?.cover})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          {/* Card body START */}
          <div className="card-body py-0">
            <div className="d-sm-flex align-items-start text-center text-sm-start">
              <div>
                {/* Avatar */}
                <div className="avatar avatar-xxl mt-n5 mb-3">
                  <img
                    className="avatar-img rounded-circle border border-white border-3"
                    src={"../assets/images/brands/" + brandInfo?.logo}
                    alt=""
                  />
                </div>
              </div>
              <div className="ms-sm-4 mt-sm-3">
                {/* Info */}
                <h1 className="mb-0 h5">{brandInfo?.displayName}</h1>
                <div className="d-flex">
                  <p>
                    <DiscountCount brandName={brandName} /> Products
                  </p>
                  <p className="ms-2">{FollowerCount} Followers</p>
                </div>
              </div>
              {/* AdSense Location */}
              <div className="d-flex mt-3 justify-content-end ms-sm-auto">
                <Link
                  className="btn btn-primary me-2"
                  onClick={async () => {
                    if (!user) handleClick();
                    else {
                      if (FollowButton) {
                        // await handleUnfollowBrand(user?.uid, brandName);
                        // setFollowButton(false);
                        // fetchCount();
                        handleClick();
                      } else {
                        await handlefollowBrand(user?.uid, brandName);
                        setFollowButton(true);
                        fetchCount();
                        toast.success(
                          "Brand followed successfully",
                          toastOptions
                        );
                      }
                    }
                  }}
                >
                  {FollowButton ? "Unfollow" : "Follow"}
                </Link>
                {/* </div> */}
                {/* AdSense Location */}
                {/* <div className="d-flex mt-3 justify-content-center ms-sm-auto"> */}
              </div>
            </div>
          </div>
          {/* Card body END */}
          {/* <div className="card-footer mt-3 pt-2 pb-0 ">
      

            <nav className="navbar navbar-expand-lg">
              <div
                className="container"
                style={{
                  zIndex: 34,
                }}
              >
      
                <ul className="navbar-nav navbar-nav-scroll ms-auto">
       
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      onClick={() => setShowBrandPosts(true)}
                    >
                      Latest
                    </Link>
                  </li>
                </ul>
       
              </div>
            </nav>
          </div> */}
        </div>
        {showBrandPosts && <BrandPosts />}
        {!showBrandPosts && <BrandProducts brandName={brandName} />}
      </div>
    </>
  );
}

export default BrandHome;
