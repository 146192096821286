const handlefollowUser = async (senderId, receiverId) => {
  //  console.log('Sender of request', senderId);
  //  console.log('Receiver of request (user to follow)', receiverId);

  try {
    // Check if senderId and receiverId are the same
    if (senderId === receiverId) {
      console.log("Cannot follow yourself");
      return false;
    }
    const response = await fetch(
      process.env.REACT_APP_API + "/api/followUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ senderId, receiverId }),
      }
    );

    await response.json();
    console.log("followed user successfully!");
  } catch (error) {
    console.error("Error updating document: ", error);
  }

  return true;
};
export default handlefollowUser;
