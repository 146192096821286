import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../FirebaseConfig";

// Function to check if the email already exists in the 'users' collection
export const checkIfEmailExists = async (email) => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);
  
  if (!querySnapshot.empty) {
    // Extract document ID (userId) and firstName
    const userData = querySnapshot.docs[0]; // Assuming you're looking for the first match
    const user = userData.data();
    return {
      exists: true,
      userId: userData.id,   // document id is now userId
      firstName: user.firstName,
    };
  } else {
    return { exists: false };
  }
};
