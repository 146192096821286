import { getCookie, setCookie } from "./referralCodeInCookie";
import { getStoredReferralCodesFromLocalStorage } from "./referralCodeInLocalStorage";

export function getAllStoredReferralCodes() {
    let cookieCodes = JSON.parse(getCookie("referralCodes")) || [];
    let localStorageCodes = getStoredReferralCodesFromLocalStorage();

    // Combine unique codes from both cookie and local storage
    let allCodes = [...new Set([...cookieCodes, ...localStorageCodes])];
    return allCodes;
}
function clearStoredReferralCodes() {
    localStorage.removeItem('referralCodes');
}
export function clearAllStoredReferralCodes() {
    // Clear referral codes from cookies
    setCookie("referralCodes", "", -1); // Setting the cookie with a negative expiration will delete it
    document.cookie = "referralCodes=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // Clear referral codes from local storage
    clearStoredReferralCodes();
}