export function setCookie(name, value) {
    document.cookie = name + "=" + (value || "") + "; path=/";
}

export function storeReferralCodeInCookie(referralCode) {
    let existingCodes = getCookie("referralCodes");
    if (existingCodes) {
        existingCodes = JSON.parse(existingCodes);
    } else {
        existingCodes = [];
    }
    
    if (!existingCodes.includes(referralCode)) {
        existingCodes.push(referralCode);
        setCookie("referralCodes", JSON.stringify(existingCodes));
    }
}

export function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
