import { collection, onSnapshot, orderBy, query, limit, startAfter, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { UserAuth } from '../Context';
import { db } from '../FirebaseConfig';
import AddInvitedUser from './AddInvitedUser';
import JoinedUser from './JoinedUser';
import PostLike from './PostLike';
import PostComment from './PostComment';
import FollowedMe from './FollowedMe';
import ShoppingListChatMessage from './ShoppingListChatMessage';
import FirstTimeListView from './FirstTimeListView';

const PAGE_LIMIT = 8;

function AllNotifications() {
  const { user } = UserAuth();
  const [notifications, setNotifications] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // Track if there are more notifications

  const fetchNotifications = async (startAfterDoc = null) => {
    setLoading(true);
    
    const notificationsRef = collection(db, `users/${user.uid}/notifications`);
    let q = query(notificationsRef, orderBy("timestamp", "desc"), limit(PAGE_LIMIT));
    
    if (startAfterDoc) {
      q = query(notificationsRef, orderBy("timestamp", "desc"), startAfter(startAfterDoc), limit(PAGE_LIMIT));
    }

    try {
      const querySnapshot = await getDocs(q);
      const notificationData = querySnapshot.docs.map((docSnapshot) => ({
        notificationId: docSnapshot.id,
        ...docSnapshot.data(),
      }));

      setNotifications(prevNotifications => [
        ...prevNotifications,
        ...notificationData
      ]);

      // Update the last visible document for pagination
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc);
      
      // Determine if there are more notifications to fetch
      setHasMore(notificationData.length === PAGE_LIMIT);
    } catch (error) {
      console.error("Error fetching notifications: ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (user) {
      fetchNotifications(); // Fetch initial notifications
    }
  }, [user]);

  const handleLoadMore = () => {
    if (lastVisible && hasMore) {
      fetchNotifications(lastVisible);
    }
  };

  return (
    <>
      <div className="container mt-5">
        <div className="row g-4">
          {/* <!-- Main content START --> */}
          <div className="col-lg-8 mx-auto">
            {/* <!-- Card START --> */}
            <div className="card">
              <div className="card-header py-3 border-0 d-flex align-items-center justify-content-between">
                <h1 className="h5 mb-0">Notifications</h1>
                {/* <!-- Notification action START --> */}
                <div className="dropdown">
                  <a href="#" className="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardNotiAction" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="bi bi-three-dots"></i>
                  </a>
                  {/* <!-- Card share action dropdown menu --> */}
                  {/* <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction">
                    <li><a className="dropdown-item" href="#"> <i className="bi bi-check-lg fa-fw pe-2"></i>Mark all read</a></li>
                    <li><a className="dropdown-item" href="#"> <i className="bi bi-bell-slash fa-fw pe-2"></i>Push notifications </a></li>
                    <li><a className="dropdown-item" href="#"> <i className="bi bi-bell fa-fw pe-2"></i>Email notifications </a></li>
                  </ul> */}
                </div>
                {/* <!-- Notification action END --> */}
              </div>
              <div className="card-body p-2">
                {notifications.length > 0 && (
                  <ul className="list-unstyled p-2">
                    {notifications.map((notification) => {
                      if (notification.type === "add") {
                        return (
                          <AddInvitedUser
                            key={notification.notificationId}
                            notification={notification}
                            user={user}
                          />
                        );
                      } else if (notification.type === "joined") {
                        return (
                          <JoinedUser
                            key={notification.notificationId}
                            notification={notification}
                          />
                        );
                      } else if (notification.type === "postLike") {
                        return (
                          <PostLike
                            key={notification.notificationId}
                            notification={notification}
                          />
                        );
                      } else if (notification.type === "postComment") {
                        return (
                          <PostComment
                            key={notification.notificationId}
                            notification={notification}
                          />
                        );
                      } else if (notification.type === "followed") {
                        return (
                          <FollowedMe
                            key={notification.notificationId}
                            notification={notification}
                          />
                        );
                      } else if (notification.type === "listChat") {
                        return (
                          <ShoppingListChatMessage
                            key={notification.notificationId}
                            notification={notification}
                          />
                        );
                      } else if (notification.type === "firstTimeListView") {
                        return (
                          <FirstTimeListView
                            key={notification.notificationId}
                            notification={notification}
                          />
                        );
                      }
                      return null; // Ensure that a valid element is returned for other types
                    })}
                  </ul>
                )}
              </div>
              {hasMore && (
                <div className="card-footer border-0 py-3 text-center position-relative d-grid pt-0">
                  {/* <!-- Load more button START --> */}
                  <a
              
                    role="button"
                    className={`btn btn-loader btn-primary-soft ${loading ? 'disabled' : ''}`}
                    onClick={handleLoadMore}
                  >
                    <span className="load-text">
                      {loading ? 'Loading...' : 'Load more notifications'}
                    </span>
                    <div className="load-icon">
                      <div className="spinner-grow spinner-grow-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </a>
                  {/* <!-- Load more button END --> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllNotifications;
