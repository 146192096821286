const handleUnfollowUser = async (senderId, receiverId) => {
  // console.log('Sender of request', senderId);
  // console.log('Receiver of request (user to unfollow)', receiverId);

  try {
    const response = await fetch(
      process.env.REACT_APP_API + "/api/unfollowUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ senderId, receiverId }),
      }
    );

    await response.json();
    console.log("unfollowed user successfully!");
  } catch (error) {
    console.error("Error updating document: ", error);
  }

  return true;
};
export default handleUnfollowUser;
