import React from "react";
import { Route, Routes, useParams } from "react-router-dom";
import UserActivity from "./UserActivity";
import UserAbout from "./UserAbout";
import UserPosts from "./UserPosts";
import UserFollowing from "./UserFollowingTab";
import UserFollower from "./UserFollowerTab";

import RightSideBarProfilePage from "../SideNav/RightSideBarProfilePage";
import UserConnections from "./UserConnections";
import UserProfileHeader from "./UserProfileHeader";
import SavedPosts from "./SavedPosts";
import InviteStats from "../Referral/InviteStats";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { UserAuth } from "../Context";
//importing sidebar links here 


function ProfilePage() {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const { user } = UserAuth();
  const { userData } = useFetchUserData(uid);

  return (
    <>
      {/* Main content START */}
      <div className="col-lg-8 vstack gap-4">
      
        <UserProfileHeader />
        <Routes>
          <Route path="/" element={<UserPosts userData={userData} user={user}/>} />
          <Route path="/userActivity" element={<UserActivity />} />
          <Route path="/userAbout" element={<UserAbout userData={userData} />} />
          <Route path="/userPosts" element={<UserPosts userData={userData} user={user}/>} />
          <Route path="/userConnections" element={<UserConnections userData={userData}/>} />
          <Route path="/userfollowing" element={<UserFollowing userData={userData}/>} />
          <Route path="/userfollowers" element={<UserFollower userData={userData}  />} />
          <Route path="/savedposts" element={<SavedPosts />} />
          <Route path="/invite" element={<InviteStats userData={userData}/>} />
        </Routes>
      </div>
      {/* Main content END */}

      <RightSideBarProfilePage />
    </>
  );
}

export default ProfilePage;
