import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import { useParams, useLocation } from "react-router-dom";
import UserProfileCard from "../Header/userProfileCard";
import { UserAuth } from "../Context";
//algolia object initialization and index mapping
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const indexUsers = client.initIndex("users"); // Name of index in Algolia is users

export default function Users() {
  //**********************  Router Functions  **********************************//
  const location = useLocation();
  const { input } = useParams();

  //*********************** other states     *************************************/
  const [allUsers, setAllUsers] = useState([]); // Corrected line
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(8);
  const [loading, setLoading] = useState(true);
  const [countLeftUser, setCountLeftUser] = useState(false);
  const [paramInput, setParamInput] = useState(input);
  const [totalUserCount, setTotalUserCount] = useState(0); //total User count
  const { user } = UserAuth();

  const [result, setResult] = useState(false);

  //******************************* Functions **********************************************/
  useEffect(() => {
    console.log("Page count in useeffect", currentPage);
    setResult(false);
    setCurrentPage(0);
    setParamInput(input);
    retrieveData(0);
    setResult(true);
    setCurrentPage(currentPage + 1);
  }, [location.pathname, input]); //only updates when user refreshes

  //function fires when user loads
  const handleLoad = () => {
    if (!loading) {
      setResult(false);
      setLoading(true);
      setCurrentPage(currentPage + 1);
      retrieveData(currentPage + 1); // Fetch data for the next page
      setResult(true);
    }
  };

  //**********************   Handle Load More ********************************************/
  // ****************** async call to retrieve data **************************************/
  const retrieveData = async (page) => {
    if (input.length >= 3) {
      try {
        const offset = page * itemsPerPage;
        const data = await indexUsers.search(input, {
          offset,
          length: itemsPerPage,
          typoTolerance: "strict", // Enable strict typo tolerance
          // Contexts:['firstName', 'lastName', 'email', 'userName'], // Searchable contexts
          synonyms: false, // Disable synonyms
        });

        // // Create a Set to store unique users based on a unique identifier (e.g., user ID)
        // const uniqueUserSet = new Set(allUsers.map(user => user.email));

        // // Filter out duplicates and convert back to an array
        // const uniqueUsers = data.hits.filter(user => !uniqueUserSet.has(user.email));
        console.log("Data hits in search", data.hits);
        setLoading(false);
        setAllUsers(data.hits);
        setTotalUserCount(data.nbHits);
        setCountLeftUser(data.nbHits > offset + itemsPerPage);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };
  console.log("value of current page :", currentPage);
  return (
    <div>
      {allUsers.length > 0 && (
        <>
          <div className="vstack gap-4">
            <div className="card">
              <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pb-0">
                <div className="card-title d-flex justify-content-between align-items-center">
                  <h2>Users</h2>
                </div>
              </div>
              <div className="card-body">
                <div className="row g-4">
                  {paramInput.length >= 3 ? (
                    allUsers.length ? (
                      allUsers?.map((searchUser, index) => (
                        <UserProfileCard
                          key={index}
                          searchUser={searchUser}
                          user={user}
                          componentName={"Users"}
                        />
                      ))
                    ) : (
                      <div>No Users Found</div>
                    )
                  ) : (
                    <div>Need At least three Characters to search</div>
                  )}
                </div>
              </div>
            </div>
            <button
              className={`btn btn-loader mt-12 btn-primary-soft ${
                loading ||
                !countLeftUser ||
                totalUserCount <= currentPage * itemsPerPage
                  ? "disabled"
                  : ""
              }`}
              onClick={handleLoad}
            >
              {loading ? (
                <div className="spinner-grow spinner-grow-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <span className="load-text">
                  {countLeftUser ? "Load more" : "No More Users to show"}
                </span>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
}
