import React from "react";
import { Link } from "react-router-dom";

function BrandDisplay({ brandData, type }) {

  return (
    <div className="d-flex align-items-center">
      <div className="  " >
        <Link to={"/BrandHome/" + brandData?.supplier}>

        </Link>
      </div>
      <div>
        <div className="nav nav-divider" style={{ marginTop: '10px', marginBottom: '-2px' }}>
          <div className={`nav-item card-title mb-0 fw-light h6`} style={{ fontSize: type === "ProductCard" ? "11px" : "11px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
            {" "}
            <Link className={`nav-link`} to={"/BrandHome/" + brandData?.name} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {" "}
              {brandData?.displayName}
            </Link>
          </div>
          {/* <span className="nav-item small">
            {" "}
            {formatTimestampForPost(post?.dateCreated)}
          </span> */}
        </div>
        {/* <p className="mb-0 small">{}</p> */}
      </div>
    </div>
  );
}

export default BrandDisplay;
