import React from "react";
import { Link } from "react-router-dom";

export default function SignIn() {
  return (
    <>
      <div className="offcanvas-body d-block px-2 px-lg-0">
        <div className="card overflow-hidden">
        <div className="card-header pb-0 border-0">
        <h5 className="card-title mb-0">Sign in</h5>
      </div>
          <div className="card-body">
            <form className="">
              <div className="d-grid ">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email address"
                />
              </div>

              <div className="d-grid mt-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Enter password"
                />
              </div>

              <div className="d-grid mt-3">
                <Link className="btn btn-sm btn-primary-soft" to='/SignIn'>
                  Sign In
                </Link>
              </div>
              
              <div className="d-grid mt-3">
                <Link className="btn btn-sm btn-primary" to='/SignIn'>
                  Sign in with Google
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
