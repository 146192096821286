import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const db = getFirestore();

const ReviewsStars = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [averageRating, setAverageRating] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, `products/${productId}/Reviews`);
        const querySnapshot = await getDocs(reviewsRef);
        const reviewsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setReviews(reviewsData);

        if (reviewsData.length > 0) {
          const totalRating = reviewsData.reduce((acc, review) => acc + review.rating, 0);
          const avgRating = (totalRating / reviewsData.length).toFixed(1);
          setAverageRating(avgRating);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError("Failed to load reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  if (loading) return null;
  if (error) return <p>{error}</p>;

  return (
    <div className="d-flex align-items-center">
      {averageRating ? (
        <div className="d-flex">
          {[...Array(5)].map((_, starIndex) => (
            <span key={starIndex}>
              {starIndex < Math.round(averageRating) ? (
                <i className="bi bi-star-fill text-warning"></i>
              ) : (
                <i className="bi bi-star text-warning"></i>
              )}
            </span>
          ))}
        </div>
      ) : (
        <p className="mb-0">Be the first to rate it</p>
      )}
    </div>
  );
};

export default ReviewsStars;
