import React, { useState, useEffect } from "react";

export default function GoToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    document.body.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  const checkScrollHeight = () => {
    console.log("checking")
    if (document.body.scrollHeight > 3000) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(checkScrollHeight, 2000);

    return () => {
      clearInterval(interval);
    }
  }, [])


  const buttonStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 1000,
    backgroundColor: '#007bff',
    color: 'white',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  };

  return (
    isVisible && (
      <button
        type="button"
        onClick={goToBtn}
        id="btn-back-to-top"
        style={buttonStyle}
      >
        <i className="fas fa-arrow-up"></i>
      </button>
    )
  );
}

