import React, { useState, useEffect } from 'react';
import { collection, where, query, orderBy, getDocs, doc, getDoc, } from 'firebase/firestore';
import { db } from "../FirebaseConfig";
import { Slider, InputNumber } from 'antd';
import DiscountCount from '../Brands/DiscountCount';
import CountGender from './CategoryProductCount';
import useBrandProducts from '../StatesStore/brandProducts';
import useBrandFilter from '../StatesStore/BrandFilters';
import { getCategoryDisplayName } from '../lib/getCategoryName';
import { useSearchParams } from 'react-router-dom';

const colorMapping = {
  Pink: "#FFC0CB",
  Blue: "#87CEEB",
  Green: "#008000",
  Yellow: "#FFFF00",
  White: "#FFFFFF",
  Black: "#000000",
  Red: "#FF0000",
  Purple: "#800080",
  Grey: "#808080",
  Brown: "#A52A2A",
  Multi: "#FF00FF", // Assuming multi-color is represented by magenta for example purposes
  Orange: "#FFA500",
};

const getColorCode = (colorName) => {
  return colorMapping[colorName] || "#000000"; // Return black as default if color is not found
};


export function SideNavSizeDropDown({ brandName}) {
  const [alphabeticSizes, setAlphabeticSizes] = useState([]);
  const [numericSizes, setNumericSizes] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const BrandFilter = useBrandFilter(state => state.BrandFilter);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const fetchSizes = async () => {
      try {
        const sizesCollectionRef = collection(db, 'sizes');
        const sizesSnapshot = await getDocs(sizesCollectionRef);

        let alphabeticSizes = [];
        let numericSizes = [];

        sizesSnapshot.forEach(doc => {
          const data = doc.data();
          if (doc.id === 'type1') {
            alphabeticSizes = data.Alphabetic;
          } else if (doc.id === 'type2') {
            numericSizes = data.Numbers;
          }
        });

        setAlphabeticSizes(alphabeticSizes);
        setNumericSizes(numericSizes);

        if (brandName) {
          const brandDocRef = doc(db, 'brands', brandName);
          const brandDoc = await getDoc(brandDocRef);
          if (brandDoc.exists()) {
            setAvailableSizes(brandDoc.data().sizes || []);
          } else {
            console.error('No such brand!');
          }
        }
      } catch (error) {
        console.error('Error fetching sizes:', error);
      }
    };

    fetchSizes();
  }, [brandName]);

  const setBrandFilter = useBrandFilter(state => state.setBrandFilter);

  const handleSizeChange = e => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedSize = [...currentFilter.size];

    if (checked) {
      updatedSize.push(name);
    } else {
      updatedSize = updatedSize.filter(g => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      size: updatedSize,
    };

    setBrandFilter(updatedFilter);
    // Update the URL with the selected sizes
  //   const newSearchParams = new URLSearchParams(searchParams);
  //   newSearchParams.set('size', updatedSize.join(','));
  // setSearchParams(newSearchParams);
  };

  return (
    <div className="collapse show" id={brandName}>
      <div className="overflow-auto" style={{ maxHeight: '300px' }}>
        <div className="d-flex">
          <div className="me-4">
            {alphabeticSizes?.map(size => (
              <div key={size} className="text-wrap justify-content-start align-items-center my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={size}
                  onChange={handleSizeChange}
                  checked={BrandFilter.size.includes(size)}
                  disabled={!availableSizes.includes(size)}
                />
                <label className="form-check-label mx-2">{size}</label>
              </div>
            ))}
          </div>
          <div>
            {numericSizes?.map(size => (
              <div key={size} className="text-wrap justify-content-start align-items-center my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name={size}
                  onChange={handleSizeChange}
                  checked={BrandFilter.size.includes(size)}
                  disabled={!availableSizes.includes(size)}
                />
                <label className="form-check-label mx-2">{size}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}





// export function SideNavSizeDropDown({ brandName }) {
//   const [sizes, setSizes] = useState([]);
//   const BrandFilter = useBrandFilter(state => state.BrandFilter);

//   // console.log('Inside size dropdown :', brandName);

//   useEffect(() => {
//     const fetchSizes = async () => {
//       if (!brandName) return;

//       try {
//         const productsCollectionRef = collection(db, "products");
//         const q = query(productsCollectionRef, where("supplier", "==", brandName));
//         const querySnapshot = await getDocs(q);
//         const sizesSet = new Set();
//         querySnapshot.forEach(doc => {
//           const data = doc.data();
//           if (data.sizes) {
//             data.sizes.forEach(size => sizesSet.add(size));
//           }
//         });

//         const sortedSizes = [...sizesSet].sort((a, b) => {
//           // If sizes are numerical, sort as numbers
//           if (!isNaN(a) && !isNaN(b)) {
//             return parseFloat(a) - parseFloat(b);
//           }
//           // Otherwise, sort alphabetically
//           return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
//         });

//         setSizes(sortedSizes);
//       } catch (error) {
//         console.error("Error fetching sizes:", error);
//       }
//     };

//     fetchSizes();
//   }, [brandName]);


//   const setBrandFilter  = useBrandFilter(state => state.setBrandFilter);

//   const handleSizeChange = (e) => {
//     const { name, checked } = e.target;
//     const currentFilter = useBrandFilter.getState().BrandFilter;
//     let updatedsize = [...currentFilter.size]; 

//     if (checked) {
//       updatedsize.push(name); 
//     } else {
//       updatedsize = updatedsize.filter(g => g !== name); 
//     }

//     const updatedFilter = {
//       ...currentFilter,
//       size: updatedsize,
//     };

//     setBrandFilter(updatedFilter); 
//   };





//   // console.log('Sizes available : ', sizes);
//   return (
//     <div className="collapse show" id={brandName}>
//       <div className="overflow-auto" style={{ maxHeight: '300px' }}>
//         {sizes.map((size) => (
//           <div key={size} className="text-wrap justify-content-start align-items-center my-2">
            
//               <input
//                 className="form-check-input"
//                 type="checkbox"
//                 name={size}
//                 onChange={handleSizeChange} 
//                 checked={BrandFilter.size.includes(size)}
//               />
//               <label className="form-check-label mx-2  ">{size}</label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }




// export function SideNavSizeDropDown({ brandName }) {
//   const [alphabeticSizes, setAlphabeticSizes] = useState([]);
//   const [numericSizes, setNumericSizes] = useState([]);
//   const [availableSizes, setAvailableSizes] = useState([]);
//   const BrandFilter = useBrandFilter(state => state.BrandFilter);

//   useEffect(() => {
//     const fetchSizes = async () => {
//       try {
//         const sizesCollectionRef = collection(db, 'Sizes');
//         const sizesSnapshot = await getDocs(sizesCollectionRef);

//         let alphabeticSizes = [];
//         let numericSizes = [];

//         sizesSnapshot.forEach(doc => {
//           const data = doc.data();
//           if (doc.id === 'type1') {
//             alphabeticSizes = data.Alphabetic;
//           } else if (doc.id === 'type2') {
//             numericSizes = data.numbers;
//           }
//         });

//         setAlphabeticSizes(alphabeticSizes);
//         setNumericSizes(numericSizes);

//         if (brandName) {
//           const brandDocRef = doc(db, 'brands', brandName);
//           const brandDoc = await getDoc(brandDocRef);
//           if (brandDoc.exists()) {
//             setAvailableSizes(brandDoc.data().sizes || []);
//           } else {
//             console.error('No such brand!');
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching sizes:', error);
//       }
//     };

//     fetchSizes();
//   }, [brandName]);

//   const setBrandFilter = useBrandFilter(state => state.setBrandFilter);

//   const handleSizeChange = e => {
//     const { name, checked } = e.target;
//     const currentFilter = useBrandFilter.getState().BrandFilter;
//     let updatedSize = [...currentFilter.size];

//     if (checked) {
//       updatedSize.push(name);
//     } else {
//       updatedSize = updatedSize.filter(g => g !== name);
//     }

//     const updatedFilter = {
//       ...currentFilter,
//       size: updatedSize,
//     };

//     setBrandFilter(updatedFilter);
//   };

//   return (
//     <div className="collapse show" id={brandName}>
//       <div className="overflow-auto" style={{ maxHeight: '300px' }}>
//         <div className="d-flex">
//           <div className="me-4">
//             {alphabeticSizes.map(size => (
//               <div key={size} className="text-wrap justify-content-start align-items-center my-2">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   name={size}
//                   onChange={handleSizeChange}
//                   checked={BrandFilter.size.includes(size)}
//                   disabled={!availableSizes.includes(size)}
//                 />
//                 <label className="form-check-label mx-2">{size}</label>
//               </div>
//             ))}
//           </div>
//           <div>
//             {numericSizes.map(size => (
//               <div key={size} className="text-wrap justify-content-start align-items-center my-2">
//                 <input
//                   className="form-check-input"
//                   type="checkbox"
//                   name={size}
//                   onChange={handleSizeChange}
//                   checked={BrandFilter.size.includes(size)}
//                   disabled={!availableSizes.includes(size)}
//                 />
//                 <label className="form-check-label mx-2">{size}</label>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }



export function SideNavColorDropDown2() {
  const [colors, setColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const BrandFilter = useBrandFilter(state => state.BrandFilter);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const fetchColors = async () => {
      try {
        const colorsCollectionRef = doc(db, 'colors', 'Colors');
        const colorsDoc = await getDoc(colorsCollectionRef);

        if (colorsDoc.exists()) {
          setColors(colorsDoc.data().Colors);
        } else {
          console.error('No colors document found!');
        }

      } catch (error) {
        console.error('Error fetching colors:', error);
      }
    };

    fetchColors();
  }, []);

  const setBrandFilter = useBrandFilter(state => state.setBrandFilter);

  const handleColorChange = e => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedColors = [...currentFilter.colors];

    if (checked) {
      updatedColors.push(name);
    } else {
      updatedColors = updatedColors.filter(color => color !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      colors: updatedColors,
    };

    setBrandFilter(updatedFilter);

    // Update the URL with the selected colors
    // const newSearchParams = new URLSearchParams(searchParams);
    // newSearchParams.set('colors', updatedColors.join(','));
    // setSearchParams(newSearchParams);
  };
  console.log('BrandFilter in All products',BrandFilter);
  return (
    <div className="collapse show">
      <div className="overflow-auto" style={{ maxHeight: '300px' }}>
        <div className="d-flex flex-wrap">
          {colors?.map(color => (
            <div key={color} className="text-wrap justify-content-start align-items-center my-2">
              <input
                className="form-check-input"
                type="checkbox"
                name={color}
                onChange={handleColorChange}
                checked={BrandFilter.color.includes(color)}
                disabled={!availableColors.includes(color)}
              />
              <label className="form-check-label mx-2">{color}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function SideNavColorDropDown({ brandName }) {
  const [colors, setColors] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const BrandFilter = useBrandFilter(state => state.BrandFilter);

  useEffect(() => {
    const fetchColors = async () => {
      if (!brandName) return;

      try {
        const productsCollectionRef = collection(db, "products");
        const q = query(productsCollectionRef, where("supplier", "==", brandName));
        const querySnapshot = await getDocs(q);
        const colorSet = new Set();
        querySnapshot.forEach(doc => {
          const data = doc.data();
          if (data.colors) {
            data.colors.forEach(color => colorSet.add(color));
          }
        });

        const sortedColors = [...colorSet].sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }));

        setColors(sortedColors);
      } catch (error) {
        console.error("Error fetching colors:", error);
      }
    };

    const fetchAvailableColors = async () => {
      if (!brandName) return;

      try {
        const brandDocRef = doc(db, "brands", brandName);
        const brandDoc = await getDoc(brandDocRef);
        if (brandDoc.exists()) {
          const data = brandDoc.data();
          if (data.colors) {
            setAvailableColors(data.colors);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching available colors:", error);
      }
    };

    fetchColors();
    fetchAvailableColors();
  }, [brandName]);

  const setBrandFilter = useBrandFilter(state => state.setBrandFilter);

  const handColorChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedColor = [...currentFilter.color];

    if (checked) {
      updatedColor.push(name);
    } else {
      updatedColor = updatedColor.filter(g => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      color: updatedColor,
    };

    setBrandFilter(updatedFilter);
  };

  return (
    <div className="collapse show" id={brandName}>
      <div className="overflow-auto" style={{ maxHeight: '300px' }}>
        {colors
          .filter(
            (color) =>
              availableColors.includes(color) &&
              isNaN(color) &&
              !["XXS", "XS", "S", "M", "L", "XL", "XXL"].includes(color)
          )
          .map((color) => (
            <div key={color} className="text-wrap justify-content-start align-items-center my-2">
              <input
                className="form-check-input"
                type="checkbox"
                name={color}
                onChange={handColorChange}
                checked={BrandFilter.color.includes(color)}
              />
              <label className="form-check-label mx-2">{color}</label>

            {/* <div
              key={index}
              title={color}
              style={{
                backgroundColor: getColorCode(color),
                border: '2px solid lightgrey',
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            />
           */}




            </div>
          ))}
      </div>
    </div>
  );
}

// export function SideNavColorDropDown({ brandName }) {
//   const [colors, setColors] = useState([]);
//   const BrandFilter = useBrandFilter(state => state.BrandFilter);

//   console.log('Inside size dropdown :', brandName);

//   useEffect(() => {
//     const fetchColors = async () => {
//       if (!brandName) return;

//       try {
//         const productsCollectionRef = collection(db, "products");
//         const q = query(productsCollectionRef, where("supplier", "==", brandName));
//         const querySnapshot = await getDocs(q);
//         const colorSet = new Set();
//         querySnapshot.forEach(doc => {
//           const data = doc.data();
//           if (data.colors) {
//             data.colors.forEach(color => colorSet.add(color));
//           }
//         });

//         const sortedColors = [...colorSet].sort((a, b) => {
//           // If sizes are numerical, sort as numbers
//           if (!isNaN(a) && !isNaN(b)) {
//             return parseFloat(a) - parseFloat(b);
//           }
//           // Otherwise, sort alphabetically
//           return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
//         });

//         setColors(sortedColors);
//       } catch (error) {
//         console.error("Error fetching sizes:", error);
//       }
//     };

//     fetchColors();
//   }, [brandName]);


//   const setBrandFilter  = useBrandFilter(state => state.setBrandFilter);

//   const handColorChange = (e) => {
//     const { name, checked } = e.target;
//     const currentFilter = useBrandFilter.getState().BrandFilter;
//     let updatedcolor = [...currentFilter.color]; 

//     if (checked) {
//       updatedcolor.push(name); 
//     } else {
//       updatedcolor = updatedcolor.filter(g => g !== name); 
//     }

//     const updatedFilter = {
//       ...currentFilter,
//       color: updatedcolor,
//     };

//     setBrandFilter(updatedFilter); 
//   };

//   // console.log('Colors available : ', colors);
//   return (
//     <div className="collapse show" id={brandName}>
//   <div className="overflow-auto" style={{ maxHeight: '300px' }}>
//     {colors
//       .filter(
//         (color) =>
//           isNaN(color) && 
//           !["XXS","XS", "S", "M", "L", "XL","XXL"].includes(color) 
//       )
//       .map((color) => (
//         <div key={color} className="text-wrap justify-content-start align-items-center my-2">
//           <input
//             className="form-check-input"
//             type="checkbox"
//             name={color}
//             onChange={handColorChange}
//             checked={BrandFilter.color.includes(color)}
//           />
//           <label className="form-check-label mx-2">{color}</label>
//         </div>
//       ))}
//   </div>
// </div>
//   );
// }


























export function SideNavPriceRangeDropDown({ dataId }) {
  // State to hold the current value of the slider
  const [range, setRange] = useState([0, 90000]); // Initial value set to [0, 90000]

  const BrandFilter  = useBrandFilter(state => state.BrandFilter);
  const setBrandFilter  = useBrandFilter(state => state.setBrandFilter);

 console.log('Brand filter in All product',BrandFilter);
  // Function to handle changes in the slider value
  const handleRangeChange = (newRange) => {
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: newRange[0],
      maxprice: newRange[1],
    };
    setBrandFilter(updatedFilter);
    // console.log('Current filter state:', updatedFilter);
  };

  // Function to handle changes in the min value input box
  const handleMinInputChange = (value) => {
    const newRange = [value, range[1]];
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: value,
      maxprice: range[1],
    };
    setBrandFilter(updatedFilter);
  };

  // Function to handle changes in the max value input box
  const handleMaxInputChange = (value) => {
    const newRange = [range[0], value];
    setRange(newRange);
    const updatedFilter = {
      ...BrandFilter,
      minprice: range[0],
      maxprice: value,
    };
    setBrandFilter(updatedFilter);
  };

  return (
    <div className="collapse show" id={dataId}>
      <div className="flex-1 justify-content-center ">
        {/* Price Range Slider */}
        <div className="rounded-5 mb-3">
          <Slider
            range
            value={range}
            onChange={handleRangeChange}
            min={0}
            max={90000}
            step={500}
            tipFormatter={(value) => `${value}`}
          />
        </div>

        <div className="d-flex gap-2">
          <div className="d-flex flex-column ">
            <label>Min</label>
            <InputNumber
            className='w-100'
              min={0}
              max={90000}
              value={range[0]}
              onChange={handleMinInputChange}
              placeholder={range[0]}
            />
          </div>
          <div className="d-flex flex-column ">
            <label>Max</label>
            <InputNumber
            className='w-100'

              min={0}
              max={90000}
              value={range[1]}
              onChange={handleMaxInputChange}
              placeholder={range[1]}
            />
          </div>
        </div>

      </div>
    </div>
  );
}

export function SideNavGenderDropDown({ dataId }) {

  const BrandFilter  = useBrandFilter(state => state.BrandFilter);
  const setBrandFilter  = useBrandFilter(state => state.setBrandFilter);

  const handleGenderChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedGender = [...currentFilter.category]; 

    if (checked) {
      updatedGender.push(name); 
    } else {
      updatedGender = updatedGender.filter(g => g !== name); 
    }

    const updatedFilter = {
      ...currentFilter,
      category: updatedGender,
    };

    setBrandFilter(updatedFilter); 
  };

      // console.log('Current Brand filter state in Gender', BrandFilter);


  return (
    <div className="collapse show" id={dataId}>
  {/* Gender filter section */}
  <ul className="gender-filter list-unstyled">
    <li className="form-check">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="Men"
            id={`${dataId}-men`}
            onChange={handleGenderChange} // Call handleGenderChange on change
            checked={BrandFilter.category.includes("Men")} // Check if "Men" is included in the gender filter array
          />
          <label className="form-check-label" htmlFor={`${dataId}-men`}>Men</label>
        </div>
      </div>
    </li>
    <li className="form-check">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="Women"
            id={`${dataId}-women`}
            onChange={handleGenderChange} // Call handleGenderChange on change
            checked={BrandFilter.category.includes("Women")} // Check if "Women" is included in the gender filter array
          />
          <label className="form-check-label" htmlFor={`${dataId}-women`}>Women</label>
        </div>
      </div>
    </li>
  </ul>
</div>
  );
}

export function SideNavCategoryDropDown({ dataId }) {

  const BrandFilter  = useBrandFilter(state => state.BrandFilter);
  const setBrandFilter  = useBrandFilter(state => state.setBrandFilter);

  const handleCategoryChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedCategories = [...currentFilter.subCategory]; 

    if (checked) {
      updatedCategories.push(name); 
    } else {
      updatedCategories = updatedCategories.filter(g => g !== name); 
    }

    const updatedFilter = {
      ...currentFilter,
      subCategory: updatedCategories,
    };

    setBrandFilter(updatedFilter); 
  };

      // console.log('Current Brand filter state in Category', BrandFilter);


  return (
    <div className="collapse show" id={dataId}>
  {/* Gender filter section */}
  <ul className="gender-filter list-unstyled">
    <li className="form-check">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="Ready to Wear"
            id={`${dataId}-Ready to Wear`}
            onChange={handleCategoryChange} // Call handleGenderChange on change
            checked={BrandFilter.subCategory.includes("Ready to Wear")} // Check if s included in the gender filter array
          />
          <label className="form-check-label" htmlFor={`${dataId}-Ready to Wear`}>Ready to Wear</label>
        </div>
      </div>
    </li>
    <li className="form-check">
      <div className="text-wrap justify-content-between align-items-center">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="Unstitched"
            id={`${dataId}-Unstitched`}
            onChange={handleCategoryChange} // Call handleGenderChange on change
            checked={BrandFilter.subCategory.includes("Unstitched")} // Check if "Unstitched" is included in the gender filter array
          />
          <label className="form-check-label" htmlFor={`${dataId}-Unstitched`}>Unstitched</label>
        </div>
      </div>
    </li>
    <li className="form-check">
      <div className="text-wrap justify-content-between align-items-center">
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="Western Wear"
            id={`${dataId}-Western Wear`}
            onChange={handleCategoryChange} 
            checked={BrandFilter.subCategory.includes("Western Wear")} // Check if "Unstitched" is included in the gender filter array
          />
          <label className="form-check-label" htmlFor={`${dataId}-Western Wear`}>Western Wear</label>
        </div>
      </div>
    </li>
  </ul>
</div>
  );
}


export function SideNavSubCategoryDropDown({ brandName }) {
  const [subSubCategory, setSubSubCategory] = useState([]);
  const [displaySubCatMap, setDisplaySubCatMap] = useState({});
  const BrandFilter = useBrandFilter((state) => state.BrandFilter);
  const setBrandFilter = useBrandFilter((state) => state.setBrandFilter);

  const handleSubSubCategoryChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useBrandFilter.getState().BrandFilter;
    let updatedsubSubCategory = [...currentFilter.subSubCategory];

    if (checked) {
      updatedsubSubCategory.push(name);
    } else {
      updatedsubSubCategory = updatedsubSubCategory.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      subSubCategory: updatedsubSubCategory,
    };

    setBrandFilter(updatedFilter);
  };

  useEffect(() => {
    const fetchSubSubCategories = async () => {
      if (!brandName) return;

      try {
        const productsCollectionRef = collection(db, "products");
        const q = query(productsCollectionRef, where("supplier", "==", brandName));
        const querySnapshot = await getDocs(q);
        const subSubCategorySet = new Set();
        const displaySubCatMap = {};

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.subSubCategory && data.subSubCategory !== "None") {
            subSubCategorySet.add(data.subSubCategory);
            displaySubCatMap[data.subSubCategory] = getCategoryDisplayName(data.subSubCategory);
          }
        });

        // Sort subSubCategorySet
        const sortedSubSubCategory = Array.from(subSubCategorySet).sort((a, b) => {
          const aIsNumeric = !isNaN(a);
          const bIsNumeric = !isNaN(b);

          if (aIsNumeric && bIsNumeric) {
            return a - b; // both are numbers
          } else if (aIsNumeric) {
            return -1; // a is a number, b is a string
          } else if (bIsNumeric) {
            return 1; // a is a string, b is a number
          } else {
            return a.localeCompare(b); // both are strings
          }
        });

        setSubSubCategory(sortedSubSubCategory);
        setDisplaySubCatMap(displaySubCatMap);
      } catch (error) {
        console.error("Error fetching subSubCategory:", error);
      }
    };

    fetchSubSubCategories();
  }, [brandName]);

  console.log("Current Brand filter state in subSubCategory", BrandFilter);


  return (
    <div className="collapse show" id={brandName}>
      <div className="overflow-auto" style={{ maxHeight: '300px' }}>
        {subSubCategory?.map((subCat) => (
          <div key={subCat} className="d-flex align-items-center justify-content-between w-100 mb-2">
            <div className="me-2">
              <input
                className="form-check-input"
                type="checkbox"
                name={subCat}
                onChange={handleSubSubCategoryChange} 
                checked={BrandFilter.subSubCategory.includes(subCat)}
              />
            </div>
            <div className="flex-grow-1 overflow-hidden text-truncate">
              <label className="text-wrap form-check-label">
                {displaySubCatMap[subCat]}
              </label>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}












