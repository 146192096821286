import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ProductCard from "./Products/ProductCard";
import fetchProducts from "./lib/productUtils";
import useActiveBrandsStore from "./StatesStore/ActiveBrands";
import { getCategoryDisplayName } from "./lib/getCategoryName";
import SideNavAllProducts from "./SideNav/SideNavAllProducts";

function AllProducts({ brandName }) {

  let { subCategory } = useParams();
  let { subSubCategory } = useParams();
  let { category } = useParams();


  let { catLabel: categoryLabel } = "";

  if (typeof subCategory === "undefined") {
    categoryLabel = "All Products";
  } else {
    // Map category names to display names
    const mappedSubCategory = getCategoryDisplayName(subCategory);
    const mappedSubSubCategory = getCategoryDisplayName(subSubCategory);

    categoryLabel = mappedSubCategory + " - " + mappedSubSubCategory;
  }

  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [filter, setFilter] = useState("NF");
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countLeft, setCountLeft] = useState(true);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);

  useEffect(() => {
    setCurrentPage(1);
    setAllProducts([]);
    setLastVisible(null);
  }, [subCategory, subSubCategory, category]);
console.log("i am from All products subCategory, subSubCategory, category",subCategory, subSubCategory, category);
  useEffect(() => {
     // Ensure each of category, subCategory, and subSubCategory are arrays
  const ensureArray = (value) => (Array.isArray(value) ? value : [value]);
    fetchProducts({
      activeBrands,
      category: ensureArray(category),
      subCategory: ensureArray(subCategory),
      subSubCategory: ensureArray(subSubCategory),
      lastVisible,
      currentPage,
      setAllProducts,
      setLoading,
      setLastVisible,
      setCountLeft,
      filter
    });
  }, [currentPage, itemsPerPage, subCategory, subSubCategory, category]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  allProducts.sort((a, b) => {
    switch (filter) {
      case "PL":
        return parseInt(a.newPrice) - parseInt(b.newPrice);
      case "PH":
        return parseInt(b.newPrice) - parseInt(a.newPrice);
      case "DL":
        return parseInt(a.discount) - parseInt(b.discount);
      case "DH":
        return parseInt(b.discount) - parseInt(a.discount);
      default:
      //return parseInt(b.dateCreated.seconds) - parseInt(a.dateCreated.seconds);
    }
  });

  return (
    <>
    <SideNavAllProducts/>
      <div className="col-md-8 col-lg-6 vstack gap-4">
        <div className="card">
          {/* Card header START */}
          <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pb-0">
            <h1 className="card-title h4">{categoryLabel}</h1>{" "}
            <div className="col-sm-6 col-lg-4">
              <select
                className="form-select js-choice"
                data-search-enabled="true"
                onChange={handleFilterChange}
                value={filter}
              >
                <option value="NF">Newest First</option>
                <option value="PL">Price - Low to High</option>
                <option value="PH">Price - High to Low</option>
                <option value="DL">Discount - Low to High</option>
                <option value="DH">Discount - High to Low</option>
              </select>
            </div>
          </div>

          {/* Card body START */}
          <div className="card-body">
            <div className="row g-4">
              {/* Product item START */}
              {allProducts.map((product, index) => {
                return <ProductCard key={index} product={product} />;
              })}
              {/* Product item END */}
            </div>
          </div>
          {/* Card body END */}
        </div>

        <button
          className={`btn btn-loader  mt-12 btn-primary-soft ${loading ||
              !countLeft ||
              allProducts.length < currentPage * itemsPerPage
              ? "disabled"
              : ""
            }`}
          onClick={
            !countLeft
              ? null
              : () => {
                if (!loading) {
                  setLoading(true);
                  setCurrentPage((prevValue) => prevValue + 1);
                }
              }
          }
        >
          {loading ? (
            <div className="spinner-grow spinner-grow-sm" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span className="load-text">
              {countLeft ? "Load more" : "No More products to show"}
            </span>
          )}
        </button>
      </div>
    </>
  );
}

export default AllProducts;
