import React, { useState } from "react";
import WebFont from "webfontloader";
import { auth } from "../FirebaseConfig";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import TermsAndConditions from "./TermsAndConditions";
import useModalStore from "../StatesStore/ModalStore";

function ResetPassword() {
  const { open, closeModal } = useModalStore();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState({
    email: "",
  });
  if (open) {
    closeModal();
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailVal = e.target.elements.email.value;
    sendPasswordResetEmail(auth, emailVal)
      .then((res) => {
        console.log(res);
        toast.success(
          "Check your email for password reset instructions",
          toastOptions
        );
        navigate("/SignIn"); // Redirect to the home page or login page
      })
      .catch(() => {
        // toast.error("User not found! Enter correct email address", toastOptions);
        setErrorMsg((prevErrors) => ({
          ...prevErrors,
          email: "Email not found! Please enter correct email address.",
        }));
        return;
      });
  };

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
  }, []);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100 py-5">
          <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
            <div className="card card-body rounded-3 text-center p-4">
              <div className="text-center">
                <Link className="text-body text-center fs-icon " to="/">
                  <div
                    style={{
                      fontFamily: "Nunito Sans",
                    }}
                  >
                    <i>lookflock&nbsp;</i>
                  </div>
                </Link>
              </div>
              <p className="mt-3 fs-4 text-body">Forget Password?</p>
              <form className="mt-3" onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3">
                  <input
                    className="form-control email"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    onChange={() => {
                      setErrorMsg((prevErrors) => ({
                        ...prevErrors,
                        email: "",
                      }));
                    }}
                    required
                  />
                  <div>
                    <span
                      style={{
                        color: "red",
                        display: "flex",
                        marginTop: "3px",
                      }}
                    >
                      {errorMsg.email}
                    </span>
                  </div>
                </div>
                <div className="d-grid">
                  <button type="submit" className="mb-3 btn btn-primary">
                    Reset password
                  </button>
                </div>
              </form>
              <div className="container mb-3 text-end">
                <Link to="/SignIn">
                  <u className="text-body">Back to Sign in</u>
                </Link>
              </div>
              <TermsAndConditions />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
