import React, { useState, useEffect } from "react";
import ProductCard from "../Products/ProductCard";
import algoliasearch from "algoliasearch/lite";
import { useParams, useLocation, Link } from "react-router-dom";
import useSearchedProducts from "../StatesStore/searchedProducts";
import useSearchFilter from "../StatesStore/SearchFilters";
import SideNavSearch from "../SideNav/SideNavSearch";
import { load } from "webfontloader";

//algolia object initialization and index mapping
const client = algoliasearch("EMFUSB67CG", "0f7466afc30b24c667a61b43cf879898");
const index = client.initIndex("products"); //name of index in algolia is products

export default function Products() {
  //**********************  Router Functions  **********************************//
  const location = useLocation();
  const { input } = useParams();
  const products = useSearchedProducts((state) => state.products);

  //*********************** other states     *************************************/
  const [allProducts, setAllProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(9);
  const [loading, setLoading] = useState(true);
  const [countLeftProduct, setCountLeftProduct] = useState(false);
  const [paramInput, setParamInput] = useState(input);
  const [totalProductCount, setTotalProductCount] = useState(0); //total product count

  const [result, setResult] = useState(false);
  const setSearchedProduct = useSearchedProducts(
    (state) => state.setSearchedProduct
  );
  const searchFilter = useSearchFilter((state) => state.SearchFilter);

  //******************************* Functions **********************************************/
  useEffect(() => {
    console.log("Page count in useeffect", currentPage);
    setResult(false);
    setAllProducts([]);
    setSearchedProduct([]);
    setCurrentPage(0);
    setParamInput(input);
    retrieveData(0);
    setResult(true);
    setCurrentPage(currentPage + 1);
  }, [location.pathname, input]); //only updates when user refreshes

  //**********************   Handle Load More ********************************************/
  const handleLoad = () => {
    if (!loading) {
      setResult(false);
      setLoading(true);
      setCurrentPage(currentPage + 1);
      retrieveData(currentPage + 1); // Fetch data for the next page
      setResult(true);
    }
  };
  // ****************** async call to retrieve data **************************************/
  const retrieveData = async (page) => {
    if (input.length >= 3) {
      // console.log('Page before fetch: ', page);
      try {
        const offset = page * itemsPerPage;
        const data = await index.search(input, {
          offset,
          length: itemsPerPage,
        });
        setLoading(false);

        // Create a Set to ensure unique products
        const uniqueProducts = new Set([...allProducts, ...data.hits]);

        // Convert the Set back to an array
        const updatedProducts = Array.from(uniqueProducts);

        setAllProducts(updatedProducts);
        setTotalProductCount(data.nbHits);
        console.log("all products in search 1111", updatedProducts);

        setSearchedProduct(data.hits);

        if (data.nbHits > data.offset) {
          setCountLeftProduct(true);
        } else {
          setCountLeftProduct(false);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
  };
  // console.log('global products in search 1111', products);
  const filteredProducts = products.filter(
    (product) =>
      (searchFilter.gender.length === 0 ||
        searchFilter.gender.includes(product.category)) &&
      (searchFilter.brand.length === 0 ||
        searchFilter.brand.includes(product.supplier)) &&
      searchFilter.minprice <= product.newPrice &&
      searchFilter.maxprice >= product.newPrice
  );
  const isFilter =
    searchFilter?.gender.length > 0 ||
    searchFilter?.brand.length > 0 ||
    searchFilter?.size.length > 0 ||
    searchFilter?.minprice > 0 ||
    searchFilter?.maxprice < 90000;
  console.log("value of current page :", currentPage);

  console.log("value of countLeft page :", countLeftProduct);
  return (
    <div className="pe-0">
      {filteredProducts.length > 0 && (
        <div className="d-flex gap-4">
          <SideNavSearch />
          <div className="vstack gap-4">
            <div className="productsSection border border-2 card p-0">
              <div className="card-header border-0 pb-0">
                {result && (
                  <div className="card-title d-flex justify-content-between align-items-center">
                    <h3>
                      {totalProductCount} product
                      {totalProductCount > 1 ? "s" : ""} found from search
                      results
                    </h3>
                  </div>
                )}
              </div>
              <div className="card-body pt-0">
                <div className="row g-4">
                  {paramInput.length >= 3 && filteredProducts.length ? (
                    filteredProducts.map((product, index) => (
                      <ProductCard
                        key={index}
                        product={product}
                        componentName={"Products"}
                      />
                    ))
                  ) : (
                    <div>Need At least three Characters to search</div>
                  )}
                </div>
              </div>
            </div>
            {totalProductCount > 0 && (
              <button
                className={`btn btn-loader btn-primary-soft ${
                  loading ||
                  !countLeftProduct ||
                  totalProductCount <= currentPage * itemsPerPage
                    ? "disabled"
                    : ""
                }`}
                onClick={handleLoad}
              >
                {/* Loading button condition */}
                {loading ? (
                  <div className="spinner-grow spinner-grow-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <span className="load-text">
                    {countLeftProduct
                      ? "Load more"
                      : "No more products to show"}
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
