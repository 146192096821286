import React, { useState, useEffect } from "react";
import { db } from "../FirebaseConfig";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";

function CountGender({ Gender }) {
    const [itemCount, setItemCount] = useState();
    const collectionRef = collection(db, "products");
  
    useEffect(() => {
      const getItemCount = async () => {
          const q = query (collectionRef, 
              where('category', '==', Gender),
              )
  
          const snapshot = await getCountFromServer(q)
          const totalCount = snapshot.data().count
          setItemCount(totalCount)
      }
      getItemCount();
    }, [Gender])
    return <>{itemCount}</>;
  }

export default CountGender