import React, { useState, useEffect } from "react";
import ProductList from "./ProductList";
import ListComments from "./ListComments";
import { Link } from "react-router-dom";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  where,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import useNotification from "../hooks/useNotification";
const InviteLists = ({ invitedshoppingListData, invitedList }) => {
  // The list which is currently selected
  const [selectedShopList, setSelectedShopList] = useState({});
  // Use to check which user is the owner of invited list to render its profile
  const [selectedUser, setSelectedUser] = useState([]);
  // Current List ID
  const [listId, setListId] = useState("");
  const { user } = UserAuth();
  const { addNotification } = useNotification();

  useEffect(() => {
    if (invitedshoppingListData?.length > 0) {
      setSelectedShopList({
        name: invitedshoppingListData[0]?.name,
        listId: invitedshoppingListData[0]?.listId,
      });
      setListId(invitedshoppingListData[0]?.listId);
    }
  }, [invitedshoppingListData]);
  useEffect(() => {
    if (!invitedshoppingListData || !invitedList || !selectedShopList || !user)
      return;
    // console.log("invitedshoppingListData", invitedshoppingListData);
    // console.log("invitedList", invitedList);
    // console.log("selectedShopList", selectedShopList);
    const fetchUserDetails = async () => {
      const selectedShopListData = invitedshoppingListData?.find(
        (shopList) =>
          shopList?.name === selectedShopList?.name &&
          shopList?.listId === selectedShopList?.listId
      );
      const selectedListID = selectedShopListData?.listId;
      const selecteduser = invitedList?.find(
        (list) => list?.listId === selectedListID
      );
      // console.log("selecteduser", selecteduser);
      if (selecteduser) {
        const userRef = doc(db, "users", selecteduser?.userId);
        const userData = await getDoc(userRef);
        const userCompleteData = userData?.data();
        selecteduser.name =
          userCompleteData?.firstName + " " + userCompleteData?.lastName;
        selecteduser.photo = userCompleteData?.photo;
        setSelectedUser(selecteduser);
      }
       // Check if the user has already seen the shopping list
       if (selecteduser?.userId) {
        console.log(
          "From invited lists selecteduser?.userId selectedShopList?.name",
          selecteduser?.userId,
          selectedShopList?.name
        );
      
        // Create a reference to a specific document in the 'viewers' subcollection
        const hasSeenListDocRef = doc(
          db,
          "users",
          selecteduser?.userId,
          "shoppingList",
          selectedShopList?.name,
          "viewers", // Collection name
          user?.uid // Document ID for this user
        );
      
        const hasSeenListSnapshot = await getDoc(hasSeenListDocRef);
      
        if (!hasSeenListSnapshot.exists()) {
          // If the user has not seen the list before, show the notification
          await addNotification({
            userId: selecteduser?.userId,
            id: user.uid,
            timestamp: serverTimestamp(),
            type: "firstTimeListView",
            by: "user",
            fields: {
              listId: selectedListID,
              shopList:selectedShopList?.name
            },
          });
      
          // Mark the list as seen for this user
          await setDoc(hasSeenListDocRef, {
            listId: selectedListID,
            timestamp: serverTimestamp(),
            shopList:selectedShopList?.name,
          });
        }
      }
      
    };
    fetchUserDetails();
  }, [invitedshoppingListData, selectedShopList, invitedList, user]);
  useEffect(() => {
    if (!selectedShopList) return;
    console.log("selectedShopList", selectedShopList);
  }, [selectedShopList]);

  const handleshopListClick = async (shopListName, shopListId) => {
    const selectedShopListData = invitedshoppingListData?.find(
      (shopList) =>
        shopList?.name === shopListName && shopList?.listId === shopListId
    );
    if (selectedShopListData) {
      setListId(selectedShopListData?.listId);
    }
    setSelectedShopList({
      name: selectedShopListData?.name,
      listId: selectedShopListData?.listId,
    });

  };
  return (
    <>
      <div className="card pt-0 border border-0 rounded-2">
        <div
          className={`d-sm-flex justify-content-between align-items-center bg-transparent mt-2 nav px-3 ${
            invitedshoppingListData?.length > 0 ? "" : "border-bottom-0"
          }`}
        >
          {invitedshoppingListData?.length > 0 && (
            <ul
              className={`nav nav-bottom-line d-sm-flex align-items-center justify-content-center justify-content-md-start`}
            >
              {/* ActiveList */}
              {invitedshoppingListData.map((shopList) => (
                <li className="nav-item" key={shopList?.name}>
                  <Link
                    className={`nav-link card-title px-2 py-3 m-0 d-flex align-items-center justify-content-center ${
                      selectedShopList?.name === shopList?.name &&
                      selectedShopList?.listId === shopList?.listId
                        ? "active"
                        : ""
                    }`}
                    to=""
                    onClick={() =>
                      handleshopListClick(shopList?.name, shopList?.listId)
                    }
                  >
                    <div>{shopList?.name}</div>
                    <div
                      className="bg-primary fw-normal ms-2 text-white rounded-pill"
                      style={{ fontSize: "10px", padding: "1px 8px" }}
                    >
                      {shopList.products?.length}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <div className="d-flex justify-content-center align-items-center ">
            <Link
              to={`/userProfile/${selectedUser?.userId}`}
              className="nav-link p-0 fw-normal "
            >
              <div className="avatar" style={{ height: 0 }}>
                <img
                  className="rounded-circle"
                  src={selectedUser?.photo}
                  height={"70%"}
                  width={"70%"}
                  alt=""
                />
              </div>
              <span>{selectedUser?.name}</span>
            </Link>
          </div>
        </div>
        {invitedshoppingListData?.length > 0 && <hr className="m-0" />}
        <ProductList
          shoppingListData={invitedshoppingListData}
          selectedShopListName={selectedShopList?.name}
          selectedShopListId={selectedShopList?.listId}
          userId={user?.uid}
          role={invitedList?.find((list) => list.listId === listId)?.role}
          ownerId={invitedList?.find((list) => list.listId === listId)?.userId}
          notify={true}
        />
        {invitedshoppingListData.some(
          (shopList) =>
            shopList.name === selectedShopList?.name &&
            shopList.products?.length > 0
        ) && (
          <ListComments
            userId={user?.uid}
            listId={listId}
            shoppingListData={invitedshoppingListData}
            selectedShopList={selectedShopList?.name}
            role={invitedList?.find((list) => list.listId === listId)?.role}
            invitedBy={invitedList?.find((list) => list.listId === listId)?.userId}
            notify={true}
          />
        )}
      </div>
    </>
  );
};

export default InviteLists;
