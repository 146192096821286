import React, { useEffect } from "react";
import InvitesList from "./InvitesList";
import { useParams } from "react-router-dom";
import { UserAuth } from "../Context";
import InviteClicks from "./InviteClicks";

function InviteStats({ userData }) {
  const { uid } = useParams();
  const { user } = UserAuth();

  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }
  }, [uid]);

  // Render a loading message or similar if the user data is not yet available
  if (!uid) {
    return <div>Loading...</div>;
  }

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      {/* Card Connections START */}
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <>
          <div className="card">
            <div className="card-header border-0 pb-0">
            </div>
            <div className="card-body">
              <InviteClicks userId={user?.uid} />
              <div className="my-4">
                <hr />
              </div>
              {<InvitesList userId={user?.uid} />}
            </div>
          </div>
        </>
      )}

      {!isCurrentUserProfile && privacy === "private" && (
        <>
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h5 className="card-title">Profile Info</h5>
            </div>
            <div className="card-body">
              <p>This profile is private.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default InviteStats;
