// Function to get the base URL
export function getBaseURL() {
    let protocol = window.location.protocol;
    let host = window.location.host;
    let port = window.location.port;
  
    if (host === "localhost") {
      protocol = "http:";
      // Check if the port is specified, otherwise set it to 3000
      port = port ? port : "3000";
      host = `${host}:${port}`;
    }
  
    return `${protocol}//${host}`;
  }