import React, { useState } from "react";
import { Upload, Progress } from "antd";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { db, storage } from "../FirebaseConfig";
import { v4 } from "uuid";
import {
  addDoc,
  collection,
  doc,
  FieldValue,
  increment,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { UserAuth } from "../Context";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { Link } from "react-router-dom";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";


function ShareFeed() {
  const { user } = UserAuth() || {};
  const [textContent, setTextContent] = useState("");
  const [fileList, setFileList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showUpload, setShowUpload] = useState(true);
  const { userData, loading } = useFetchUserData(user?.uid);

  const uploadFiles = async () => {
    let promises = [];
    let totalFiles = fileList.length + videoList.length;
    let completedFiles = 0;

    const updateProgress = () => {
      completedFiles += 1;
      setUploadProgress((completedFiles / totalFiles) * 100);
    };
    if ((fileList.length === 0 && videoList.length === 0) && textContent) {
      // Handle text-only post
      createPost([]);
      setTextContent("");
      return;
    }
    if (fileList.length > 0) {
      const imagePromises = fileList.map((file) => {
        return new Promise((resolve, reject) => {
          const imageRef = ref(storage, `customPosts/images/${file.name + v4()}`);
          const uploadTask = uploadBytesResumable(imageRef, file);
          
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
              console.error("Error uploading image:", error);
              reject(error);
            }, 
            async () => {
              const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ type: "image", url: imageUrl });
              updateProgress();
            }
          );
        });
      });
      promises.push(...imagePromises);
    }

    if (videoList.length > 0) {
      const videoPromises = videoList.map((file) => {
        return new Promise((resolve, reject) => {
          const videoRef = ref(storage, `customPosts/videos/${file.name + v4()}`);
          const uploadTask = uploadBytesResumable(videoRef, file);
          
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => {
              console.error("Error uploading video:", error);
              reject(error);
            }, 
            async () => {
              const videoUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve({ type: "video", url: videoUrl });
              updateProgress();
            }
          );
        });
      });
      promises.push(...videoPromises);
    }

    if (promises.length > 0) {
      try {
        const uploadedFiles = await Promise.all(promises);
        console.log("uploadedFiles", uploadedFiles);
        createPost(uploadedFiles);
        setUploadProgress(0);
      } catch (error) {
        console.error("Error uploading files:", error);
        setUploadProgress(0);
      }
    } else {
      console.log("No files to upload.");
    }
  };

  const createPost = async (mediaDocs) => {
    try {
      const thumbnailUrl = mediaDocs.length > 0 ? mediaDocs[0].url : null;
      const mediaCount = mediaDocs.length;

      const newPost = {
        dateCreated: serverTimestamp(),
        userId: user?.uid,
        by: "user",
        content: textContent || "",
        type: mediaDocs.length > 0 ? mediaDocs[0].type : "content",
        thumbnailUrl: thumbnailUrl,
        mediaCount: mediaCount,
        flagged:true,
      };

      const postRef = await addDoc(collection(db, "posts"), newPost);

      const mediaPromises = mediaDocs.slice(1).map((mediaDoc) =>
        addDoc(collection(db, "posts", postRef.id, "media"), mediaDoc)
      );

      await Promise.all(mediaPromises);
      console.log("Post created successfully!");
      toast.success(`Post created successfully`, toastOptions);
      setShowUpload(false)
      setTextContent("");
      setFileList([]);
      setVideoList([]);
      // Update the user's document to increment the "posts" field by 1
     const userRef = doc(db, "users", user.uid);
     await updateDoc(userRef, {
      posts: increment(1)
    });
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const handleTextChange = (event) => {
    setTextContent(event.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleRemove = (file, setFileList) => {
    setFileList((prevFileList) => prevFileList.filter(item => item.uid !== file.uid));
  };
console.log("The file List is",fileList);
  return (
    <div style={{height:"18rem",padding:"4px"}}>
     
      <div class="card bg-primary text-white">
  <div class="card-body text-center">
    <i class="bi bi-plus-circle-fill display-4"></i>
    <h6 class="card-title text-white">Create your post</h6>
    <p class="card-text text-sm font-weight-light" style={{fontSize:"10px"}}>Share your thoughts...</p>
    <div class="d-grid gap-2 ">
      {/* <button class="btn btn-light" type="button">Add Photos/Videos</button>
      <button class="btn btn-light" type="button">Add Shopping List</button> */}
    
       
       
            <Link
              className="btn btn-light "
              data-bs-toggle="modal"
              data-bs-target="#feedActionPhoto"
              
            >
              {" "}
              <i className="bi bi-image-fill text-success pe-2"></i>
              Add Photo
            </Link>
        
         
            <Link
              className="btn btn-light "
              data-bs-toggle="modal"
              data-bs-target="#feedActionVideo"
            >
              {" "}
              <i className="bi bi-camera-reels-fill text-info pe-2"></i>
              Add Video
            </Link>
       
        
        </div>
  </div>
</div>
    

      <div
        className="modal fade"
        id="feedActionPhoto"
        tabIndex="-1"
        aria-labelledby="feedActionPhotoLabel"
        aria-hidden="true"
       
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="feedActionPhotoLabel">
                Add post photo
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex mb-3">
                <div className="avatar avatar-xs me-2">
                  <Link to={`/userProfile/${userData?.userId}`}>
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded-circle"
                    />
                  </Link>
                </div>
                <form className="w-100">
                  <textarea
                    className="form-control pe-4 fs-3 lh-1 border-0"
                    rows="2"
                    placeholder="Share your thoughts..."
                    value={textContent}
                    onChange={handleTextChange}
                  ></textarea>
                </form>
              </div>
              <div>
                <label className="form-label">Upload attachment</label>
                <Upload
                  listType="picture"
                  accept=".png,.jpg,.jpeg,.webp"
                  className="dropzone dropzone-default card shadow-none"
                  maxCount={10}
                  multiple={true}
                  fileList={fileList}
                  // showUploadList={fileList.length===0?false:true}
                  onRemove={(file) => handleRemove(file, setFileList)}
                  beforeUpload={(file) => {
                    setFileList((prevFileList) => [...prevFileList, file]);
                    return false;
                  }}
                  style={{ backgroundColor: '#333', color: 'white' }}
                >
                  <div className="dz-message">
                    <i className="bi bi-images display-3"></i>
                    <p>Drag here or click to upload photo.</p>
                  </div>
                </Upload>
              </div>
            </div>
            <div className="modal-footer ">
              <button
                type="button"
                className="btn btn-danger-soft me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={uploadFiles}
                type="button"
                className="btn btn-primary-soft "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="feedActionVideo"
        tabIndex="-1"
        aria-labelledby="feedActionVideoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="feedActionVideoLabel">
                Add post video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex mb-3">
                <div className="avatar avatar-xs me-2">
                  <Link to={`/userProfile/${userData?.userId}`}>
                    <ProfilePicture
                      userData={userData}
                      className="avatar-img rounded-circle"
                    />
                  </Link>
                </div>
                <form className="w-100">
                  <textarea
                    className="form-control pe-4 fs-3 lh-1 border-0"
                    rows="2"
                    placeholder="Share your thoughts..."
                    value={textContent}
                    onChange={handleTextChange}
                  ></textarea>
                </form>
              </div>
              <div>
                <label className="form-label">Upload attachment</label>
                <Upload
                  listType="picture"
                  accept=".mp4,.mkv,.avi"
                  className="dropzone dropzone-default card shadow-none"
                  maxCount={2}
                  multiple={true}
                  fileList={videoList}
                  onRemove={(file) => handleRemove(file, setVideoList)}
                  beforeUpload={(file) => {
                    setVideoList((prevVideoList) => [...prevVideoList, file]);
                    return false;
                  }}
                >
                  <div className="dz-message">
                    <i className="bi bi-camera-reels display-3"></i>
                    <p>Drag here or click to upload video.</p>
                  </div>
                </Upload>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger-soft me-2"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={uploadFiles}
                type="button"
                className="btn btn-primary-soft"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        {uploadProgress > 0 && (
          <Progress percent={Math.round(uploadProgress)} />
        )}
      </div>
    </div>
  );
}

export default ShareFeed;
