import React from "react";

function Empty() {
  return (
    <div className="my-sm-5 py-sm-5 d-flex justify-content-center align-items-center flex-column">
      <i className="display-1 text-muted bi bi-trash"> </i>
      <h4 className="mt-2 mb-1 text-body">No product found</h4>
     
    </div>
  );
}

export default Empty;
