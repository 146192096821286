import { Link } from "react-router-dom";
 import React, { useState, useEffect } from "react";
 import { db } from "../FirebaseConfig";
 import { collection, getDocs, query, where } from "firebase/firestore";
 import { UserAuth } from "../Context";
 import { calculateScores } from "../lib/calculateBrandScores";
 import useActiveBrandsStore from "../StatesStore/ActiveBrands";
 // import useUserCountry from "../StatesStore/UserCountry";
 
 function BrandsMenu() {
   const { user } = UserAuth();
   const [brands, setBrands] = useState([]);
   const brandsCollectionRef = collection(db, "brands");
   const setActiveBrands = useActiveBrandsStore((state) => state.setActiveBrands);
   // const country = useUserCountry((state) => state.country) || "PK";
 
   useEffect(() => {
     const getBrands = async () => {
       // let brandsLimit = user ? 5 : 10; // Show 5 brands when logged in, 10 when not logged in
       // let brandsLimit = 99;
 
       const qry = query(
         brandsCollectionRef,
         where("active", "==", 1)
         // where("region", "==", country)
       );
 
       const data = await getDocs(qry);
 
       const brandsWithScores = calculateScores(
         data.docs.map((doc) => doc.data())
       );
 
       const sortedBrands = brandsWithScores.sort((a, b) => {
         const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
         const nameB = b.displayName.toUpperCase(); // ignore upper and lowercase
         if (nameA < nameB) {
           return -1;
         }
         if (nameA > nameB) {
           return 1;
         }
         // names must be equal
         return 0;
       });
 
       setBrands(sortedBrands);
       setActiveBrands(sortedBrands)
     };
 
     getBrands();
   }, [user]);
 console.log("Brands",brands);

  return (
    <>
      {/* <div className="card"> */}
      <div className="dropdown text-center text-md-start">
        <Link to="/brands" className="text-decoration-none">
          <span>View All Brands</span>
        </Link>
      </div>

      <div className="dropdown-divider"></div>

      <div
        className="dropdown"
        style={{ overflowY: "auto", maxHeight: "250px" }}
      >
        <div
          className="scrollbar scrollbar-black thin"
          style={{ maxHeight: "250px", border: "none" }}
        >
          <div className="force-overflow">
            <ul className="nav nav-link-secondary flex-column fw-bold">
              {" "}
              {/* Adjust the gap value based on your preference */}
              {brands.map((brand, index) => (
                <li key={`${brand.name}-${index}`}>
                  <Link
                    className="dropdown-item py-1"
                    to={`/BrandHome/${brand?.name}`}
                  >
                    <span>{brand?.displayName}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
}

export default BrandsMenu;
