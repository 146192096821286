import { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "./FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
export const InterestContext = createContext({});

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initialize user as null
  const [underAge, setUnderAge] = useState(null); // Add this line
  const [incompleteProfile, setIncompleteProfile] = useState(false);
  const [parentalConsent, setParentalConsent] = useState(null); // Add this line
  const navigate = useNavigate();
  const googleSignIn = () => {
    const googleProvider = new GoogleAuthProvider();
    googleProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, googleProvider);
  };
  const facebookSignIn = () => {
    const facebookProvider = new FacebookAuthProvider();
    facebookProvider.addScope(
      "https://www.googleapis.com/auth/user.birthday.read"
    );
    signInWithPopup(auth, facebookProvider);
  };

  const logOut = () => {
    signOut(auth);
    setUser(null); // Set user to null on logout
  };

  const fetchUserData = async (uid) => {
    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUnderAge(userData.underAge);
      setParentalConsent(userData.parentalConsent);

      if (userDoc) {
        const { location, favBrands, favCategories } = userData;
        console.log("userdoc in context", userData);

        const isLocationIncomplete = !location || location === "";
        const isFavBrandsIncomplete = !favBrands || favBrands.length === 0;
        const isFavCategoriesIncomplete =
          !favCategories || favCategories.length === 0;

        // Set incompleteProfile to true if any of the conditions are true
        setIncompleteProfile(
          isLocationIncomplete ||
            isFavBrandsIncomplete ||
            isFavCategoriesIncomplete
        );
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = auth.currentUser;
      setUser(currentUser || null);

      if (currentUser) {
        await fetchUserData(currentUser.uid);
      } else {
        // Reset underAge and parentalConsent when there is no user
        setUnderAge(null);
        setParentalConsent(null);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, fetchData);

    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (!auth.currentUser) return;
    console.log(
      "USERRR ISSS VERRRIIFFIIIEEEDDD",
      auth.currentUser.emailVerified
    );
    if (!auth.currentUser.emailVerified) {
      console.log("VerifyEmail is calliing me byeeee");
      navigate("/VerifyEmail");
    }
  }, [auth.currentUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        underAge,
        incompleteProfile,
        parentalConsent,
        googleSignIn,
        facebookSignIn,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
