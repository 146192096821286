import { collection, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../FirebaseConfig";

async function IncrementInviteClicks(inviteCode) {
  try {
    // Check if inviteCode exists
    if (inviteCode) {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("referralCode", "==", inviteCode))
      );
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        // Increment inviteClicks attribute
        const userRef = doc.ref;
        const inviteClicksRef = doc.data()?.inviteClicks || 0;
        await updateDoc(userRef, {
          inviteClicks: inviteClicksRef + 1,
        });
      }
    }
  } catch (error) {
    console.error("Error recording click:", error);
  }
}

export default IncrementInviteClicks;
