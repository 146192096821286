import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { doc, updateDoc, getDoc, writeBatch, deleteDoc, collection, increment } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { toastOptions } from "../lib/toastOptions";
import { toast } from "react-toastify";
import formatPrice from "../lib/formatPrice";
import useActiveBrandsStore from "../StatesStore/ActiveBrands";
import { getDisplayName } from "../lib/getSupplierDisplayName";
import useModalStore from "../StatesStore/ModalStore";
import usefavStore from "../StatesStore/FavStore";

function ProductCardShoppingList({ product, listName }) {
  const { user } = UserAuth();
  const [productSize, setProductSize] = useState(null); // Corrected variable name to camelCase
  const [productData, setProductData] = useState(null);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const removeFavoriteProduct = usefavStore((state) => state.removeFavorite)
  const { openModal } = useModalStore()
  useEffect(() => {
    const fetchProduct = async () => {
      if (product?.id) {
        const productRef = doc(db, "products", product?.id);
        const productSnapshot = await getDoc(productRef);
        if (productSnapshot.exists()) {
          setProductData(productSnapshot.data());

          const batch = writeBatch(db);
          const incrementViews = {
            views: productSnapshot.data()?.views
              ? productSnapshot.data()?.views + 1
              : 1,
          };
          batch.update(doc(db, "products", product?.id), incrementViews);
          batch.commit();
        }
      }
    };

    fetchProduct();
  }, [product?.id]);

  useEffect(() => {
    const fetchProductSize = async () => {
      if (!user) return;

      const shoppingListRef = collection(doc(db, "users", user.uid), "shoppingList");
      const listDocRef = doc(shoppingListRef, listName);
      const listDoc = await getDoc(listDocRef);

      if (listDoc.exists()) {
        const listData = listDoc.data();
        const productIndex = listData.products.indexOf(product.id);

        if (productIndex > -1) {
          if (listData.sizes)
            setProductSize(listData.sizes[productIndex]);
        }
      }
    };

    fetchProductSize();
  }, [user, product.id, listName]);

  const handleTrashIconClick = async () => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);
    const shoppingListRef = collection(userRef, "shoppingList");

    try {
      const listDocRef = doc(shoppingListRef, listName);
      const listDoc = await getDoc(listDocRef);

      if (listDoc.exists()) {
        const listData = listDoc.data();
        const updatedProducts = listData.products || [];
        const updatedSizes = listData.sizes || [];

        const productIndex = updatedProducts.indexOf(product.id);

        if (productIndex > -1) {
          updatedProducts.splice(productIndex, 1);
          updatedSizes.splice(productIndex, 1);

          if (updatedProducts.length === 0) {
            // If the updated products list is empty, delete the list document
            await deleteDoc(listDocRef);
          } else {
            // Update the products and sizes arrays in the list document
            await updateDoc(listDocRef, {
              products: updatedProducts,
              sizes: updatedSizes
            });
          }

          // Decrement the shoppingListCount
          await updateDoc(userRef, {
            shoppingListCount: increment(-1),
          });
          if (listName === "Favorites")
            removeFavoriteProduct(product.id)
          toast.success(`${product.name} removed from ${listName}`, toastOptions);
        } else {
          console.error("Product not found in the list.");
        }
      } else {
        console.error("List document does not exist.");
      }
    } catch (error) {
      console.error("Error removing product from shopping list:", error.message);
    }
  };
  const handleClick = () => {
    const DATA = {
      "deleteFunction": handleTrashIconClick,
      "displayName": productData?.name
    }
    openModal("ConfirmDelete", 'shoppingListProduct', DATA)
  }
  return (
    <>
      <div className="col-6 col-lg-3">
        <div className="position-relative">
          <Link to={`/ProductDetails/${product?.id}`}>
            <img
              className="rounded img-fluid"
              src={productData?.imageUrl}
              alt=""
              style={{ width: "44vw", height: "43vh", objectFit: "cover" }}
            />
          </Link>

          {productData?.discount > 0 && (
            <div className="badge bg-danger text-white mt-2 me-2 position-absolute top-0 end-0">
              {productData?.discount}%
            </div>
          )}

          <div className="position-absolute bottom-0 start-0 p-3 d-flex w-100">
            <span className="badge bg-primary px-2 rounded text-white small">
              {getDisplayName(productData?.supplier, activeBrands)}
            </span>
          </div>
          <div className="position-absolute bottom-0  p-2 d-flex w-100">
            <span className="badge  ms-auto px-1 rounded text-white small">
              <Link
                to=""
                className="icon-sm bg-light text-secondary rounded-circle"
                onClick={() => handleClick()}
              >
                <i className="bi bi-trash"></i>
              </Link>
            </span>
          </div>
        </div>
        <ul className="nav nav-stack small">
          <li className="nav-item">
            <Link className="nav-link">
              <i className="bi bi-hand-thumbs-up-fill pe-1"></i>({productData?.likes ?? 0})
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="">
              <i className="bi bi-eye-fill pe-1"></i>({productData?.views ?? 0})
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="">
              <i className="bi bi-star-fill pe-1"></i>({productData?.favourites ?? 0})
            </Link>
          </li>
        </ul>
        <div className="hstack mt-1">
          <div>
            <h6 className="mb-0 small">
              <Link to={`/ProductDetails/${product?.id}`}>
                {productData?.name}
              </Link>
            </h6>
            <h6 className="text-secondary mb-0">
              Rs {formatPrice(productData?.newPrice)} &nbsp; &nbsp;
              {productData?.oldPrice > 0 && (
                <s className="text-secondary mb-0 small">Rs. {formatPrice(productData?.oldPrice)}</s>
              )}
            </h6>
            {productSize && (
              <h6 className="text-secondary mb-0">
                Size: {productSize}
              </h6>
            )}
          </div>
          <div className="dropdown ms-auto"></div>
        </div>
      </div>
    </>
  );
}

export default ProductCardShoppingList;
