import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import UserActiveFollowing from "./ActiveFollowing";
import { useParams } from "react-router-dom";
import axios from "axios"; // Assuming you are using axios for HTTP requests

export default function UserFollowing({ userData }) {
  const { user } = UserAuth();
  const { uid } = useParams();
  const [followinglist, setFollowinglist] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }

    const fetchFollowingList = async () => {
      try {
        // const response = await axios.get(process.env.REACT_APP_API+`/api/userFollowing/${uid}`);
        const response = await axios.get(
          process.env.REACT_APP_API + `/api/userFollowing/${uid}`
        );
        console.log("response.data", response.data);
        setFollowinglist(response.data);
      } catch (error) {
        console.error("Error fetching following list:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowingList();
  }, [uid]);

  // Render a loading message or similar if the user data is not yet available
  if (loading) {
    return <div>Loading...</div>;
  }

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      {/* Card Connections START */}

      <>
        <div className="card">
          {/* Card header START */}
          <div className="card-header border-0 pb-0">
            <h5 className="card-title">
              {followinglist.length || 0} following
            </h5>
          </div>
          {/* Card header END */}
          {/* Card body START */}
          <div className="card-body">
            {/* followers Item */}
            <UserActiveFollowing
              followinglist={followinglist}
              loggedInUser={user?.uid}
            />
            <div className="d-grid">{/* Load more button START */}</div>
          </div>
          {/* Card body END */}
        </div>
        {/* Card Connections END */}
      </>

      {!isCurrentUserProfile && privacy === "private" && (
        <>
          <div className="card">
            <div className="card-header border-0 pb-0">
              <h5 className="card-title">Profile Info</h5>
            </div>
            <div className="card-body">
              <p>This profile is private.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
