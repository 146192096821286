import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import EmptyBox from "../components/EmptyBox";
import ProductCard3 from "../Products/ProductCard3";
import Masonry from "react-responsive-masonry";
export default function SavedPosts() {
  const [savedPosts, setSavedPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = UserAuth();

  useEffect(() => {
    const fetchSavedPosts = async () => {
      if (!user) return;
      setLoading(true);
      try {
        const savedPostRef = collection(db, "users", user.uid, "saved");
        const unsubscribe = onSnapshot(savedPostRef, async () => {
          const savedPostSnap = await getDocs(savedPostRef);
          setSavedPosts(savedPostSnap.docs.map((post) => post.data()));
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching saved posts:", error);
        setLoading(false);
      }
    };

    fetchSavedPosts();
  }, [user]);
  console.log(savedPosts);
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="d-flex row mx-0">
          {savedPosts.length === 0 ? (
            <EmptyBox filename="No Saved Posts found" />
          ) : (
            savedPosts.map((savedPost, index) => (
              <div className="col-3">
                <ProductCard3 product={savedPost} />
              </div>
            ))
          )}
        </div>
      )}
    </>
  );
}
