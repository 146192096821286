import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const TestBrandCard = ({ post }) => {
  console.log(post);
  return (
    <Card className="m-2 m-sm-3 ">
      {post.image ? <Card.Img src={post.image} variant="top" /> : ""}
      <Card.Body>
        <Card.Title className="d-flex justify-content-between">
          <h3 className="fs-5 fw-normal">{post.brandName}</h3>
          <h3 className="fs-5 text-success">Rs.{post.price}</h3>
        </Card.Title>
        <Card.Text>{post.caption}</Card.Text>
        <button
          style={{
            bottom: "5%",
            right: "-5%",
            padding: "5px 8px",
            backgroundColor: "#91939c",
          }}
          className="position-absolute border border-1 border-dark  rounded-circle "
        >
          <i className={`bi bi-${post.image ? "image" : "quote"}`}></i>
        </button>
      </Card.Body>
      <Card.Footer className="p-0 bg-black ">
        <div className="d-flex text-white justify-content-evenly">
          <button type="button" class="btn py-2">
            <i className={`bi bi-hand-thumbs-up-fill`}> </i>
          </button>
          <div class="vr "></div>
          <button type="button" class="btn py-2">
            <i class="bi bi-chat-dots-fill"></i>
          </button>
          <div class="vr"></div>
          <button type="button" class="btn py-2">
            <i class="bi bi-list-ul"></i>
          </button>
          <div class="vr"></div>
          <button type="button" class="btn py-2">
            <i class="bi bi-eye-fill "></i>
          </button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default TestBrandCard;
