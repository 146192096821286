import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu from "../components/ShareMenu";
import ShareMenu2 from "../components/ShareMenu2";
import { Card, Carousel } from "react-bootstrap";
import nameShorter from "../lib/nameShorter";
import { db } from "../FirebaseConfig";
import ProductReaction from "./ProductReaction";
import BrandDisplay from "../NewsFeed/BrandDisplay";

import "./style.css";
import {
  getDoc,
  doc,
  onSnapshot,
  getDocs,
  collection,
} from "firebase/firestore";
import { UserAuth } from "../Context";
import formatPrice from "../lib/formatPrice";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import { getDisplayName } from "../lib/getSupplierDisplayName";
import useActiveBrandsStore from "../StatesStore/ActiveBrands";
import ImageCarousel from "./ImageCarousel";

const ProductCard = ({ product }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  // console.log('Active brand in recent ::',activeBrands);
  const [brand, setBrand] = useState(null); // Initialize brand state
  const { user } = UserAuth();

  useEffect(() => {
    const fetchBrand = async () => {
      if (product?.supplier) {
        setBrand(activeBrands.find((brand) => brand.name === product.supplier));
      }
    };

    fetchBrand();
  }, [product.supplier]);

  return (
    // <Card className="col-sm-6 col-md-4 shadow-lg mx-ms-1">
    <div className="px-4">
      <div className=" h-100">
        <ImageCarousel product={product} />
        <Link to={`/ProductDetails/${product?.id}`}>
          <div
            className=" position-absolute rounded-pill top-0 m-2"
            style={{
              fontSize: "10px",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {product?.newPrice && (
              <p className=" m-0 text-white px-2 py-1">
                PKR{" "}
                {product?.newPrice
                  ? formatPrice(product.newPrice)
                  : formatPrice(product.oldPrice)}
              </p>
            )}
          </div>

          <div className="px-0 py-1">
            <div className="pe-3 pb-1">
              <div
                className="d-flex align-items-center "
                style={{ paddingLeft: "0px" }}
              >
                {/* Logo */}
                <div
                  className="avatar me-2"
                  style={{ width: "35px", height: "35px" }}
                >
                  <Link to={"/BrandHome/" + product?.supplier}>
                    <img
                      className="avatar-img rounded"
                      src={`assets/images/brands/${brand?.logo}`}
                      alt=""
                    />
                  </Link>
                </div>
                <div
                  className="d-flex flex-column justify-content-start"
                  style={{ width: "78%" }}
                >
                  <div
                    className="mb-0"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <BrandDisplay
                      brandData={brand}
                      post={product}
                      type={"ProductCard"}
                    />
                  </div>
                  <div
                    className="fw-bold h6 position-relative"
                    style={{
                      marginTop: "2px",
                      fontSize: "12px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
