export const weights = {
    views: 0.1,
    favourites: 0.2,
    list: 0.3,
    likes: 0.4,
    shares: 0.5,
  };
  
  export const calculateScores = (brands) => {
    return brands.map((brand) => {
      const score = Object.entries(weights).reduce(
        (total, [weightKey, weightValue]) =>
          total + brand[weightKey] * weightValue,
        0
      );
      return { ...brand, score };
    });
  };
  