import React, { useEffect, useState } from "react";
import UserMenuHeader from "../Authentication/UserMenuHeader";
import SignInMenuHeader from "../Authentication/SignInMenuHeader";
import { UserAuth } from "../Context";
import HeaderMenu from "./HeaderMenu";
import { Link } from "react-router-dom";
import "./style.css";

function Header() {
  const { user } = UserAuth();

  // Input state is used to store user input
  const [input, setInput] = useState("");

  // Handle outside click
  const handleOutsideClick = () => {
    const main = document.getElementById("main");
    const navbarCollapsible = document.getElementById("navbarCollapse");

    function fun() {
      if (navbarCollapsible.classList.contains("show")) {
        main.setAttribute("data-bs-toggle", "collapse");
        main.setAttribute("data-bs-target", "#navbarCollapse");
      } else {
        main.removeAttribute("data-bs-target", "#navbarCollapse");
        main.removeAttribute("data-bs-toggle", "collapse");
      }
    }
    setTimeout(fun, 400);
  };

  useEffect(() => {
    const main = document.getElementById("main");
    main.addEventListener("click", handleOutsideClick);
    return () => {
      main.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Handle search
  const handleSearch = (e) => {
    e.preventDefault();
    if (input.length > 2) {
      window.location.href = `#/search/${input}`;
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-mode">
      <div className="text-center container align mt-2 mt-lg-0 mt-sm-1 mt-xs-0">
        {/* Logo START */}
        <Link className="text-body text-center fs-icon" to="/">
          <div style={{ fontFamily: "Nunito Sans" }}>
            <i>lookflock &nbsp;</i>
          </div>
        </Link>
        {/* Logo END */}

        {/* Responsive navbar toggler */}
        <button
          className="navbar-toggler ms-auto icon-md btn btn-light p-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={handleOutsideClick}
        >
          <span className="navbar-toggler-icon">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </span>
        </button>

        {/* Main navbar START */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          {/* Nav Search START */}
          <div className="nav mt-3 mt-lg-0 flex-nowrap align-items-center px-4 px-lg-0">
            <div className="nav-item w-100">
              <form
                className="rounded position-relative"
                onSubmit={handleSearch}
              >
                <input
                  className="form-control ps-5 bg-light"
                  type="search"
                  name="input"
                  placeholder="Search..."
                  aria-label="Search"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <button
                  className="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y"
                  type="submit"
                >
                  <i className="bi bi-search fs-5"></i>
                </button>
              </form>
            </div>
          </div>
          {/* Nav Search END */}

          <HeaderMenu />

          <ul className="list-unstyled d-lg-none d-flex justify-content-around rounded-2 align-items-center mx-3 mt-3">
            <Link to="/TandC" className="text-body">
              Terms of Services
            </Link>
            <div className="vr"></div>
            <Link to="/PrivacyPolicy" className="text-body">
              Privacy Policy
            </Link>
            <div className="vr"></div>
            <Link to="/contactUs" className="text-body">
              Contact Us
            </Link>
          </ul>
        </div>

        {/* Nav right START */}
        <ul className="nav flex-nowrap align-items-center list-unstyled position-relative">
          {user ? <UserMenuHeader /> : <SignInMenuHeader />}
        </ul>
      </div>
    </nav>
  );
}

export default Header;
