import { doc, collection, query, where, getDocs, setDoc, serverTimestamp, deleteDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";


const useNotification = () => {
  
  const addNotification = async ({ userId, id,type, by, fields }) => {
    try {
      const notificationsCollectionRef = collection(db, "users", userId, "notifications");

      // Create a notification document
      const notificationData = {
        timestamp: serverTimestamp(),
        id,
        type,
        by,
        ...fields, // Include additional fields dynamically
      };

      await setDoc(doc(notificationsCollectionRef), notificationData);

    } catch (error) {
      console.error("Error adding notification: ", error);
    }
  };

  const deleteNotification = async ({ userId,id, type, by, fields }) => {
    try {
      const notificationsCollectionRef = collection(db, "users", userId, "notifications");

      // Build the query dynamically based on provided filters
      const notificationConditions = [
        where("id", "==", id),
        where("type", "==", type),
        where("by", "==", by),
      ];


      if (fields && typeof fields === "object") {
        Object.keys(fields)?.forEach((key) => {
          if (fields[key] !== null && fields[key] !== undefined) {
            notificationConditions.push(where(key, "==", fields[key]));
          }
        });
      }
      console.log("inside delete notiification",notificationConditions);
      const notificationQuery = query(notificationsCollectionRef, ...notificationConditions);

      const notificationSnapshot = await getDocs(notificationQuery);
      notificationSnapshot.forEach((doc) => {
        deleteDoc(doc.ref); // Delete matching notification
      });

    } catch (error) {
      console.error("Error deleting notification: ", error);
    }
  };

  return { addNotification, deleteNotification };
};

export default useNotification;
