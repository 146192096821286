export function storeReferralCodeInLocalStorage(referralCode) {
    let existingCodes = JSON.parse(localStorage.getItem('referralCodes')) || [];
    if (!existingCodes.includes(referralCode)) {
        existingCodes.push(referralCode);
        localStorage.setItem('referralCodes', JSON.stringify(existingCodes));
    }
}

export function getStoredReferralCodesFromLocalStorage() {
    return JSON.parse(localStorage.getItem('referralCodes')) || [];
}

export function clearStoredReferralCodes() {
    localStorage.removeItem('referralCodes');
}