import React, { useEffect, useRef, useState } from "react";
import TestBrandCard from "./TestBrandCard";
import TestProductCard from "./TestProductCard";
import TestPromotionCard from "./TestPromotionCard";
import TestUserCard from "./TestUserCard";
import testdata from "./testData.json";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import useFetch from "../../hooks/useFetch";
import "./style.css";

export default function NewsFeed() {
  const [masonry, setmasonry] = useState();
  const [newsFeedItems, setNewsFeedItems] = useState([]);

  //api endpoint for loggedout user
  let url = process.env.REACT_APP_API + `/api/logoutFeed`;
  const { data, isLoading } = useFetch(url);
  console.log("Fro TestMasonry The posts are", data);

  // Invoke Masonry Function
  function initMasonry() {
    var elem = document.querySelector(".msnry");
    var msnry = new Masonry(elem, {
      itemSelector: ".grid-item",
      transitionDuration: "0.4s",
      stagger: 20,
    });
    //Update masonry cards as each image loads

    imagesLoaded(elem).on("progress", function() {
      // layout Masonry after each image loads
      msnry.layout();
    });
    setmasonry(msnry);
  }

  //Handles Expanded Effect on Click
  const handleClick = (event) => {
    const elem = event.currentTarget.parentNode.parentNode;

    console.log(elem);
    if (!elem.classList.contains("grid-item")) {
      return;
    }
    console.log(event.currentTarget);
    elem.classList.toggle("grid-item--width2");
    masonry.layout();
    if (elem.classList.contains("grid-item--width2")) {
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        // masonry.layout();
      }, 600);
    }
  };

  //Update Masonry upon setting NewsFeed
  useEffect(() => {
    if (isLoading) return;
    initMasonry();
    console.log("masonry invoked");
  }, [newsFeedItems]);

  //fetch NewsFeed Items
  useEffect(() => {
    setNewsFeedItems(data || []); // Use fetched data directly for logged-out users
  }, [data]);

  //Spinner
  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className="px-3">
      <div className="msnry">
        <div className="grid-item p-0"></div>
        {newsFeedItems.map((item, index) => {
          if (item.by == "brand") {
            return (
              <div className="grid-item">
                <TestBrandCard post={item} handleClick={handleClick} />
              </div>
            );
          } else if (item.by == "product") {
            return (
              <div onClick={handleClick} className="grid-item ">
                <TestProductCard product={item} handleClick={handleClick} />
              </div>
            );
          }
          // else {
          //   <div className="grid-item grid-item--width2 ">
          //     <TestPromotionCard post={post} />
          //   </div>;
          // }
        })}
      </div>
    </div>
  );
}
