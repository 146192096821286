import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import Avatar from "react-avatar";
import { onSnapshot, doc, collection, getDocs, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import FriendList from "./FriendList";

function UserProfile() {
  const { user } = UserAuth();
  const [userData, setUserData] = useState(null);
  const [followingCount, setFollowingCount] = useState(0);
  const [followersCount, setFollowersCount] = useState(0);
  const [postCount, setPostCount] = useState(0);

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          setUserData(doc.data());
        } else {
          console.log("User document does not exist.");
        }
      });

      return () => {
        // Unsubscribe from the snapshot listener when the component unmounts
        unsubscribe();
      };
    }
  }, [user]);
  useEffect(() => {
    if (!user) return;
    const fetchFollowersAndFollowingData = async () => {
      const userDocRef = doc(db, "users", user.uid);
      const followersRef = collection(userDocRef, "followers");
      const followerDocs = await getDocs(followersRef);
      setFollowersCount(followerDocs.size);
      const followingRef = collection(userDocRef, "following");
      const followingDocs = await getDocs(followingRef);
      setFollowingCount(followingDocs.size);
    };
    fetchFollowersAndFollowingData();
  }, [user]);
  useEffect(() => {
    if (!user) return;

    const fetchPostsCount = async () => {
      try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPostCount(userData.posts || 0);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching posts count:", error);
      }
    };

    fetchPostsCount();
  }, [user]);

  return (
    <>
      {/* Offcanvas body */}
      <div className="offcanvas-body d-block px-2 px-lg-0">
        {/* Card START */}
        <div className="card overflow-hidden">
          {/* Cover image */}
          {/* Card body START */}
          <div
            className="h-50px"
            style={{
              backgroundImage: `url(assets/images/bg/01.jpg)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="card-body pt-0">
            <div className="text-center">
              {/* Avatar */}
              {/* {renderProfilePicture()} */}
              <div class="avatar avatar-lg mt-n5 mb-3">
                <Link to={user ? `/userProfile/${user.uid}` : "/userProfile"}>
                  <ProfilePicture
                    userData={userData}
                    className="avatar-img rounded border border-white border-3"
                  />
                </Link>
              </div>
              {/* Info */}
              <h5 className="mb-0">
                <Link to={user ? `/userProfile/${user.uid}` : "/userProfile"}>
                  {userData
                    ? userData.userName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : ""}
                </Link>
              </h5>
              <small>
                <strong>{userData ? userData.userTitle : ""}</strong>
              </small>
              <p className="mt-3">
                {userData && userData.overview
                  ? userData.overview
                      .split(" ")
                      .slice(0, 10)
                      .join(" ") +
                    (userData.overview.split(" ").length > 10 ? "..." : "")
                  : ""}
              </p>

              {/* User stat START */}
              <div className="hstack gap-2 gap-xl-3 justify-content-center">
                {/* User stat item */}
                <div>
                  <h6 className="mb-0">
                    {postCount}
                  </h6>
                  <small>Post</small>
                </div>
                {/* Divider */}
                <div className="vr"></div>
                {/* User stat item */}
                <div>
                  <h6 className="mb-0">{followersCount}</h6>
                  <small>Followers</small>
                </div>
                {/* Divider */}
                <div className="vr"></div>
                {/* User stat item */}
                <div>
                  <h6 className="mb-0">{followingCount}</h6>
                  <small>Following</small>
                </div>
              </div>

              {/* User stat END */}
            </div>
            <div className="d-sm-none d-block"></div>
          </div>
          {/* Card body END */}
          {/* Card footer */}
        </div>
        <FriendList />

        {/* Card END */}
      </div>
    </>
  );
}

export default UserProfile;
