import { createUserWithEmailAndPassword } from "firebase/auth";
import { create } from "zustand";
//initial state with types
const initialState = {
  userName: "DefaultDisplayName",
  email: null,
  firstName: null,
  lastName: null,
  birthday: null,
  phoneNumber: null,
  gender: null,
  photo: null,
  overview: null,
  products: null,
  timestamp: null,
  location: null,
  userTitle: null,
  requestList: [],
  followingList: [],
  followerList: [],
  friendRequests: {},
  post: {},
  shoppinglist: {},
  location: "",
  terms: false, //adding this to users object in firestore
  heardAboutUs: "",
};

// Create a Zustand store
const useUserStore = create((set) => ({
  user: initialState,
  setUser: (newUser) => set({ user: { ...initialState, ...newUser } }),

  authUser: null,
  createUser: async (email, pass) => {
    try {
      const auth = await createUserWithEmailAndPassword(auth, email, pass);
      set({ authUser: auth });
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  },
  Id: null,
  createId: (newId) => set({ Id: newId }),
}));

export default useUserStore;
