import { create } from 'zustand';

// Define the initial state
const initialState = {
  type: null,
  open: false,
  data: null,
  title:null,
  closable:true,
};

// Create a Zustand store
const useModalStore = create((set) => ({
  ...initialState,

  // Action to open the modal
  openModal: (type, title,data,closable) => set({ type, open: true, data,title,closable }),

  // Action to close the modal
  closeModal: () => set({ type: null, open: false, data: null,title:null,closable:true }),
}));

// Export the hook to be used in components
export default useModalStore;
