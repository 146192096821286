import React, { useEffect, useRef, useState } from 'react';

function NewsFeed3() {
    const userId = 'P5VKfm6w4hZShp7hKOIXhpjgfJp1'; // Replace with the actual user ID
    const [newsFeedItems, setNewsFeedItems] = useState([]);
    const [nextCursor, setNextCursor] = useState(null);
    const [loading, setLoading] = useState(false);
    const loadMoreRef = useRef(null);

    const fetchNewsFeed = async (cursor = null) => {
        setLoading(true);
        try {
            const params = new URLSearchParams();
            if (cursor) {
                params.append('startAfter', cursor);
            }
            params.append('limit', 10);

            const response = await fetch(`http://localhost:8082/api/newsFeedPagination/newsFeed/${userId}?${params.toString()}`);
            const data = await response.json();

            const { newsFeedItems: newItems, nextCursor: newCursor } = data;
            setNewsFeedItems(prevItems => [...prevItems, ...newItems]);
            setNextCursor(newCursor);
        } catch (error) {
            console.error('Error fetching news feed:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNewsFeed(nextCursor);
    }, []); // Initial fetch on component mount

    useEffect(() => {
        if (loading) return;

        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && nextCursor) {
                fetchNewsFeed(nextCursor);
            }
        }, {
            threshold: 0.1
        });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [loading, nextCursor]);

    return (
        <div className="App">
            <h1>News Feed</h1>
            <ul>
                {newsFeedItems.map((item, index) => (
                    <li key={index}>
                        <h2 style={{height:"100px"}}>{index} Category: {item?.category}</h2>
                    </li>
                ))}
            </ul>
            {loading && <p>Loading...</p>}
            <div ref={loadMoreRef}></div>
        </div>
    );

    
}

export default NewsFeed3;



{/* <Masonry columnsCount={columnCount} className="px-1" gutter="10px">
{user && <ShareFeed />}
{(user ? newsFeedItems : data)?.map((post, index) => (
  <>
    {/* {console.log("The value of index is",index)}  */}
    {/* {console.log("The value of postCount here is",postCount)}  */}
    {/* {console.log(
      "the newsFeedItems length is " +
        newsFeedItems.length +
        "postCount " +
        postCount +
        "index " +
        index
    )}
    {console.log(
      "index === Math.floor(newsFeedItems.length*0.7) ",
      index === Math.floor(newsFeedItems.length * 0.7)
    )} */}


    // {!user && index === 90  && <div ref={loginRef}></div>}
    // {user && index === Math.floor(postCount * 0.7) && <div ref={myRef}></div>}
    // {index === newsFeedItems.length - 15 && (
    //   <div ref={loadMoreRef2}></div>
    // )}


    {/* <div style={{
height: '50px', // Example height
backgroundColor: 'black', // Example background color
margin: '20px 0', // Example margin
// Add more styling properties as needed
}}>the value of index is {index}</div> */}
    {/* {index=== Math.floor(newsFeedItems.length * 0.5) && <div style={{
height: '50px', // Example height
backgroundColor: 'black', // Example background color
margin: '20px 0', // Example margin
// Add more styling properties as needed
}}ref={loadMoreRef}></div>} */}
    // {post.by === "product" && (
    //   <ProductCard3 key={post.id} product={post} />
    // )}

    {/* <div style={{
height: '50px', // Example height
backgroundColor: 'red', // Example background color
margin: '20px 0', // Example margin
// Add more styling properties as needed
}} ref={loadMoreRef}></div> */}
    {/* <div ref={myRef}></div> */}
    {/* {user && index === postCount-10 && <div ref={loadRef}></div>} */}
//   </>
// ))}
// </Masonry> */}