import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import uuid4 from "uuid4";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  getDocs,
  collection,
  increment,
  onSnapshot,
} from "firebase/firestore";
import useModalStore from "../StatesStore/ModalStore";

function AddToList({ product, componentName }) {
  const { user } = UserAuth();
  const [savedLists, setSavedLists] = useState([]);
  const [shoppingList, setShoppingList] = useState({});
  const [newListName, setNewListName] = useState("");

  const fetchData = async () => {
    if (!user) return;

    const shoppingListRef = collection(db, "users", user.uid, "shoppingList");

    const unsubscribe = onSnapshot(shoppingListRef, async (shoppingListSnapshot) => {
      const newListNames = shoppingListSnapshot.docs.map((doc) => doc.id);
      setSavedLists(newListNames);

      const updatedShoppingList = {};

      const listPromises = shoppingListSnapshot.docs.map(async (docSnap) => {
        const listName = docSnap.id;
        const productsRef = collection(
          db,
          "users",
          user.uid,
          "shoppingList",
          listName,
          "products"
        );
        const productsSnapshot = await getDocs(productsRef);
        const productIds = productsSnapshot.docs.map((productDoc) => productDoc.id);

        updatedShoppingList[listName] = productIds;
        updatedShoppingList[listName].dateCreated = docSnap.data().dateCreated;
      });

      await Promise.all(listPromises);

      setShoppingList(updatedShoppingList);
    });

    return unsubscribe;
  };

  const { openModal } = useModalStore();
  const [selectedSize, setSelectedSize] = useState("");

  const handleClick = () => {
    if (!user) {
      openModal("SignInModal", "", "");
    }
  };

  const isProductInList = (listName) => {
    const list = shoppingList[listName];
    if (list) {
      return list.includes(product.id);
    }
    return false;
  };

  const textClass =
    componentName === "productDetails" || componentName === "strip2"
      ? "text-light"
      : "text-body";

  const handleListClick = async (listName, size = "") => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);

    try {
      const listDocRef = doc(db, "users", user.uid, "shoppingList", listName);
      const productDocRef = doc(listDocRef, "products", product.id);
      const productDoc = await getDoc(productDocRef);

      if (productDoc.exists()) {
        // If the product is in the list, remove it
        await deleteDoc(productDocRef);

        // Decrement the shoppingListCount
        await updateDoc(userRef, {
          shoppingListCount: increment(-1),
        });
        toast.success(`${product.name} removed from ${listName}`, toastOptions);
      } else {
        // If the product is not in the list, add it
        await setDoc(productDocRef, {
          size: size,
          timestamp: serverTimestamp(),
          likeCount: 0,
        });

        // Increment the shoppingListCount
        await updateDoc(userRef, {
          shoppingListCount: increment(1),
        });
        toast.success(`${product.name} added to ${listName}`, toastOptions);
      }

      // Update the local state
      setShoppingList((prevState) => {
        const list = prevState[listName] || [];
        let updatedList;
        if (productDoc.exists()) {
          // Remove product ID
          updatedList = list.filter((id) => id !== product.id);
        } else {
          // Add product ID
          updatedList = [...list, product.id];
        }
        return {
          ...prevState,
          [listName]: updatedList,
        };
      });
    } catch (error) {
      console.error("Error handling list click:", error.message);
    }
  };

  useEffect(() => {
    if (!user) return;
    fetchData();
  }, [user]);

  const handleCreateNewList = async (size = "") => {
    const listName = newListName.trim();

    if (listName) {
      // Capitalize the first letter
      const capitalizedListName =
        listName.charAt(0).toUpperCase() + listName.slice(1);

      await addToSavedList(capitalizedListName, size);
      setSavedLists([...savedLists, capitalizedListName]);
      toast.success(
        `${product.name} added to ${capitalizedListName} `,
        toastOptions
      );
      setNewListName(""); // Clear input after saving
    }
  };

  const addToSavedList = async (listName, size) => {
    if (!user) return;

    try {
      const listDocRef = doc(db, "users", user.uid, "shoppingList", listName);

      // Check if the list document exists
      const listDoc = await getDoc(listDocRef);

      if (!listDoc.exists()) {
        // If the list does not exist, create it
        await setDoc(listDocRef, {
          dateCreated: serverTimestamp(),
          listId: uuid4(),
          visibility: "Private",
        });
      }

      // Add the product to the products subcollection
      const productDocRef = doc(listDocRef, "products", product.id);
      await setDoc(productDocRef, {
        size: size,
        timestamp: serverTimestamp(),
        likeCount: 0,
      });

      // Increment the shoppingListCount
      await updateDoc(doc(db, "users", user.uid), {
        shoppingListCount: increment(1),
      });

      // Update the local state
      setShoppingList((prevState) => {
        const list = prevState[listName] || [];
        const updatedList = [...list, product.id];
        return {
          ...prevState,
          [listName]: updatedList,
        };
      });
      console.log("Product added to the list successfully!");
    } catch (error) {
      console.error("Error adding product to the list:", error.message);
    }
  };

  return (
    <div className="d-flex justify-content-between">
      <Link className="p-0 m-auto">
        <li className={`nav-link btn ${textClass} nav-item`}>
          {user && (
            <>
              <Link
                className={`nav-link btn d-flex gap-2 ${textClass} bg-default`}
                to=""
                id="feedActionList"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-list-task fs-6"></i>
                {componentName === "strip" || componentName === "strip2"
                  ? ""
                  : "Add to List"}
              </Link>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="feedActionList"
              >
                <div className="p-2 py-0 text-center">
                  <h6>Add to List</h6>
                </div>
                {savedLists.map((listName) => (
                  <li key={listName}>
                    <label className="dropdown-item d-flex gap-2">
                      <input
                        type="checkbox"
                        checked={isProductInList(listName)}
                        onChange={() => handleListClick(listName, selectedSize)}
                      />
                      {listName}
                    </label>
                  </li>
                ))}
                {savedLists.length > 0 && (
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                )}
                <li>
                  <div className="input-group d-flex gap-2 p-2">
                    <input
                      type="text"
                      className="form-control form-control-sm focus-ring focus-ring-secondary py-1 px-2 text-decoration-none border rounded-2"
                      placeholder="Create new list"
                      value={newListName}
                      onChange={(e) => setNewListName(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-light"
                      onClick={() => handleCreateNewList(selectedSize)}
                    >
                      Save
                    </button>
                  </div>
                </li>
              </ul>
            </>
          )}
          {!user && (
            <Link
              to=""
              className={`nav-link btn d-flex gap-2 ${textClass} bg-defualt`}
              onClick={handleClick}
            >
              <i className="bi bi-list-task fs-6"></i>
              {componentName === "strip" || componentName === "strip2"
                ? ""
                : "Add to List"}
            </Link>
          )}
        </li>
      </Link>
    </div>
  );
}

export default AddToList;
