import React from "react";
import { SideNavPriceRangeDropDown ,SideNavGenderDropDown ,SideNavSizeDropDown, SideNavColorDropDown, SideNavCategoryDropDown , SideNavSubCategoryDropDown} from "./SideNavBrandDropDown";

export default function BrandsPageSideNav( {brandName} ) {
// console.log('Results are',Result);

  return (
    <div className="offcanvas-body d-block bg-mode rounded-2 p-0" >
      {/* Card START */}
      <p className="fw-semibold m-0 fs-2 px-4 py-4">Filters &nbsp;</p>
      {/* GENDER */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseGender"
          aria-expanded="false"
          aria-controls="collapseGender"
        >
          Gender &nbsp;
        </p>
          <SideNavGenderDropDown dataId={"collapseGender"} />
      </div>
      <hr className="m-0" />


      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseCategory"
          aria-expanded="false"
          aria-controls="collapseCategory"
        >
          Category &nbsp;
        </p>
          <SideNavCategoryDropDown dataId={"collapseCategory"} />
      </div>
      <hr className="m-0" />
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSubCategory"
          aria-expanded="false"
          aria-controls="collapseSubCategory"
        >
         Sub-Categories &nbsp;
        </p>
          <SideNavSubCategoryDropDown dataId={"collapseSubCategory"} brandName={brandName} />
      </div>
      <hr className="m-0" />
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapsePriceRange"
          aria-expanded="false"
          aria-controls="collapsePriceRange"
        >
          Price &nbsp;
        </p>
          <SideNavPriceRangeDropDown dataId={"collapsePriceRange"} />
      </div>
      <hr className="m-0" />
      {/* PRICE RANGE */}
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseSize"
          aria-expanded="false"
          aria-controls="collapseSize"
        >
          Size &nbsp;
        </p>
          <SideNavSizeDropDown  dataId={"collapseSize"} brandName={brandName} />
      </div>
      <hr className="m-0" />
      <div className="px-4 py-3">
        <p
          className="fw-semibold"
          data-bs-toggle="collapse"
          data-bs-target="#collapseColor"
          aria-expanded="false"
          aria-controls="collapseColor"
        >
         Color &nbsp;
        </p>
        <SideNavColorDropDown  dataId={"collapseColor"} brandName={brandName} />
        </div>
      <hr className="m-0" />
    </div>
  );
}
