import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "./FirebaseConfig";
import { Button, Flex } from "antd";
import formatPrice from "./lib/formatPrice";
import {
  collection,
  query,
  where,
  getDocs,
  documentId,
} from "firebase/firestore";
import AddOrRemoveLike from "./Products/AddOrRemoveLike";
import AddOrRemoveFavourites from "./Products/AddOrRemoveFavourites";
import { UserAuth } from "./Context";
import ShareMenu2 from "./components/ShareMenu2";
import AddToList from "./components/AddToList";

import { incrementProductView } from "./components/IncrementProductView";
import usePost from "./hooks/usePost";
import ColorSelector from "./Experiments/colorDisplay";
import ProductFeedback from "./components/ProductFeedback";
import RecentViewed from "./Products/RecentViewed";
import ReviewsList from "./Products/RenderReviews";
import { getDisplayName } from "./lib/getSupplierDisplayName";
import useActiveBrandsStore from "./StatesStore/ActiveBrands";
import ReviewsStars from "./lib/fetchProductRating";
import { Eye } from "react-bootstrap-icons";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [mainImg, setMainImg] = useState();
  const [secImgs, setSecImg] = useState([]);
  const [likeCount, setLikeCount] = useState(0);
  const [favCount, setFavCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [views, setViews] = useState(0);
  const [error, setError] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const { user } = UserAuth();
  const [showRP, setShowRP] = useState(false);
  const ActiveBrands = useActiveBrandsStore((state) => state.activeBrands);

  let url = process.env.REACT_APP_API + `/api/activity/${user?.uid}`;

  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`;
  const { data: postData, post } = usePost(url);

  const collectionRef = collection(db, "products");
  useEffect(() => {
    console.log("Hello prod Details 4");
    const getProduct = async () => {
      setLoading(true);
      const q = query(collectionRef, where(documentId(), "==", id));

      const data = await getDocs(q);
      const docData = data.docs[0]?.data();
      setProduct({ ...docData, id: data.docs[0]?.id });
      setLoading(false);
    };
    if (id) {
      getProduct();
    }
  }, [id]);

  useEffect(() => {
    if (!user) return;
    const fetchUserData = async () => {
      if (user) {
        try {
          const userActivityRef = collection(
            db,
            "users",
            user.uid,
            "userActivity"
          );
          const q = query(userActivityRef, where("type", "==", "view"));
          const querySnapshot = await getDocs(q);
          const viewedProductIds = querySnapshot.docs.map(
            (doc) => doc.data().product
          );
          const uniqueProductIds = Array.from(new Set(viewedProductIds));
          setShowRP(uniqueProductIds.length > 0);
        } catch (error) {
          console.log("Recent products not found");
          setShowRP(false);
        }
      }
    };
    fetchUserData();
  }, [user]);
  useEffect(() => {
    if (!product) return;
    const fetchProductData = async () => {
      if (product) {
        setMainImg(product?.secondaryImages?.[0] || product?.imageUrl);
        setSecImg(product?.secondaryImages);
        setLikeCount(product?.likes);
        setFavCount(product?.favourites);
        setViews(product?.views);
        await incrementProductView(product);
        let data = {
          product: product.id,
          brand: product.supplier,
          category: product.category,
          subCategory: product.subCategory,
          subSubCategory: product.subSubCategory,
          newPrice: product.newPrice,
          discount: product.discount,
          type: "view",
        };

        post(data);
      }
    };
    const fetchReviews = async () => {
      try {
        const reviewsRef = collection(db, `products/${product?.id}/Reviews`);
        const querySnapshot = await getDocs(reviewsRef);
        const reviewsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setReviews(reviewsData);

        if (reviewsData.length > 0) {
          const totalRating = reviewsData.reduce(
            (acc, review) => acc + review.rating,
            0
          );
          const avgRating = (totalRating / reviewsData.length).toFixed(1);
          // console.log(avgRating);
          setAverageRating(avgRating);
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError("Failed to load reviews.");
      }
    };

    fetchReviews();
    fetchProductData();
  }, [product]);

  const handleSwapImage = (index) => {
    console.log("Hello prod Details 2");
    if (index < 0 || index >= secImgs.length) {
      console.error("Invalid image index for swapping");
      return;
    }
    setMainImg(secImgs[index]);
  };

  const goToBottom = () => {
    console.log("Hello prod Details 3");
    document.body.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="row g-4 g-lg-0 justify-content-between">
            <div className="col-lg-5">
              <div className="row g-2">
                <div className="col-12">
                  <div
                    className="rounded-2 position-relative"
                    style={{ padding: 0 }}
                  >
                    <div
                      className="stretched-link cursor-zoom"
                      data-glightbox=""
                      data-gallery="image-popup"
                    >
                      <img
                        src={mainImg}
                        alt=""
                        className="rounded border hover-zoom"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                  {/* Secondary Images */}
                  <div className="row mt-2 justify-content-center">
                    {secImgs?.map((image, index) => (
                      <div className="col-3 d-flex " key={index}>
                        <div className="rounded-2 glightbox-bg position-relative">
                          <Link
                            onClick={() => handleSwapImage(index)}
                            className="stretched-link cursor-zoom"
                            data-glightbox=""
                            data-gallery="image-popup"
                          >
                            <img
                              src={image}
                              alt=""
                              className="rounded border img-fluid"
                            />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* End of Secondary Images */}
                </div>
              </div>
            </div>

            {/* Detail */}
            <div className="container mainArea col-lg-6 col-12">
              <div className="my-2 productName">
                <h6 className="h3 fw-light">{product?.name}</h6>
              </div>
              <div className="Details d-flex align-items-center row">
                <div className="brandName col-12 col-lg-4">
                  <span>Brand: </span>
                  <span className="h6 fw-light">
                    {getDisplayName(product?.supplier, ActiveBrands)}
                  </span>
                </div>
                <div className="rating me-3 pe-0  d-flex col-7 col-lg-4">
                  {averageRating ? (
                    <div className="d-flex">
                      {[...Array(5)].map((_, starIndex) => (
                        <span key={starIndex}>
                          {starIndex < Math.round(averageRating) ? (
                            <i className="bi bi-star-fill text-warning"></i>
                          ) : (
                            <i className="bi bi-star text-warning"></i>
                          )}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <p
                      className="mb-0"
                      onClick={goToBottom}
                      style={{ cursor: "pointer" }}
                    >
                      Be the first to rate
                    </p>
                  )}
                  <span className="mx-2">{averageRating} Rating</span>
                </div>
                <div className="views d-flex align-items-center col-4 col-lg-3">
                  <Eye className="fs-5" />
                  <span className="ms-2 h6 fw-light m-0">{views} Views</span>
                </div>
              </div>
              <div className="price d-flex align-items-center pt-4">
                <div className="text-danger h3 fw-light">
                  PKR {formatPrice(product?.newPrice)}
                  {/* Rs.6375 */}
                </div>
                {product?.oldPrice > 0 && (
                  <strike className="text-body h6 fw-light mx-2">
                    PKR {formatPrice(product?.oldPrice)}
                    {/* Rs.9735 */}
                  </strike>
                )}
                {product?.discount > 0 && (
                  <span className="mx-2 h6 fw-light badge rounded-pill bg-warning p-2">
                    {product?.discount}% Discount
                  </span>
                )}
              </div>
              <hr className="text-body " />
              <div className="Buttons d-flex align-items-center mb-4">
                {/* Add to list */}
                <div className="bg-primary w-50 rounded py-2">
                  <AddToList
                    product={product}
                    componentName={"productDetails"}
                  />
                </div>
                <div className="favCount rounded border border-primary text-primary p-2 ms-2 fw-light d-flex align-items-center">
                  {/* {"2.7k"} FavCount */}
                  <div className="me-2">
                    {favCount} {/* FavCount */}
                  </div>
                  <AddOrRemoveFavourites
                    product={product}
                    user={user}
                    componentName={"productDescriptions"}
                  />
                </div>
                <div className="likeCount rounded border border-primary text-primary p-2 ms-2 fw-light d-flex align-items-center">
                  {/* {"2.7k"} LikeCount */}
                  <div className="me-2">
                    {likeCount} {/* LikeCount */}
                  </div>
                  <AddOrRemoveLike
                    user={user}
                    product={product}
                    setLikeCount={setLikeCount}
                    componentName={"productDescriptions"}
                  />
                </div>
                <div className="shareButton rounded border border-primary text-primary p-2 ms-2 fw-light">
                  <ShareMenu2
                    product={product}
                    componentName={"productDescriptions"}
                  />
                </div>
              </div>
              <div className="productDetails my-4">
                <div className="h4 fw-light">Product Details</div>
                {(product?.sizes.length > 0 || product?.colors.length > 0) && (
                  <div className="sizeAndColor d-flex justify-content-between my-4 row">
                    {product?.sizes.length > 0 && (
                      <div className="sizeDiv h6 fw-light d-flex flex-column col-12 col-lg-6">
                        <span>Size Available</span>
                        <div className="d-flex my-4">
                          {product?.sizes?.map((size, index) => (
                            <div
                              className="btn btn-outline-light h6 fw-light me-2 rounded border border-secondary py-2 px-3"
                              style={{ cursor: "pointer" }}
                              disabled
                            >
                              {size}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {product?.colors.length > 0 && (
                      <div className="colorDiv h6 fw-light col-12 col-lg-5">
                        <div>Available Colors</div>
                        <div className="d-flex my-4">
                          {product?.colors?.length > 0 && (
                            <ColorSelector colors={product?.colors} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="productDescriptionSection">
                <p dangerouslySetInnerHTML={{ __html: product?.description }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {showRP && <RecentViewed />}
      <hr />
      {/* <h6>Ratings & Reviews</h6>       */}
      {user && (
        <>
          <ProductFeedback userId={user} productID={product?.id} />
          <hr />
        </>
      )}
      <ReviewsList productId={product?.id} />
    </>
  );
};

export default ProductDetails;
