import React, { useEffect, useState } from "react";
import {
  doc,
  getDoc,
  setDoc,
  arrayUnion,
  writeBatch,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { collection } from "firebase/firestore";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import useNotification from "../hooks/useNotification";

export default function AddComment({ post, setCommentCount,user }) {
  const [input, setInput] = useState("");
  const [userData, setUserData] = useState({});
  const { addNotification } = useNotification();

  const handleSubmit = async (e) => {
    if (!user || !userData) return;

    e.preventDefault();

    if (input) {
      const postRef = collection(db, "posts", post.id, "comments");

      const commentObj = {
        content: input,
        timestamp: serverTimestamp(),
        userId: user.uid,
      };
      setInput("");
      // Create a new document with a specific id in the comments collection
      const commentDocRef = await addDoc(postRef, commentObj);
      const commentDocId = commentDocRef.id;
      setCommentCount((prev) => prev + 1);
      // Clear the input
    

      if (post.userId !== user.uid) {
        await addNotification({
          userId: post?.userId,
          id: user?.uid,
          timestamp: serverTimestamp(),
          type: "postComment",
          by: "user",
          fields: {
            postId: post?.id,
            commentId: commentDocId,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (user) {
      const fetchUser = async () => {
        const userRef = doc(db, "users", user?.uid);
        const userSnapshot = await getDoc(userRef);
        const currentUser = userSnapshot.data();
        setUserData(currentUser);
      };
      fetchUser();
    }
  }, [user]);

  return (
    <>
      {/* Add comment */}
      <div className="d-flex">
        <div className="avatar avatar-xs me-2">
          <ProfilePicture
            userData={userData}
            avatarSize={35}
            className="avatar-img rounded-circle"
          />
        </div>
        {/* Comment box  */}
        <form
          className="nav nav-item w-100 position-relative"
          onSubmit={handleSubmit}
        >
          <textarea
            data-autoresize
            className="form-control bg-light "
            rows="1"
            placeholder="Add comment"
            value={input}
            style={{ scrollbarWidth: "none", borderRadius: "30px" }}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          ></textarea>

          <button
            className="nav-link bg-transparent px-3 position-absolute top-50 end-0 translate-middle-y border-0"
            type="submit"
          >
            <i className="bi bi-send-fill"> </i>
          </button>
        </form>
      </div>
    </>
  );
}
