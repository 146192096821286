// Map to convert category names to display names
const categoryDisplayNameMap = {
  "1-Pc": "1 Piece",
  "2-Pc": "2 Piece",
  "3-Pc": "3 Piece",
  "All": "All Products",
  "Stoles": "Dupattas & Shawls",
  "Tops": "Kurtas & Tops",
  "Bottoms": "Shalwars & Bottoms",
  "Dresses": "Dresses",
  "Sarees": "Sarees",
  "Suit": "Suit",
  "T-Shirts": "T-Shirts & Tops",
  "Shirts": "Shirts & Blouses",
  "Hoodies": "Hoodies & Sweatshirts",
  "Burqa": "Burqa",
  "Sweaters": "Sweaters",
  "Sleepwear": "Sleepwear",
  "Jackets": "Jackets",
  "Jeans": "Jeans & Trousers",
  "BottomsWestern": "Trousers & Skirts",
  "Kurta": "Kurta",
  "ShalwarKameez": "Shalwar Kameez",
  "WaistCoat": "Waist Coat (ShalwarKameez)",
  "BottomsShalwarKameez": "Shalwars & Pajamas",
  "FormalBottoms": "Formal Bottoms",
  "FormalTops": "Formal Tops",
  "Suits": "Suits",
  "Blazers": "Blazers",
  "ExoticSuit": "Sherwani & Prince Suits",
  "Shawls": "Shawls & Mufflers",
  "Polos": "Polos",
  "BottomsWestern": "Trousers & Shorts",
  "CasualT-Shirts": "Casual T-Shirts",
  "CasualShirts": "Casual Shirts"
};

// Function to get display name from category name
export const getCategoryDisplayName = (categoryName) => {
  return categoryDisplayNameMap[categoryName] || categoryName;
};
