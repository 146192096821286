
import { useState, useEffect } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../FirebaseConfig';


export const useFetchUserData = (userId) => {
 const [userData, setUserData] = useState(null);
 const [loading, setLoading] = useState(true);

 useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) return;
      try {
        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setUserData({
            ...userDocSnapshot.data(),
            userId: userDocSnapshot.id,
          });
        } else {
          console.log("User document does not exist.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }finally{
        setLoading(false);
      }
    };
    fetchUserData();
 }, [userId]);

 return { userData, loading };
};
