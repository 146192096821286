import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const db = getFirestore();

const formatTimestampForPost = (timestamp) => {
  if (!timestamp || (!timestamp.toDate && !timestamp._seconds)) {
    return "N/A";
  }

  const postDate = timestamp.toDate ? timestamp.toDate() : new Date(timestamp._seconds * 1000);
  const currentDate = new Date();
  const timeDifference = currentDate - postDate;

  // Convert milliseconds to hours
  const hours = Math.floor(timeDifference / 3600000);
  if (hours < 2) {
    // If less than 2 hours, display in hours and minutes
    const options = { hour: "numeric", minute: "numeric" };
    return postDate.toLocaleTimeString("en-US", options);
  } else if (hours < 24) {
    // If between 2 and 24 hours, display in hours
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    // If greater than or equal to 24 hours, convert to days
    const days = Math.floor(hours / 24);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
};

const ReviewsList = ({ productId }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        // Reference to the reviews subcollection
        const reviewsRef = collection(db, `products/${productId}/Reviews`);
        
        // Fetch all review documents
        const querySnapshot = await getDocs(reviewsRef);
        
        // Map review documents to an array of review data
        const reviewsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError("Failed to load reviews.");
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [productId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="container" style= {{paddingLeft: '35px'}}>
      {reviews.length === 0 ? (
        <div className="text-center my-3">No reviews yet.</div>
      ) :  (
        reviews.map((review, index) => (
          <div 
            key={review.id} 
            className={`d-flex align-items-start py-3 ${index !== reviews.length - 1 ? 'border-bottom' : ''}`}
          >
            {review.userId?.photoURL ? (
              <img
                src={review.userId.photoURL}
                className="rounded border me-3"
                style={{ width: '25px', height: '25px' }}
              />
            ) : (
              <div
                className="rounded border bg-secondary me-3"
                style={{ width: '25px', height: '25px' }}
              />
            )}
            <div className="flex-grow-1">
              <p className="fw-bold mb-1">{review.userId?.displayName}</p>
              <p className="mb-1">{review.comment}</p>
              <div className="d-flex mb-1">
                {[...Array(5)].map((_, starIndex) => (
                  <span key={starIndex}>
                    {starIndex < review.rating ? (
                      <i className="bi bi-star-fill text-warning"></i>
                    ) : (
                      <i className="bi bi-star text-warning"></i>
                    )}
                  </span>
                ))}
              </div>
              <p className="text small">{formatTimestampForPost(review.timestamp)}</p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ReviewsList;