import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { collection, doc, getDoc, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { useParams } from "react-router-dom";
import { UserAuth } from "../Context";

export default function FriendList() {
  const { user } = UserAuth();
  const [userData, setUserData] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const uid = user.uid;
  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }
  
    const fetchConnections = async () => {
      try {
        const connectionsRef = collection(db, "users", uid, "connections");
        const connectionsSnapshot = await getDocs(connectionsRef);
  
        const activeList = [];
  
        connectionsSnapshot.forEach((doc) => {
          const connectionData = doc.data();
          if (connectionData.type === "active") {
            activeList.push(doc.id); // Assuming doc.id is the friend's UID
          }
        });
  
        setActiveList(activeList);
      } catch (error) {
        console.error("Error fetching connections:", error.message);
      }
    };
  
    fetchConnections();
  }, [uid]);
  
  
  useEffect(() => {
    if (!activeList || activeList.length === 0) {
      // If it's null or empty, you can either set the userData to an empty array or keep the existing data
      setUserData([]);
      return; // Prevents further execution of the useEffect
    }

    const fetchUserData = async () => {
      try {
        const userList = await Promise.all(
          activeList.map(async (userId) => {
            const docSnap = await getDoc(doc(db, "users", userId));
            if (docSnap.exists()) {
              return { ...docSnap.data(), uid: docSnap.id };
            } else {
              console.log("No such document!");
              return null;
            }
          })
        );

        // Filter out any null values if a document wasn't found
        const filteredUserList = userList.filter((user) => user !== null);
        console.log("Fetched users from active friends:", filteredUserList);
        setUserData(filteredUserList);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUserData();
  }, [activeList]);

  return userData.length ? (
    <div className="card overflow-hidden my-4">
      <h6 className="mt-3 mb-2 text-center"> My Friend List</h6>
      <hr className=" m-0" />
      <ul class="nav nav-link-secondary flex-column fw-bold gap-2 px-3 py-2 mb-1">
        {userData.map((user) => {
          return (
            <li>
              <Link
                to={`/userProfile/${user.uid}`}
                className="nav-link p-0 fw-normal"
              >
                <div class="avatar me-3 mb-3 mb-md-0">
                  <ProfilePicture
                    userData={user}
                    avatarSize={42}
                    className={"rounded-2"}
                  />
                </div>
                {user.firstName}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    ""
  );
}
