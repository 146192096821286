import React from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { toastOptions } from "../lib/toastOptions";
import { toast } from "react-toastify";
import formatPrice from "../lib/formatPrice";
import { Link } from "react-router-dom";
import { useFetchAndUpdateProduct } from "../lib/useFetchAndUpdateProduct";
import ProductList from "./ProductList";
import useModalStore from "../StatesStore/ModalStore";
import { dataNewsFeed, titleNewsFeed } from "../Content/ModalContent";
import EmptyBox from "../components/EmptyBox";
import EmptyList from "./EmptyList";

function SharedProductCardShoppingList({
  product,
  listName,
  userId,
  visibility,
  friends,
  shoppingListData,
  selectedShopList,
  listId,
  ownerId
}) {
 
  // const productData = useFetchAndUpdateProduct(product);



  console.log("Inside shared selectedShopList", selectedShopList);
  console.log("Inside shared listId", listId);
  console.log("Inside shared shoppingListData", shoppingListData);
  // Check if the user is allowed to view the product list

  return (
    <>
      <ProductList
        shoppingListData={shoppingListData}
        selectedShopListName={listName}
        selectedShopListId={listId}
        userId={userId}
        ownerId={ownerId}
      />
    </>
  );
}

export default SharedProductCardShoppingList;
