import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDoc,
  doc,
  where,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "./FirebaseConfig";
import ProfilePicture from "./UserProfile/ProfilePicture";
import { UserAuth } from "./Context";
import { formatTimestampForPost } from "./lib/formatTimestampForPost";

function Comments({ userId, listId }) {
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState([]);

  const { user } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const text = commentText;
    // Clear the comment input field
    setCommentText("");

    try {
      // Create a new comment document with specified attributes
      await addDoc(collection(db, "shoppingListComments"), {
        comment: text,
        timestamp: serverTimestamp(),
        userId: userId,
        listId: listId,
      });

      // Fetch the comments again to include the newly added comment
      fetchComments();
    } catch (error) {
      console.error("Error adding comment: ", error);
      // Handle any errors here, e.g., display an error message to the user
    }
  };

  const fetchComments = async () => {
    if (!listId) {
      setComments([]);
      return;
    }

    const q = query(
      collection(db, "shoppingListComments"),
      where("listId", "==", listId),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const newComments = querySnapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          userName: "", // Initialize userName to an empty string
          photo: "",
        };
      });

      // Fetch user data for each comment asynchronously
      await Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            comment.userName = userSnapshot.data()?.userName; // Update userName
            comment.photo = userSnapshot.data()?.photo;
          } else {
            // Handle the case where the user document doesn't exist
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }
        })
      );

      // Set the comments after fetching user data
      setComments(newComments);
    });

    return unsubscribe;
  };

  useEffect(() => {
    fetchComments();
  }, [listId]);

  useEffect(() => {
    const fetchUser = async () => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot?.data();
        setLoggedInUser(userData);
      }
    };
    fetchUser();
  }, [user]);


  return (
    <>
      <div className="card-body">
        <div className="d-flex mb-3">
          {/* Avatar */}
          <div className="avatar avatar-xs me-2">
            <ProfilePicture userData={loggedInUser}  className='avatar-img rounded-circle'/>
          </div>
          {/* Comment box  */}
          <form
            className="nav nav-item w-100 position-relative"
            onSubmit={handleSubmit}
          >
            <textarea
              className="form-control mb-sm-2 mb-3"
              data-autoresize
              rows="1"
              placeholder="Add a comment..."
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
            ></textarea>

            <button
              className="nav-link bg-transparent px-6 position-absolute top-50 end-0 translate-middle-y border-0"
              type="submit"
            >
              <i className="bi bi-send-fill"> </i>
            </button>
          </form>
        </div>

        <ul className="comment-wrap list-unstyled">
  {comments?.map((comment, index) => (
    (comment.userId === user.uid) ? (
      <li key={index} className="comment-item">
        <div className="d-flex justify-content-end">
          {/* Avatar */}
          <div className="ms-2">
            {/* Comment by */}
            <div className="bg-light rounded-start-top-0 p-3 rounded">
              <div className="d-flex justify-content-between">
                <h6 className="mb-2">
                  <Link to={`/userProfile/${comment.userId}`} className="avatar avatar-xs ">
                    <ProfilePicture userData={comment} className='avatar-img rounded-circle'/>
                  </Link>
                  &nbsp; {/* Non-breaking space */}
                  <Link to={`/userProfile/${comment.userId}`}>{comment.userName.split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")} </Link>
                </h6> 
                <small className="ms-2">
                  {formatTimestampForPost(comment.timestamp)}
                </small>
              </div>
              <p className="small mb-0">{comment.comment}</p>
            </div>
            {/* Comment react */}
            <ul className="nav nav-divider py-2 small"></ul>
          </div>
        </div>
      </li>
    ) : (
      <li key={index} className="comment-item">
        <div className="d-flex justify-content-start">
          {/* Avatar */}
          <div className="ms-2">
            {/* Comment by */}
            <div className="bg-light rounded-start-top-0 p-3 rounded">
              <div className="d-flex justify-content-between">
              <h6 className="mb-2">
                  <Link to={`/userProfile/${comment.userId}`}>
                    <ProfilePicture userData={comment} avatarSize={15} className='avatar-xs'/>
                  </Link>
                  &nbsp; {/* Non-breaking space */}
                  <Link to={`/userProfile/${comment.userId}`}>{comment.userName.split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")} </Link>
                </h6> 
                <small className="ms-2">
                  {formatTimestampForPost(comment.timestamp)}
                </small>
              </div>
              <p className="small mb-0">{comment.comment}</p>
            </div>
            {/* Comment react */}
            <ul className="nav nav-divider py-2 small"></ul>
          </div>
        </div>
      </li>
    )
  ))}
</ul>

      </div>
    </>
  );
}

export default Comments;
