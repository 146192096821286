import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  limit,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";

function InvitedFriendsAvatar({ listName, listId, userId }) {
  const [avatars, setAvatars] = useState([]);
  const [extraCount, setExtraCount] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  useEffect(() => {
    const fetchInvitedFriends = async () => {
      try {
        // Get members' document IDs from the members subcollection
        const membersRef = collection(
          db,
          "users",
          userId,
          "shoppingList",
          listName,
          "members"
        );
        const membersQuery = query(membersRef, limit(3));
        const membersSnapshot = await getDocs(membersQuery);

        const memberIds = membersSnapshot.docs.map((doc) => doc.id);

        // Fetch user documents for the first 3 members
        const userAvatars = await Promise.all(
          memberIds.map(async (memberId) => {
            const userDocRef = doc(db, "users", memberId);
            const userDoc = await getDoc(userDocRef);
            const userData = userDoc.data();
            const avatarInitials = userData.userName
              ? userData.userName[0].toUpperCase()
              : "";
            return {
              photo:
                userData.photo ||
                `https://ui-avatars.com/api/?name=${avatarInitials}`,
              displayName: userData.userName,
            };
          })
        );

        // Get the count of all documents in the members subcollection
        const allMembersSnapshot = await getDocs(
          collection(db, "users", userId, "shoppingList", listName, "members")
        );
        const totalMembers = allMembersSnapshot.size;
        setFriendsCount(totalMembers);
        const extraMembersCount = totalMembers > 3 ? totalMembers - 3 : 0;

        setAvatars(userAvatars);
        setExtraCount(extraMembersCount);
      } catch (error) {
        console.error("Error fetching invited friends:", error);
      }
    };

    fetchInvitedFriends();
  }, [listName, userId]);

  console.log("Avatars", avatars);

  return (
    <ul className="avatar-group list-unstyled justify-content-center justify-content-md-start align-items-center mb-0 flex-wrap">
      {
        <>
          {avatars.map((avatar, index) => (
            <li key={index} className="avatar avatar-xs ">
              <img
                className="avatar-img rounded-circle"
                src={avatar.photo}
                alt={avatar.userName || "avatar"}
              />
            </li>
          ))}
          <li className="ms-3 my-0 h6">
            {" "}
            {friendsCount}
            {friendsCount === 1 ? " Friend" : " Friends"}
          </li>
        </>
      }
      {extraCount > 0 && (
        <li className="avatar avatar-xs me-2">
          <div className="avatar-img rounded-circle bg-primary">
            <span className="smaller text-white position-absolute top-50 start-50 translate-middle">
              +{extraCount}
            </span>
          </div>
        </li>
      )}
    </ul>
  );
}

export default InvitedFriendsAvatar;
