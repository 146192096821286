import React from "react";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import formatTimestamp from "../lib/formatTimestamp";

function JoinedUser({ notification }) {
  const { userData } = useFetchUserData(notification?.id);
  return (
    <li>
      <div class="list-group-item list-group-item-action rounded badge-unread d-flex border-0 mb-1 p-3">
        <div class="avatar text-center d-none d-sm-inline-block">
          <img
            class="avatar-img rounded-circle"
            src={
              userData?.photo ??
              `https://ui-avatars.com/api/?name=${userData?.userName}`
            }
            alt=""
          />
        </div>
        <div class="ms-sm-3">
          <div class=" d-flex">
            <p class="small mb-2">
              <b>{userData?.userName}</b> accepted your invitation to join.
            </p>
            <p class="small ms-3">{formatTimestamp(notification.timestamp)}</p>
          </div>
        </div>
      </div>
    </li>
  );
}

export default JoinedUser;
