import React from 'react';
import { useLocation } from 'react-router-dom';

function Support() {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const page = pathSegments[pathSegments.length - 1];
  return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
        You are on 
          <span style={{fontSize:'30'}}> 
              &nbsp;{page.toUpperCase()}&nbsp; 
          </span> 
        Page
      </div>
  );
}

export default Support;
