
import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { followBrand, getBrandDoc, unfollowBrand } from '../../lib/brandFunctions';

export const useFollowStatus = (brandId, userId) => {
    const [isFollowing, setIsFollowing] = useState(false);
  
    useEffect(() => {
      const checkFollowStatus = async () => {
        try {
          const brandDoc = await getBrandDoc(brandId);
          if (brandDoc?.exists()) {
            const followerDoc = doc(brandDoc?.ref, 'followers', userId);
            const followerSnapshot = await getDoc(followerDoc);
            setIsFollowing(followerSnapshot?.exists());
          } else {
            console.error('Brand document does not exist');
          }
        } catch (error) {
          console.error('Error checking follow status:', error);
        }
      };
      
      if(brandId && userId)
            checkFollowStatus();

    }, [brandId, userId]);
  
    const toggleFollow = async () => {
      try {
        if (isFollowing) {
          await unfollowBrand(brandId, userId);
        } else {
          await followBrand(brandId, userId);
        }
        setIsFollowing(!isFollowing);
      } catch (error) {
        console.error('Error toggling follow:', error);
      }
    };
  
    return { isFollowing, toggleFollow };
  };
  
