// src/PhoneAuth.js
import React, { useState } from 'react';
import { auth } from './FirebaseConfig';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { toast } from 'react-toastify';
import { toastOptions } from './lib/toastOptions';
import { Button, Container } from 'react-bootstrap';
const PhoneAuth = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationId, setVerificationId] = useState('');

  const setupRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth,
      'recaptcha-container',
      {
        'size': 'invisible',
        'callback': (response) => {
          onSignInSubmit();
        },
      }
    );
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    setupRecaptcha();
    const phoneNumberString = `+92${phoneNumber}`;
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumberString, appVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        console.log("SMS sent");
        toast.success("SMS Sent", toastOptions);
        window.confirmationResult = confirmationResult
      })
      .catch((error) => {
        console.error("Error during signInWithPhoneNumber:", error);
        toast.warning("Error during signInWithPhoneNumber", toastOptions);
      });
  };

  const onSubmitVerificationCode = (e) => {
    e.preventDefault();
    const credential = window.firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );

    auth.signInWithCredential(credential)
      .then((result) => {
        console.log("User signed in:", result.user);
      })
      .catch((error) => {
        console.error("Error during signInWithCredential:", error);
      });
  };

  return (
    <Container className="vh-100">
      <div className="row justify-content-center align-items-center vh-100 py-5">
        <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
          <div className="card card-body rounded-3 p-4">
            <h3>Phone Authentication</h3>
            <form onSubmit={onSignInSubmit} className="mb-3">
              <div id="recaptcha-container"></div>
              <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="phoneNumber"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                />
              </div>
              <div className='my-2'>
                <Button type="submit" size='sm' variant='primary'>Send Verification Code</Button>
              </div>
            </form>
            <form onSubmit={onSubmitVerificationCode} className="mb-3">
              <div className="form-group">
                <label htmlFor="verificationCode">Verification Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="verificationCode"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Enter verification code"
                />
              </div>
              <div className='my-2'>
                <Button type="submit" size='sm' variant='primary'>Verify Code</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PhoneAuth;