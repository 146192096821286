// UserProfileHeader.js
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { getDoc, doc, getDocs, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import { HandleUnfriend } from "./ActiveConnections";
import { HandleAcceptConnectionReq, HandleRejectConnectionReq } from "./ConnectionRequest";
import handlefollowUser from "../hooks/user/followUser";
import handleUnfollowUser from "../hooks/user/unfollowUser";
import SendFriendRequest from "./SendConnectionRequest";
import ProfilePicture from "./ProfilePicture";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import useModalStore from "../StatesStore/ModalStore";
import useNotification from "../hooks/useNotification";
import UpdatePFP from "./UpdatePFP";

const UserProfileHeader = () => {
  const { uid } = useParams(); // Extract the "uid" parameter from the URL
  const [userData, setUserData] = useState(null);
  const { user } = UserAuth();

  const [friendUid, setfriendUid] = useState(uid);
  const [activeList, setActiveList] = useState([]);
  const [pendinglist, setPendingList] = useState([]);
  const [requestlist, setRequestList] = useState([]);

  const [FRsent, setFRsent] = useState();
  const [isFollowed, setisFollowed] = useState(false)
  const { openModal } = useModalStore()
  const { addNotification, deleteNotification } = useNotification();

// State to track if profile picture is updated
const [profilePictureUpdated, setProfilePictureUpdated] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (uid) {
          const userDocRef = doc(db, "users", uid); // Fetch user data
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            setUserData(userDocSnapshot.data());
            
            // Fetch connections for the user
            const connectionsRef = collection(db, "users", uid, "connections");
            const connectionsSnapshot = await getDocs(connectionsRef);
            
            // Filter connections by type
            const activeConnections = [];
            const pendingRequests = [];
            const requestList = [];
            
            connectionsSnapshot.forEach((doc) => {
              const connectionData = doc.data();
              if (connectionData.type === "active") {
                activeConnections.push(doc.id);
              } else if (connectionData.type === "pending") {
                pendingRequests.push(doc.id);
              }else if(connectionData.type === "request"){
                requestList.push(doc.id)
              }
            });
            
            setActiveList(activeConnections);
            setPendingList(pendingRequests);
            setRequestList(requestList);
          } else {
            console.log("User document does not exist.");
          }
        } else {
          console.log("No uid provided in the URL.");
        }
  
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };
  
    fetchUserData();
  }, [user, uid, FRsent,profilePictureUpdated]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.uid) {
        console.error('User ID is undefined or null');
        return;
      }
      const checkBrandSnapshot = doc(db, "users", user?.uid, "following", friendUid)
      const checkBrandData = await getDoc(checkBrandSnapshot)
      if (checkBrandData.exists())
        setisFollowed(true)
      else
        setisFollowed(false)
    }
    fetchData();
  }, [userData, isFollowed])
  //to get screen widths adjusted in avatar
console.log("pendinglist",pendinglist);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || !timestamp.toDate) {
      return "N/A";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    return timestamp.toDate().toLocaleDateString("en-US", options);
  };

  const isCurrentUserProfile = user?.uid === uid;


  // console.log('Request list fetched in userprofile header',pendinglist);
  // console.log('loggedinUser document from userprofileheader',loggedinUserDoc);
  // console.log('UserData inside userprofileheader',userData);
  //a
  const handleClick =() => {
    const DATA = {
      "displayName": userData.userName.split(" ").slice(0, 2).map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" "),
      "FollowButton": setisFollowed,
      "UID": user?.uid,
      "friendUID": friendUid
    }
    openModal("ConfirmUnfollow", "user", DATA); // Open modal here
  };

  const handleProfilePictureUpdate = () => {
    // Toggle the state to trigger re-render
    setProfilePictureUpdated(!profilePictureUpdated);
  };


  return (
    <div className="card">
      <div
        className="h-200px rounded-top"
        style={{
          backgroundImage: `url(assets/images/bg/05.jpg)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="card-body py-0">
        <div className="d-sm-flex align-items-start text-center text-sm-start">
          <div>
            {/* <div className="avatar avatar-xxl mt-n5 mb-3">
              <ProfilePicture
                userData={userData}
                className="avatar-img rounded-circle border border-white border-3"
              />
            </div> */}
            <div className="avatar avatar-xxl mt-n5 mb-3">
  <UpdatePFP
    Uid = {user?.uid}
    userData={userData}
    avatarSize="xxl" // Pass the appropriate avatar size if needed
    className="avatar-img rounded-circle border border-white border-3"
    onProfilePictureUpdate={handleProfilePictureUpdate} // Pass the function to UpdatePFP
              />
</div>
          </div>
          <div className="ms-sm-4 mt-sm-3">
            <h1 className="mb-0 h5">
              {userData
                ? userData.userName
                  .split(" ")
                  .slice(0, 2)
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
                : ""}{" "}
              <i className="bi bi-patch-check-fill text-success small"></i>
            </h1>
            <p>
            {activeList && activeList.length > 0
              ? `${activeList.length} connection${activeList.length > 1 ? 's' : ''}`
              : "0 connections"}
          </p>
          </div>
          <div className="d-flex mt-3 justify-content-center ms-sm-auto">
            {!isCurrentUserProfile && (
              <button
              className="btn btn-danger me-2"
              type="button"
              onClick={async () => {
                if (activeList && activeList.includes(user?.uid)) {
                  HandleUnfriend(friendUid, user?.uid);
                  setFRsent(false);
                } else if (pendinglist && pendinglist.includes(uid)) {
                  HandleRejectConnectionReq(friendUid, user?.uid);
                  setFRsent(false);
                } else if (requestlist && requestlist.includes(user?.uid)) {
                  await HandleAcceptConnectionReq(user?.uid, friendUid);
                  setFRsent(true);
                  toast.success("Friend request accepted", toastOptions);
                } else {
                  SendFriendRequest(user?.uid, friendUid);
                  setFRsent(true);
                  await handlefollowUser(user?.uid, friendUid);
                  toast.success("Friend request has been sent", toastOptions);
                }
              }}
            >
              <i className="bi bi-person-plus"></i>
              {activeList && activeList.includes(user?.uid)
                ? " Unfriend"
                : pendinglist && pendinglist.includes(user?.uid)
                ? " Cancel Request"
                : requestlist && requestlist.includes(user?.uid)
                ? " Respond"
                : " Add Friend"}
            </button>
            
            )}
            {isCurrentUserProfile && (
              <Link className="btn btn-primary me-2" to="/settings">
                <i className="bi bi-pencil-fill me-1"></i>{" "}
                {/* Add margin-right here */}
                Edit Profile
              </Link>
            )}
            {!isCurrentUserProfile && (
              <Link
                className="btn btn-primary me-2"
                onClick={async () => {
                  if (isFollowed) {
                    // await handleUnfollowUser(myUid, friendUid);
                    // setisFollowed(false);
                    // toast.success("User unfollowed successfully", toastOptions)
                    handleClick();
                  
                  } else {
                    await handlefollowUser(user?.uid, friendUid);
                    setisFollowed(true);
                    toast.success("User followed successfully", toastOptions)
                    if (uid !== user?.uid) {
                      await addNotification({
                        userId: uid,
                        id: user?.uid,
                        timestamp: serverTimestamp(),
                        type: "followed",
                        by: "user",
                        seen:false
                      });
                    }
                  }
                }}
              >
                {isFollowed ? "Unfollow" : "Follow"}
              </Link>
            )}
          </div>
        </div>

        <ul className="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0">
          {userData && userData.userTitle && (
            <li className="list-inline-item">
              <i className="bi bi-briefcase me-1"></i> {userData.userTitle}
            </li>
          )}
          {userData && userData.location && (
            <li className="list-inline-item">
              <i className="bi bi-geo-alt me-1"></i> {userData.location}
            </li>
          )}
          <li className="list-inline-item">
            <i className="bi bi-calendar2-plus me-1"></i>{" "}
            {userData
              ? `Joined on ${formatTimestamp(userData.timestamp)}`
              : "Joined on N/A"}
          </li>
        </ul>
      </div>
      <div className="card-footer mt-3 pt-2 pb-0">
        <ul className="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/userPosts`}
              className="nav-link"
              activeClassName="active"
            >
              Posts
            </NavLink>
          </li>
          {(user?.uid === uid) &&
            <li className="nav-item">
              <NavLink to={`/userProfile/${uid}/savedposts`} className="nav-link" activeClassName="active">
                Saved Posts
              </NavLink>
            </li>
          }
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/userAbout`}
              className="nav-link"
              activeClassName="active"
            >
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/userConnections`}
              className="nav-link"
              activeClassName="active"
            >
              Connections
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/userfollowers`}
              className="nav-link"
              activeClassName="active"
            >
              Followers
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/userfollowing`}
              className="nav-link"
              activeClassName="active"
            >
              Following
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink
              to={`/userProfile/${uid}/invite`}
              className="nav-link"
              activeClassName="active"
            >
              Invite
            </NavLink>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default UserProfileHeader;