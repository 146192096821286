import Glightbox from "glightbox";
import { useEffect, useState } from "react";
import "./Glight.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  writeBatch,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";

import useActiveBrandsStore from "../StatesStore/ActiveBrands";
import { getDisplayName } from "../lib/getSupplierDisplayName";
import formatPrice from "../lib/formatPrice";
import formatTimestamp from "../lib/formatTimestamp";

const GlightImage = ({ post, image, imageIndex }) => {
  const [brandData, setBrandData] = useState(null);
  const [hasLoadedBrandData, setHasLoadedBrandData] = useState(false);

  const dynamicDescClass = `custom-desc${post?.id + imageIndex}`;

  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);
  const [product, setProduct] = useState({});

  useEffect(() => {
    fetchBrandData();
    fetchComments();
  }, []);
  useEffect(() => {
    if (image && image?.id) {
      fetchproduct();
    }
  }, [image?.id]);

  const fetchproduct = async () => {
    const productRef = doc(db, "products", image?.id);

    try {
      const productSnapshot = await getDoc(productRef);

      if (productSnapshot.exists()) {
        setProduct(productSnapshot.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchComments = async () => {
    const collectionPath = post.userId ? "posts" : "brandPost";
    const commentsCollectionRef = collection(
      db,
      collectionPath,
      post.id,
      "comments"
    );

    try {
      // Retrieve the comments once
      const querySnapshot = await getDocs(
        query(commentsCollectionRef, orderBy("timestamp", "desc"))
      );

      const newComments = querySnapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          userName: "", // Initialize userName to empty string
          photo: "",
        };
      });

      // Fetch user data for each comment
      const populatedComments = await Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);
          if (userSnapshot.exists) {
            comment.userName = userSnapshot.data().userName; // Update userName
            comment.photo = userSnapshot.data().photo;
          } else {
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }
          return comment;
        })
      );

      // Update the comments state with the retrieved comments
      setComments(populatedComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchBrandData = async () => {
    if (!image?.supplier) return;
    const brandRef = doc(db, "brands", image?.supplier);

    try {
      const brandSnapshot = await getDoc(brandRef);

      if (brandSnapshot.exists()) {
        setBrandData(brandSnapshot.data());
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
    setHasLoadedBrandData(true);
  };

  //glight box
  useEffect(() => {
    if (brandData && product && image?.id && comments) {
      const lightbox = Glightbox({
        selector: `[data-morepostglightbox="${post?.id}"]`,
      });

      const batch = writeBatch(db);
      const incrementViews = {
        views: product?.views ? product?.views + 1 : 1,
      };
      batch.update(doc(db, "products", image?.id), incrementViews);
      batch.commit();

      return () => {
        lightbox.destroy();
      };
    }
  }, [post?.id, brandData, imageIndex, comments]);

  return (
    <div>
      <Link
        to={image?.imageUrl ? image.imageUrl : ""}
        data-gallery="image-popup"
        className="glightbox"
        data-morepostglightbox={post?.id}
        data-glightbox={`description: .${dynamicDescClass}; descPosition: left;`}
      >
        <div className="card">
          <img
            className="card-img-top img-fluid rounded-top"
            style={{ height: "80%" }}
            src={image?.imageUrl}
            alt=""
          />
          {product?.discount > 0 && (
            <div className="badge bg-danger text-white mt-2 me-2 position-absolute top-0 end-0">
              {product?.discount}% off
            </div>
          )}
          <div className="position-absolute bottom-0 start-0 p-3 d-flex w-100">
            <span
              className="badge px-2 rounded text-white small"
              style={{ backgroundColor: "#007bff" }}
            >
              {getDisplayName(image?.supplier, activeBrands)}
            </span>
          </div>
        </div>
      </Link>
      <div className={`glightbox-desc ${dynamicDescClass}`}>
        {/**Top Bar in Sidebar */}
        <div className="d-flex align-items-center justify-content-between">
          {/**Post Owner */}
          <div className="d-flex align-items-center">
            {/**Post owner Image */}
            <div className="avatar me-2">
              <Link to={"/BrandHome/" + image?.supplier}>
                <img
                  className="avatar-img rounded-circle"
                  src={`assets/images/brands/${brandData?.logo}`}
                  alt="Brand profile"
                />
              </Link>
            </div>
            {/**Post owner details */}
            <div>
              <div className="nav nav-divider">
                {/**Post owner Name */}
                <h6 className="nav-item card-title mb-0">
                  {getDisplayName(image?.supplier, activeBrands)}
                </h6>
              </div>
              {/**Any tagline / status line if there is in bio of post owner  */}
              <p className="mb-0 small">
                <span className="nav-item small">
                  {formatTimestamp(post?.dateCreated)}
                </span>
              </p>
            </div>
          </div>
        </div>
        {/**Image name */}
        <h6 className="mt-3 text-wrap "> {image?.name} </h6>
        {/**Like comment Share options */}
        <div className="pt-1">
          {/* Price Row */}
          {product?.discount ? (
            <ul className="nav nav-stack small mt-1">
              {/*If sdiscount  */}
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                Rs. {formatPrice(product.newPrice)}Pkr
              </li>
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                <strike
                  style={{
                    textDecoration: "line-through",
                    textDecorationColor: "red",
                  }}
                >
                  Rs. {formatPrice(product?.oldPrice)}Pkr
                </strike>
              </li>
              <li
                className="nav-item badge bg-danger text-white ms-auto"
                style={{ fontSize: "0.85rem" }}
              >
                {product?.discount}% off
              </li>
            </ul>
          ) : (
            <ul
              className="nav nav-stack small mt-1"
              style={{ fontSize: "0.85rem" }}
            >
              {/*If no discount  */}
              <li className="nav-item">
                Price: Rs. {formatPrice(product?.newPrice)}
              </li>
            </ul>
          )}
          {/* Category and subcategory */}
          <ul className="nav nav-stack small mx-auto mt-1">
            {/*If sdiscount  */}
            {product?.category ? (
              <li className="nav-item" style={{ fontSize: "0.85rem" }}>
                Category: {product?.category}
                <span style={{ fontSize: "0.80rem" }}>
                  {" "}
                  {product?.subCategory},
                  <span style={{ fontSize: "0.75rem" }}>
                    {" "}
                    {product?.subSubCategory}
                  </span>{" "}
                </span>
              </li>
            ) : null}
          </ul>
          {/* Category and subcategory */}
          <ul className="nav nav-stack small mt-1">
            <li className="nav-item d-flex flex-grow-1">
              <Link
                to={`/ProductDetails/${image?.id}`}
                className="dropdown-item btn btn-primary-soft btn-sm my-0 text-center py-2"
              >
                Visit
              </Link>
            </li>
          </ul>
          {/**Like comment Share options */}
          <ul className="nav nav-stack mt-1 small">
            {/**Like Button */}
            <li className="nav-item">
              <i className="bi bi-hand-thumbs-up-fill pe-1"></i>(
              {product?.likes ?? 0})
            </li>
            {/**Comment button  */}
            <li className="nav-item">
              <i className="bi bi-eye-fill pe-1"></i>({product?.views ?? 0})
            </li>
            <li className="nav-item">
              <i className="bi bi-star-fill pe-1"></i>(
              {product?.favourites ?? 0})
            </li>
          </ul>
        </div>

        {/**Comments on Post */}
        <ul className="comment-wrap list-unstyled ">
          <div className="d-flex">
            {/**Commentator details */}
            <div className="ms-2">
              {/* <div className="bg-light rounded-start-top-0 p-3 rounded"> */}
              <div className="d-flex justify-content-center">
                <div className="me-2">
                  {/**Commentator name */}
                  {comments?.map((comment, index) => (
                    <li key={index} className="comment-item mt-3">
                      <div className="d-flex">
                        {/* Avatar */}
                        <div className="avatar avatar-xs">
                          {comment.photo &&
                          (comment.photo.startsWith("https") ||
                            comment.photo.startsWith("www")) ? (
                            <img
                              className="avatar-img rounded-circle"
                              src={comment.photo}
                              alt=""
                            />
                          ) : (
                            <img
                              className="avatar-img rounded-circle"
                              src={`https://ui-avatars.com/api/?name=${comment.userName[0]}`}
                              alt=""
                            />
                          )}
                        </div>
                        {/* Comment by */}
                        <div className="ms-3" style={{ width: "100%" }}>
                          <div className="bg-light rounded-start-top-0 p-2 rounded flex-grow">
                            <div className="d-flex justify-content-between align-item-center">
                              <h6 className="mb-1">
                                {" "}
                                <Link
                                  to={
                                    comment
                                      ? `/userProfile/${comment.userId}`
                                      : "/userProfile"
                                  }
                                >
                                  {" "}
                                  {comment?.userName}{" "}
                                </Link>{" "}
                              </h6>
                              <small className="ms-2">
                                {formatTimestamp(comment?.timestamp)}
                              </small>
                            </div>
                            <p className="small mb-0 text-break">
                              {comment?.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default GlightImage;
