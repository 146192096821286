import  {  useEffect } from 'react';
import axios from 'axios';
import useUserCountry from '../StatesStore/UserCountry';

function App() {

  const setUserCountry = useUserCountry((state) => state.setUserCountry);
  
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        const locationIP = await getUserLocation(response.data.ip);
        setUserCountry(locationIP?.country)
        // console.log('IP fetched : ',locationIP)
      } catch (error) {
        console.error('Error fetching IP or location:', error);
      }
    };

    fetchIP();
  }, []);

  const getUserLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching location data:", error);
      return null;
    }
  };

}

export default App;