import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { useState } from "react";

export default function UnderAgeWarning({user}) {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const checkWarningTime = async () => {
      const userDocRef = doc(db, "users", user?.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        console.log("No such document!");
        return;
      }

      const userData = userDocSnap.data();
      const warningTime = userData?.warningTime;

      if (!warningTime) {
        setShowWarning(true);
        await setDoc(
          userDocRef,
          { warningTime: serverTimestamp() },
          { merge: true }
        );
        return;
      }

      const currentTime = new Date().getTime();
      const warningTimeMillis = warningTime.toDate().getTime();

      if ((currentTime - warningTimeMillis) >= 24 * 60 * 60 * 1000) {
        setShowWarning(true);
        await setDoc(
          userDocRef,
          { warningTime: serverTimestamp() },
          { merge: true }
        );
      } else {
        setShowWarning(false);
      }
    };

    checkWarningTime();
  }, [user?.uid]);

  if (!showWarning) {
    return null;
  }
  return (
    <>
      <div
        class="alert alert-danger alert-dismissible fade show mb-0"
        role="alert"
      >
        <strong>Parental Consent:</strong> You need to obtain parental consent to use this website. <Link
          to="/"
          className=""
        >
          Click here
        </Link> to update parental consent.
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </>
  );
}