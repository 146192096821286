import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShareMenu from "../components/ShareMenu";
import ShareMenu2 from "../components/ShareMenu2";
import { db } from "../FirebaseConfig";
import { Card } from "react-bootstrap";
import "./style.css";
import {
  getDoc,
  doc,
  updateDoc,
  writeBatch,
  arrayUnion,
  onSnapshot,
  getDocs,
  collection,
  addDoc,
  where,
  query,
} from "firebase/firestore";
import { UserAuth } from "../Context";
import AddOrRemoveFavourites from "./AddOrRemoveFavourites";
import ProductReaction from "./ProductReaction";
import formatPrice from "../lib/formatPrice";
import { useCollection } from "react-firebase-hooks/firestore";
import { getDisplayName } from "../lib/getSupplierDisplayName";
import useActiveBrandsStore from "../StatesStore/ActiveBrands";

const ProductCard2 = ({ product }) => {
  const [likeCount, setLikeCount] = useState(product?.likes);
  const { user } = UserAuth();
  const [favoriteProduct, setFavoriteProduct] = useState([]);
  if (user)
    var shoppingListRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      "Favorites"
    );
  const [snapshot, loading, error] = useCollection(shoppingListRef);
  useEffect(() => {
    if (!user) return;
    if (snapshot) {
      // Retrieve favorite products
      const shoppingListData = snapshot.data()?.products || [];
      setFavoriteProduct(shoppingListData);
    }
  }, [snapshot]);
  const activeBrands = useActiveBrandsStore((state) => state.activeBrands);

  return (
    <Card className="shadow-lg sm-mx-1">
      <div className="z-1">
        <button
          style={{ top: "1%", right: "3%" }}
          className="position-absolute border border-0 mt-1 rounded-circle bg-mode "
        >
          <ShareMenu2 product={product} />
        </button>

        <button
          style={{ top: "1%", right: "3%" }}
          className="position-absolute border border-0 my-5 rounded-circle bg-mode"
        >
          <AddOrRemoveFavourites product={product} user={user} />
        </button>
      </div>

      <Link to={`/ProductDetails/${product?.id}`}>
        <div className="position-relative">
          <Card.Img
            variant="top"
            src={product.imageUrl}
            style={{
              // height: `${randomNumberInRange(280, 200)}px`,
              objectFit: "cover",
            }}
          />
          <div className="position-absolute bottom-0 start-0 p-3 d-flex w-100">
            <span className="badge bg-primary px-2 rounded text-white small">
              {getDisplayName(product?.supplier, activeBrands)}
            </span>
          </div>
        </div>
        <Card.Body className="px-3 py-1 ">
          <div className="d-flex align-items-center ">
            {/* <BrandDisplay brandData={brandData} post={post} /> */}
            <ul className="nav nav-stack py-1 small">
              <li className="nav-item">
                <Link className="nav-link">
                  {" "}
                  <i className="bi bi-hand-thumbs-up-fill pe-1"></i>({likeCount}
                  )
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="">
                  {" "}
                  <i className="bi bi-eye-fill pe-1"></i>({product?.views ?? 0})
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="">
                  {" "}
                  <i className="bi bi-star-fill pe-1"></i>(
                  {product?.favourites ?? 0})
                </Link>
              </li>
            </ul>
          </div>
          <Card.Title
            className="fw-light"
            style={{
              marginBottom: "5px",
              marginTop: "5px",
              fontSize: "10px",
            }}
          >
            {product.name}
          </Card.Title>
          <div className="d-flex align-items-center justify-content-between text-body ">
            <div className="d-flex align-items-center gap-1">
              {product?.newPrice && (
                <p className="fs-5 m-0 fw-bold text-success">
                  PKR&nbsp;
                  {product?.newPrice
                    ? formatPrice(product.newPrice)
                    : formatPrice(product.oldPrice)}
                </p>
              )}
              {product.oldPrice && product.newPrice ? (
                <p className="fs-6 m-0 text-decoration-line-through ">
                  PKR&nbsp;{formatPrice(product.oldPrice)}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </Card.Body>
      </Link>
      <div className="card-footer  p-0">
        <ProductReaction product={product} setLikeCount={setLikeCount} />
      </div>
    </Card>
  );
};

export default ProductCard2;
