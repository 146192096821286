
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";

export const getBrandDetails = async (supplierName, activeBrands) => {
    // Check if supplierName is an array and extract the string if necessary
    const supplierNameString = Array.isArray(supplierName) ? supplierName[0] : supplierName;

    // Find the brand in activeBrands
    const brand = activeBrands.find(brand => brand.name === supplierNameString);

    if (brand) {
        console.log("return from saved");
        // If the brand is found in activeBrands, return its displayName and logo
        return { displayName: brand.displayName, logo: brand.logo,name:brand.name };
    } else {
        // If the brand is not found, fetch it from the 'brands' collection
        const brandDoc = await getDoc(doc(db, "brands", supplierNameString));
        
        if (brandDoc.exists()) {
            const brandData = brandDoc.data();
            return { displayName: brandData.displayName, logo: brandData.logo,name:brandData.name };
        } else {
            // If the document doesn't exist, return the supplierNameString as the displayName
            return { displayName: supplierNameString, logo: null,name:supplierNameString };
        }
    }
};
