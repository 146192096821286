import React, { useState, useEffect } from "react";
import WebFont from "webfontloader";
import { auth } from "../FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { useForm, Controller } from "react-hook-form";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import TermsAndConditions from "./TermsAndConditions";
import useOobCodeState from "../StatesStore/OobCode";
import useApiKeyState from "../StatesStore/ApiKey";
function ForgotPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const oobCode = useOobCodeState((state) => state.oobCode);
  const apiKey = useApiKeyState((state) => state.apikey);

  const { control, handleSubmit, setError, formState } = useForm({
    defaultValues: {
      pass: "",
      confirmPass: "",
    },
  });
  const { errors } = formState;

  const handleSubmission = async (data) => {
    const { pass, confirmPass } = data;
    setSubmitButtonDisabled(true);

    const errors = {};
    if (!pass) {
      errors.pass = "Password is required";
    }
    if (!confirmPass) {
      errors.confirmPass = "Confirm Password is required";
    }
    if (pass.length < 6) {
      errors.pass = "Password must be at least 6 characters";
    }

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        setError(field, {
          type: "manual",
          message,
        });
      });
      return;
    }

    if (pass !== confirmPass) {
      setError("confirmPass", {
        type: "manual",
        message: "Password and Confirm Password do not match",
      });
      return;
    }

    // Handle the password reset logic here...
    console.log("All done");
    try {
      let res = await verifyPasswordResetCode(auth, oobCode);
      console.log("res", res);
      await confirmPasswordReset(auth, oobCode, pass);
      toast.success(
        "Password has been reset successfully. You can now log in with your new password.",
        toastOptions
      );
      setError("");
      navigate("/SignIn");
    } catch (error) {
      setError(error.message);
      toast.error("Error! Password is already updated", toastOptions);
      navigate("/");
    }
  };
  useEffect(() => {
    if (!oobCode || !apiKey) {
      navigate("/SignIn");
    }
    // async function verifyoobCode() {
    //     let response = await verifyPasswordResetCode(auth, oobCode);
    //     console.log("Response", response)
    // }
    // verifyoobCode();
    // eslint-disable-next-line
  }, [oobCode, apiKey]);
  useEffect(() => {
    if (submitButtonDisabled) {
      console.log("button disables");
    }
  }, [submitButtonDisabled]);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });
  }, []);

  function isVariableDefined(el) {
    return typeof el !== "undefined" && el !== null;
  }

  useEffect(() => {
    if (isVariableDefined(document.querySelector("#pswmeter"))) {
      passwordStrengthMeter({
        containerElement: "#pswmeter",
        passwordInput: "#new-psw-input",
        showMessage: true,
        messageContainer: "#pswmeter-message",
        messagesList: [
          "Write your password...",
          "Easy peasy!",
          "That is a simple one",
          "That is better",
          "Yeah! that password rocks ;)",
        ],
        height: 8,
        borderRadius: 4,
        pswMinLength: 8,
        colorScore1: "#dc3545",
        colorScore2: "#f7c32e",
        colorScore3: "#4f9ef8",
        colorScore4: "#0cbc87",
      });
    }
  }, []);

  function passwordStrengthMeter(opts) {
    const customStyles = document.createElement("style");
    document.body.prepend(customStyles);
    customStyles.innerHTML = `
            ${opts.containerElement} {
                height: ${opts.height || 4}px;
                background-color: #eee;
                position: relative;
                overflow: hidden;
                border-radius: ${opts.borderRadius.toString() || 2}px;
            }
            ${opts.containerElement} .password-strength-meter-score {
                height: inherit;
                width: 0%;
                transition: .3s ease-in-out;
                background: ${opts.colorScore1 || "#ff7700"};
            }
            ${
              opts.containerElement
            } .password-strength-meter-score.psms-25 {width: 25%; background: ${opts.colorScore1 ||
      "#ff7700"};}
            ${
              opts.containerElement
            } .password-strength-meter-score.psms-50 {width: 50%; background: ${opts.colorScore2 ||
      "#ffff00"};}
            ${
              opts.containerElement
            } .password-strength-meter-score.psms-75 {width: 75%; background: ${opts.colorScore3 ||
      "#aeff00"};}
            ${
              opts.containerElement
            } .password-strength-meter-score.psms-100 {width: 100%; background: ${opts.colorScore4 ||
      "#00ff00"};}
        `;

    const containerElement = document.getElementById(
      opts.containerElement.slice(1)
    );
    containerElement.classList.add("password-strength-meter");

    let scoreBar = document.createElement("div");
    scoreBar.classList.add("password-strength-meter-score");
    containerElement.appendChild(scoreBar);

    const passwordInput = document.getElementById(opts.passwordInput.slice(1));
    let passwordInputValue = "";
    if (passwordInput) {
      passwordInput.addEventListener("keyup", function() {
        passwordInputValue = this.value;
        checkPassword();
      });
    }

    let pswMinLength = opts.pswMinLength || 8;

    let scoreMessage = opts.showMessage
      ? document.getElementById(opts.messageContainer.slice(1))
      : null;
    let messagesList =
      opts.messagesList === undefined
        ? ["No data", "Too simple", "Simple", "That's OK", "Great password!"]
        : opts.messagesList;
    if (scoreMessage) {
      scoreMessage.textContent = messagesList[0] || "No data";
    }

    function checkPassword() {
      let score = getScore();
      updateScore(score);
    }

    function getScore() {
      let score = 0;
      let regexLower = new RegExp("(?=.*[a-z])");
      let regexUpper = new RegExp("(?=.*[A-Z])");
      let regexDigits = new RegExp("(?=.*[0-9])");
      let regexLength = new RegExp("(?=.{" + pswMinLength + ",})");

      if (passwordInputValue.match(regexLower)) {
        ++score;
      }
      if (passwordInputValue.match(regexUpper)) {
        ++score;
      }
      if (passwordInputValue.match(regexDigits)) {
        ++score;
      }
      if (passwordInputValue.match(regexLength)) {
        ++score;
      }
      if (score === 0 && passwordInputValue.length > 0) {
        ++score;
      }

      return score;
    }

    function updateScore(score) {
      switch (score) {
        case 1:
          scoreBar.className = "password-strength-meter-score psms-25";
          if (scoreMessage) {
            scoreMessage.textContent = messagesList[1] || "Too simple";
          }
          containerElement.dispatchEvent(
            new Event("onScore1", { bubbles: true })
          );
          break;
        case 2:
          scoreBar.className = "password-strength-meter-score psms-50";
          if (scoreMessage) {
            scoreMessage.textContent = messagesList[2] || "Simple";
          }
          containerElement.dispatchEvent(
            new Event("onScore2", { bubbles: true })
          );
          break;
        case 3:
          scoreBar.className = "password-strength-meter-score psms-75";
          if (scoreMessage) {
            scoreMessage.textContent = messagesList[3] || "That's OK";
          }
          containerElement.dispatchEvent(
            new Event("onScore3", { bubbles: true })
          );
          break;
        case 4:
          scoreBar.className = "password-strength-meter-score psms-100";
          if (scoreMessage) {
            scoreMessage.textContent = messagesList[4] || "Great password!";
          }
          containerElement.dispatchEvent(
            new Event("onScore4", { bubbles: true })
          );
          break;
        default:
          scoreBar.className = "password-strength-meter-score";
          if (scoreMessage) {
            scoreMessage.textContent = messagesList[0] || "No data";
          }
          containerElement.dispatchEvent(
            new Event("onScore0", { bubbles: true })
          );
      }
    }

    return {
      containerElement,
      getScore,
    };
  }

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100 py-5">
        <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
          <div className="card card-body rounded-3 text-center p-4 p-sm-5">
            <div className="text-center">
              <div className="text-body text-center fs-icon " to="/">
                <div
                  style={{
                    fontFamily: "Nunito Sans",
                  }}
                >
                  <i>lookflock&nbsp;</i>
                </div>
              </div>
            </div>
            <p className="mt-3 fs-4 text-body">Forget Password?</p>
            {/* <h5 className="mb-2">Forgot password?</h5> */}
            <p>Enter the email address associated with your account.</p>
            <form className="my-3" onSubmit={handleSubmit(handleSubmission)}>
              <div className="mb-3 input-group-lg">
                <div className="input-group ">
                  <Controller
                    name="pass"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          className="form-control fakepassword"
                          type={showPassword ? "text" : "password"}
                          id="new-psw-input"
                          placeholder="Enter new password"
                          autoComplete="current-password"
                          {...field}
                        />
                        <span
                          className="input-group-text p-0"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={`fakepasswordicon fa-solid fa-eye${
                              showPassword ? "-slash" : ""
                            } cursor-pointer p-2 w-40px`}
                          ></i>
                        </span>
                      </>
                    )}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "5px", marginTop: "5px" }}
                >
                  {errors.pass?.message}
                </span>
                <span
                  style={{ color: "red", marginLeft: "5px", marginTop: "5px" }}
                >
                  {errors.passwordLength?.message}
                </span>
                <div id="pswmeter" className="mt-2"></div>
                <div className="d-flex mt-1">
                  <div id="pswmeter-message" className="rounded"></div>
                </div>
              </div>
              <div className="mb-0 position-relative">
                <div className="input-group">
                  <Controller
                    name="confirmPass"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          className="form-control fakepassword"
                          type={showPassword ? "text" : "password"}
                          id="confirm-psw-input"
                          placeholder="Confirm your password"
                          autoComplete="current-password"
                          {...field}
                        />
                        <span
                          className="input-group-text p-0"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={`fakepasswordicon fa-solid fa-eye${
                              showPassword ? "-slash" : ""
                            } cursor-pointer p-2 w-40px`}
                          ></i>
                        </span>
                      </>
                    )}
                  />
                </div>
                <span
                  style={{ color: "red", marginLeft: "5px", marginTop: "5px" }}
                >
                  {errors.confirmPass?.message}
                </span>
              </div>
              <div className="d-grid">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!formState.isValid || !oobCode || !apiKey}
                >
                  Reset Password
                </button>
              </div>
            </form>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
