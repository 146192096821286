import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";
// import Brands from "./Brands";
import BrandHome from "./Brands/BrandHome";
import { InterestContext } from "./Context"; // Import UserAuth and InterestContext from Context.js
import AllProducts from "./AllProducts";
import ShoppingLists from "./ShoppingLists";
import SharedShoppingLists from "./components/SharedShoppingLists";
//Dynamic Routing For search Results :
import SearchResults from "./SearchResults/SearchResults";

import ProductDetails from "./ProductDetails";
import CollumnPage from "./Experiments/CollumnPage";
import { useLocation } from "react-router-dom";
// import SearchUsers from "./Header/SearchUsers";
import TestMasonry from "./Experiments/TestMasonry/TestMasonry";
import TestMasonryOld from "./Experiments/oldTestMasonry/TestMasonry";
import InvitedList from "./ShoppingList/InvitedList";
import NewsFeed5 from "./NewsFeed/NewsFeed5";
import AllNotifications from "./Notifications/AllNotifications";
import Users from "./SearchResults/Users";
import Products from "./SearchResults/Products";

function Main() {
  const location = useLocation(); //used to fetch pathname
  const { interestedProducts, setInterestedProducts } = useContext(
    InterestContext
  );
  return (
    <>
      {/*Disabling Side bar when User is in userInfo Route*/}
      {/* temporarily disabled side nav */}
      {/* {location.pathname === "/" ? "" :<SideNavUserProfile />*/}
      <InterestContext.Provider
        value={{ interestedProducts, setInterestedProducts }}
      >
        <Routes>
          <Route path="/testMasonry" element={<TestMasonry />} />
          <Route path="/testMasonryOld" element={<TestMasonryOld />} />
          {/* <Route path="/NewsFeed" element={<NewsFeed4 />} /> */}
          <Route path="/NewsFeed" element={<NewsFeed5 />} />
          <Route path="/InvitedList" element={<InvitedList />} />
          <Route path="/" element={<Home />} />
          <Route path="/ProductDetails/:id" element={<ProductDetails />} />

          {/* <Route path="/UserInfo" element={<MoreQuestions />} /> */}
          <Route path="/test" element={<CollumnPage />} />
          <Route path="/notifications" element={<AllNotifications />} />
          <Route
            path="/AllProducts/:category/:subCategory/:subSubCategory"
            element={<AllProducts />}
          />
          <Route path="/BrandHome/:brandName/" element={<BrandHome />} />
          <Route
            path="/BrandHome/:brandName/:category"
            element={<BrandHome />}
          />
          <Route
            path="/BrandHome/:brandName/:category/:subCategory/"
            element={<BrandHome />}
          />
          <Route
            path="/BrandHome/:brandName/:category/:subCategory/:subSubCategory"
            element={<BrandHome />}
          />

          {/* <Route path="/*" element={<Brands />} /> */}
          <Route
            path="/favorites"
            element={<ShoppingLists isFavorites={true} />}
          />
          <Route path="/shoppingLists/*" element={<ShoppingLists />} />
          <Route
            path="/shoppingList/:listId/:userId"
            element={<SharedShoppingLists />}
          />
          <Route path="/search/:input" element={<SearchResults />} />
          <Route path="/search/:input/users" element={<Users />} />
          <Route path="/search/:input/products" element={<Products />} />
          {/* <Route path="/searchuser/:input" element={<SearchUsers />} /> */}
        </Routes>
      </InterestContext.Provider>
    </>
  );
}

export default Main;
