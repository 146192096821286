import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import BrandsMenu from "./BrandsMenuDropdown";

function HeaderMenu() {
  const [womenCategories, setWomenCategories] = useState([]);
  const [menCategories, setMenCategories] = useState([]);

  useEffect(() => {
    const getMenu = async () => {
      const data = collection(db, "universalCategories");
      const querySnapshot = await getDocs(data);
      const categoriesData = querySnapshot.docs.map(
        (doc) => doc.data().subCategories
      );
      setWomenCategories(categoriesData[1]);
      setMenCategories(categoriesData[0]);
    };

    getMenu();
  }, []);

  const renderDropdownItems = (categories, gender) => {
    return Object.keys(categories)
      ?.sort()
      ?.map((categoryName) => (
        <li className="dropdown-submenu dropstart " key={categoryName}>
          <button
            className="dropdown-item dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {categoryName}
          </button>
          <ul className="dropdown-menu dropdown-menu-end" data-bs-popper="none">
            {categories[categoryName]
              .filter(
                (subCategory) =>
                  subCategory.name !== "None" &&
                  subCategory.displayName !== "None"
              )
              .map((subCategory, index) => (
                <li key={index}>
                  <Link
                    className="dropdown-item"
                    to={`/AllProducts/${gender}/${categoryName}/${subCategory.name}`}
                  >
                    {subCategory.displayName}
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      ));
  };

  return (
    <ul
      className="navbar-nav navbar-nav-scroll ms-auto"
      style={{ paddingRight: "2%" }}
    >
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Brands
        </Link>
        <ul className="dropdown-menu">
          <BrandsMenu />
        </ul>
      </li>

      {/* Women's Categories Dropdown */}
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Women
        </Link>
        <ul className="dropdown-menu">
          {renderDropdownItems(womenCategories, "Women")}
        </ul>
      </li>

      {/* Men's Categories Dropdown */}
      <li className="nav-item dropdown">
        <Link
          className="nav-link dropdown-toggle"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Men
        </Link>
        <ul className="dropdown-menu">
          {renderDropdownItems(menCategories, "Men")}
        </ul>
      </li>
      {/* <div className="d-sm-none d-flex justify-content-around nav-link mx-3">
        <li>Terms of Services</li>
        <div class="vr"></div>
        <li>Contact Us</li>
        <div class="vr"></div>
        <li>Privacy Policy</li>
      </div> */}
    </ul>
  );
}

export default HeaderMenu;
