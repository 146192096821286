import React, { useState } from 'react';

const ImageCarousel = ({ product }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const secondaryImages = product.secondaryImages || [];

  const handleImageClick = () => {
    window.open(`#/ProductDetails/${product?.id}`, '_blank');
  };

  const handleBubbleClick = (e, index) => {
    e.stopPropagation();
    setCurrentImage(index);
  };

  return (
    <div className="image-carousel" style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
      <img
        className=" d-block w-100"
        src={secondaryImages.length > 0 ? secondaryImages[currentImage] : product.imageUrl}
        alt={`Product Image ${currentImage + 1}`}
        style={{ objectFit: 'cover', height: '350px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
        onClick={handleImageClick}
      />
      {secondaryImages.length > 0 && (
        <div className="navigation">
          {secondaryImages.map((image, index) => (
            <div
              key={index}
              className={`bubble-outer ${index === currentImage ? 'active' : ''}`}
              onClick={(e) => handleBubbleClick(e, index)}
            >
              <div className="bubble-inner"></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageCarousel;
