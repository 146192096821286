import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import AccountSetting from "./AccountSetting";
// import PrivacySetting from './PrivacySetting';
import StaticLinks from "../components/StaticLinks";
import { UserAuth } from "../Context";

const UserSettings = () => {
  const [activeTab, setActiveTab] = useState("account");
  const { user } = UserAuth();
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="d-flex align-items-center mb-4 d-lg-none">
              <button
                className="border-0 bg-transparent"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <span className="btn btn-primary">
                  <i className="fa-solid fa-sliders-h"></i>
                </span>
                <span className="h6 mb-0 fw-bold d-lg-none ms-2">Settings</span>
              </button>
            </div>
            <nav className="navbar navbar-light navbar-expand-lg mx-0">
              <div
                className="offcanvas offcanvas-start"
                tabIndex="-1"
                id="offcanvasNavbar"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close text-reset ms-auto"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body p-0">
                  <div className="card w-100">
                    <div className="card-body">
                      <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                        <li
                          className={`nav-item ${
                            activeTab === "account" ? "active" : ""
                          }`}
                        >
                          <Link
                            className="nav-link d-flex mb-0"
                            to="#nav-setting-tab-1"
                            data-bs-toggle="tab"
                            onClick={() => handleTabClick("account")}
                          >
                            <img
                              className="me-2 h-20px fa-fw"
                              src="assets/images/icon/person-outline-filled.svg"
                              alt=""
                            />
                            <span>Account</span>
                          </Link>
                        </li>
                        {/* <li className={`nav-item ${activeTab === 'privacy' ? 'active' : ''}`}>
                                                    <Link className="nav-link d-flex mb-0" to="#nav-setting-tab-2" data-bs-toggle="tab" onClick={() => handleTabClick('privacy')}>
                                                        <img className="me-2 h-20px fa-fw" src="assets/images/icon/shield-outline-filled.svg" alt="" />
                                                        <span>Privacy and safety</span>
                                                    </Link>
                                                </li> */}
                      </ul>
                    </div>
                    <div className="card-footer text-center py-2">
                      <Link
                        className="btn btn-link text-secondary btn-sm"
                        to={`/userProfile/${user?.uid}`}
                      >
                        View Profile
                      </Link>
                    </div>
                  </div>
                </div>
                <StaticLinks />
              </div>
            </nav>
          </div>

          <div className="col-lg-6 vstack gap-4">
            <div className="tab-content p-0 mb-0">
              <div
                className={`tab-pane show active fade ${
                  activeTab === "account" ? "" : "d-none"
                }`}
                id="nav-setting-tab-1"
              >
                <AccountSetting />
              </div>
              {/* <div className={`tab-pane fade ${activeTab === 'privacy' ? 'show active' : 'd-none'}`} id="nav-setting-tab-2">
                                <PrivacySetting />
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserSettings;
