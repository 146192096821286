// src/hooks/useFriendActions.js
import { useState, useEffect } from "react";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { useFetchUserData } from "./useFetchUserData";
import { HandleUnfriend } from "../../UserProfile/ActiveConnections";
import {
  HandleAcceptConnectionReq,
  HandleRejectConnectionReq,
} from "../../UserProfile/ConnectionRequest";
import SendFriendRequest from "../../UserProfile/SendConnectionRequest";
import handlefollowUser from "./followUser";
import { toast } from "react-toastify";
import { toastOptions } from "../../lib/toastOptions";
import { db } from "../../FirebaseConfig";

export function useFriendActions(searchUser, user) {
  const [requestList, setRequestList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [FRsent, setFRsent] = useState();

  const { userData } = useFetchUserData(user?.uid);

  useEffect(() => {
    if (userData) {
      // Fetch user's connections from the subcollection
      const fetchConnections = async () => {
        try {
          const connectionsRef = collection(
            db,
            "users",
            user?.uid,
            "connections"
          );
          const connectionsSnapshot = await getDocs(connectionsRef);

          const activeConnections = [];
          const requestConnections = [];
          const pendingConnections = [];

          connectionsSnapshot.forEach((doc) => {
            const connectionData = doc.data();
            if (connectionData.type === "active") {
              activeConnections.push(doc.id);
            } else if (connectionData.type === "request") {
              requestConnections.push(doc.id);
            } else if (connectionData.type === "pending") {
              pendingConnections.push(doc.id);
            }
          });

          setActiveList(activeConnections);
          setRequestList(requestConnections);
          setPendingList(pendingConnections);
        } catch (error) {
          console.error("Error fetching connections:", error.message);
        }
      };

      fetchConnections();
    }
  }, [userData, user?.uid]);
  console.log("activeList from useFriendAction ", activeList);
  console.log("pendingList from useFriendAction ", pendingList);
  console.log("requestList from useFriendAction ", requestList);
  console.log("searchUser from useFriendAction ", searchUser);

  const handleFriendAction = async (friendUid, user) => {
    try {
      console.log("useFriendAction notification", friendUid);
      console.log("user useFriendAction notification", user);

      if (activeList.includes(friendUid)) {
        await HandleUnfriend(user.uid, friendUid);
        setFRsent(false);
      } else if (requestList.includes(friendUid)) {
        await HandleRejectConnectionReq(user.uid, friendUid);
        setFRsent(false);
      } else if (pendingList && pendingList.includes(friendUid)) {
        await HandleAcceptConnectionReq(user?.uid, friendUid);
        setFRsent(true);
        toast.success("Friend request accepted", toastOptions);
      } else {
        await SendFriendRequest(user.uid, friendUid);
        setFRsent(true);
        await handlefollowUser(user.uid, friendUid);
        toast.success("Friend request has been sent", toastOptions);
      }
    } catch (error) {
      console.error("Error handling friend action:", error.message);
    }
  };

  return {
    requestList,
    pendingList,
    activeList,
    handleFriendAction,

  };
}
