
import { useQuery } from '@tanstack/react-query';

const fetcher = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

const useFetch = (url) => {
  return useQuery({queryKey:['fetchData', url], queryFn:() => fetcher(url),refetchOnReconnect:true});
};

export default useFetch;
