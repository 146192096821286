import { ConnectionRequest } from "./ConnectionRequest";
import UserActiveConnections from "./ActiveConnections";
import React, { useState, useEffect } from "react";
import { UserAuth } from "../Context";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { useParams } from "react-router-dom";

export default function UserConnections({ userData }) {
  const { uid } = useParams();
  const { user } = UserAuth();
  const [pendingList, setPendingList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [connectionsTimestamps, setConnectionsTimestamps] = useState({});

  useEffect(() => {
    if (!uid) {
      // Handle the case where the user is not logged in or the data is not yet loaded
      return;
    }
  
    // Reference to the user's connections subcollection
    const connectionsRef = collection(db, "users", uid, "connections");
  
    // Listen for real-time updates to the connections subcollection
    const unsubscribe = onSnapshot(connectionsRef, (snapshot) => {
      const pendingList = [];
      const activeList = [];
      const timestamps = {};
      
      snapshot.forEach((doc) => {
        const data = doc.data();
        timestamps[doc.id] = data.timestamp;
        if (data.type === "pending") {
          pendingList.push(doc.id);
        } else if (data.type === "active") {
          activeList.push(doc.id);
        }
      });
  
      setPendingList(pendingList);
      setActiveList(activeList);
      setConnectionsTimestamps(timestamps); 
    });
  
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [uid]);

  // Render a loading message or similar if the user data is not yet available
  if (!uid) {
    return <div>Loading...</div>;
  }

  const Friendlist = userData?.friendRequests?.active || [];
  const privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const isUserInFriendList = Friendlist.includes(user?.uid);

  return (
    <>
      {/* Card Connections START */}
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <>
          <div class="card">
            {/* Card header START */}
            <div class="card-header border-0 pb-0 px-0">
              <h5 class="card-title px-4">
                {activeList.length || 0} Connections
              </h5>
            </div>
            {/* Card header END */}
            {/* Card body START */}
            <div class="card-body pt-0 px-0">
              {/* Connections Item */}

              {isCurrentUserProfile && (
                <ConnectionRequest
                  pendingList={pendingList}
                  loggedInUser={user?.uid}
                  connectionsTimestamps={connectionsTimestamps}
                />
              )}
              {/* Divider */}
              <div>
                <hr className="mb-4 mt-0" />
              </div>
              <UserActiveConnections
                activelist={activeList}
                loggedInUser={user?.uid}
                connectionsTimestamps={connectionsTimestamps}
              />
              <div class="d-grid">
                {/* Load more button START */}
                {/* <a
              href="#!"
              role="button"
              class="btn btn-sm btn-loader btn-primary-soft"
              data-bs-toggle="button"
              aria-pressed="true"
            >
              <span class="load-text"> Load more connections </span>
              <div class="load-icon">
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </a> */}
                {/* Load more button END */}
              </div>
            </div>
            {/* Card body END */}
          </div>
          {/* Card Connections END */}
        </>
      )}

      {!isCurrentUserProfile && privacy === "private" && (
        <>
          <div class="card px-4">
            <div class="card-header border-0 pb-0">
              <h5 class="card-title">Profile Info</h5>
            </div>
            <div class="card-body">
              <p>This profile is private.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}
