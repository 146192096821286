import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import SendFriendRequest from "../UserProfile/SendConnectionRequest";
import { HandleRejectConnectionReq } from "../UserProfile/ConnectionRequest";
import { HandleUnfriend } from "../UserProfile/ActiveConnections";
import handlefollowUser from "../hooks/user/followUser";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { useFetchUserData } from "../hooks/user/useFetchUserData";

function AddInvitedUser({ notification, user }) {
  const [requestList, setRequestList] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const [FRsent, setFRsent] = useState();
  const [friendUid, setFriendUid] = useState(null);

  const { userData, } = useFetchUserData(notification?.id)

  useEffect(() => {
    if (userData) {
      setActiveList(userData.friendRequests?.active || []);
      setRequestList(userData.requestList || []);
    }
  }, [userData]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (friendUid) {
          // Fetch friend's data
          const friendDocRef = doc(db, "users", friendUid);
          const friendDocSnapshot = await getDoc(friendDocRef);
          if (friendDocSnapshot.exists()) {
            const friendData = friendDocSnapshot.data();
            // setUserData(friendData);
            setActiveList(friendData.friendRequests?.active);
            setRequestList(friendData.requestList);
          } else {
            console.log("Friend document does not exist.");
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error.message);
      }
    };

    fetchUserData();
  }, [user, friendUid, FRsent]);

  const handleFriendAction = async (notification) => {
    try {
      const friendUid = notification.id;
      if (activeList && activeList.includes(user.uid)) {
        await HandleUnfriend(user.uid, friendUid);
        setFRsent(false);
      } else if (requestList && requestList.includes(user.uid)) {
        await HandleRejectConnectionReq(user.uid, friendUid);
        setFRsent(false);
      } else {
        await SendFriendRequest(user.uid, friendUid);
        setFRsent(true);
        await handlefollowUser(user.uid, friendUid);
        toast.success("Friend request has been sent", toastOptions);
      }
    } catch (error) {
      console.error("Error handling friend action:", error.message);
    }
  };
  return (
    <li>
      <div className="list-group-item list-group-item-action rounded badge-unread d-flex border-0 mb-1 p-3">
        <div className="avatar text-center d-none d-sm-inline-block">
          <img
            className="avatar-img rounded-circle"
            src={
                userData?.photo ??
              `https://ui-avatars.com/api/?name=${userData?.userName}`
            }
            alt={userData?.userName || "User"}
            loading="lazy"
          />
        </div>
        <div className="ms-sm-3">
          <div className="d-flex">
            <p className="small mb-2">
              <b>{userData?.userName}</b> is now on Lookflock.
            </p>
            <p className="small ms-3 text-nowrap">
              {/* Add timestamp or relative time here */}
            </p>
          </div>
          <div className="d-flex">
            <button
              className="btn btn-sm py-1 btn-primary me-2"
              type="button"
              onClick={() => {
                setFriendUid(notification?.id); // Set the friend's UID for fetching data
                handleFriendAction(notification);
              }}
            >
              <i className="bi bi-person-plus"></i>{" "}
              {activeList && activeList.includes(user?.uid)
                ? "Unfriend"
                : requestList && requestList.includes(user?.uid)
                ? "Cancel Request"
                : "Add Friend"}
            </button>
            <button className="btn btn-sm py-1 btn-danger-soft">Delete</button>
          </div>
        </div>
      </div>
    </li>
  );
}

export default AddInvitedUser;
