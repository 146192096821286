import React, { useEffect, useRef,forwardRef } from "react";

const VideoCard = forwardRef(({ url, userData, inView }, ref) => {
  const videoRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (videoRef.current) {
        if (inView) {
          videoRef.current.play().catch(error => {
            console.log("Error playing video:", error);
          });
        } else {
          videoRef.current.pause();
        }
      }
    }, 100); // debounce time in ms

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [inView]);

  // Inline styles
  const wrapperStyle = {
    position: 'relative',
  };

  const videoStyle = {
    width: '100%',
    borderRadius: '7px',
  };

  const overlayStyle = {
    position: 'absolute',
    top: '10px',
    left: '10px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    padding: '5px 9px',
    borderRadius: '15px',
  };

  const logoStyle = {
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    marginRight: '10px',
  };

  const nameStyle = {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const logo = userData?.photo || `https://ui-avatars.com/api/?name=${userData.userName[0].toUpperCase()}`

  return (
    <div style={wrapperStyle} ref={ref}>
      <div style={overlayStyle}>
        <img src={logo} alt={`${logo} logo`} style={logoStyle} />
        <span style={nameStyle}>{userData?.userName}</span>
      </div>
      <video ref={videoRef} style={videoStyle} controls muted crossOrigin="anonymous" poster="">
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
});

export default VideoCard;
