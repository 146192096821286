import Masonry from "react-responsive-masonry";
import { XMasonry, XBlock } from "react-xmasonry";
import React, { useEffect, useRef, useState } from "react";
import TestBrandCard from "./TestBrandCard";
import TestPromotionCard from "./TestPromotionCard";
import { getRandomText } from "./utils";
import data from "./testData.json";

export default function NewsFeed() {
  const [columnCount, setColumnCount] = useState(getColumnCount());
  // const data = Array.from({ length: 20 }, (_, index) => index + 1);
  useEffect(() => {
    console.log("indx");
    function handleResize() {
      setColumnCount(getColumnCount());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function getColumnCount() {
    const screenWidth = window.innerWidth;
    let count = 2;
    if (screenWidth >= 440) {
      count = 3;
    }
    if (screenWidth >= 768) {
      count = 4;
    }
    if (screenWidth >= 900) {
      count = 5;
    }
    return count;
  }
  // console.log("Posts are", posts);

  return (
    <div className="px-3">
      <XMasonry responsive={true} maxColumns={5} targetBlockWidth={250}>
        {data.map((post, index) => {
          if (post.type == "promotion") {
            return (
              <XBlock width={2}>
                <TestPromotionCard post={post} />
              </XBlock>
            );
          } else if (post.type == "brandPost") {
            return (
              <XBlock>
                <TestBrandCard post={post} />
              </XBlock>
            );
          }
        })}
      </XMasonry>
    </div>
  );
}
