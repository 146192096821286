import {doc,getDoc,updateDoc} from "firebase/firestore";
import { db } from "../FirebaseConfig";


export const incrementProductView = async (product) => {
  try {
    const productRef = doc(db, "products", product.id); 
    
    const productDoc = await getDoc(productRef);
    
    if (productDoc.exists()) {
      const newViews = productDoc.data().views + 1;
      
      await updateDoc(productRef, { views: newViews });
      console.log(`Successfully incremented views for product with ID: ${product.id}`);
    } else {
      console.error(`Document does not exist for product with ID: ${product.id}`);
    }
  } catch (error) {
    console.error("Error incrementing product view:", error);
  }
};
