import React from 'react';
import { useFetchUserData } from '../hooks/user/useFetchUserData';
import formatTimestamp from '../lib/formatTimestamp';

function PostLike({ notification }) {
  const { userData } = useFetchUserData(notification?.id);
  return (
    <li>
      <div className="list-group-item list-group-item-action rounded badge-unread d-flex border-0 mb-1 p-3 position-relative">
        <div className="avatar text-center d-none d-sm-inline-block">
          <img
            className="avatar-img rounded-circle"
            src={
              userData?.photo ??
              `https://ui-avatars.com/api/?name=${userData?.userName}`
            }
            alt=""
          />
        </div>
        <div className="ms-sm-3 d-flex justify-content-between w-100">
          <div>
            <p className="small mb-0">
              <b>{userData?.userName}</b> liked your post
            </p>
            <button className="btn btn-sm btn-outline-light py-1 me-2"></button>
          </div>
          <p className="small mb-0 ms-3">
            {formatTimestamp(notification.timestamp)}
          </p>
        </div>
      </div>
    </li>
  );
}

export default PostLike;
