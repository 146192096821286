import React, { useEffect, useState } from "react";
import { UserAuth } from "../Context";
import {
  doc,
  updateDoc,
  getDoc,
  setDoc,
  serverTimestamp,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Link } from "react-router-dom";
import { Modal, Button, FormSelect } from "react-bootstrap";
import { IoIosCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import { PersonExclamation, PersonPlusFill } from "react-bootstrap-icons";
function InviteOnList({ selectedShopList, listId }) {
  console.log("I am on Invite on List", selectedShopList);
  const [selectedRole, setSelectedRole] = useState("editor"); // Initialize with a default value
  const [friendId, setFriendId] = useState("");
  const [friendsData, setFriendsData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [unsharedUsers, setUnsharedUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [friendCount, setFriendCount] = useState(0);
  // let friendCount = 0;
  const { user } = UserAuth();
  const [data, setData] = useState({
    friendId: "",
    displayName: "",
  });
  const rolesOptions = [
    { name: "editor", label: "Editor" },
    { name: "viewer", label: "Viewer" },
    { name: "commentor", label: "Commentor" },
  ];

  const handleClick = async () => {
    // console.log(friendId);
    // console.log(selectedRole);
    if (!friendId) return;
    try {
      const newFriend = {
        role: selectedRole,
        timestamp: serverTimestamp(),
      };

      const friendRef = doc(
        db,
        "users",
        user.uid,
        "shoppingList",
        selectedShopList,
        "members",
        friendId
      );

      const friendSnap = await getDoc(friendRef);

      if (friendSnap.exists()) {
        // If the friend already exists, update the role and timestamp
        await updateDoc(friendRef, {
          role: newFriend.role,
          timestamp: serverTimestamp(),
        });
      } else {
        // If the friend does not exist, add the new friend
        await setDoc(friendRef, newFriend);
      }

      // Add to the friend's invitedList subcollection
      const invitedListRef = doc(db, "users", friendId, "invitedList", listId);

      const invitedListData = {
        userId: user.uid,
        timestamp: serverTimestamp(),
        role: newFriend.role,
      };

      await setDoc(invitedListRef, invitedListData);

      setFriendId("");
      fetchFriends();
      // Change visibility to "Friends"
      const shoppingListRef = doc(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList
      );

      await updateDoc(shoppingListRef, {
        visibility: "Friends",
      });

      toast.success("Friend invited successfully!", toastOptions);
      console.log("Friend successfully added/updated!");
    } catch (error) {
      console.error("Error updating friend: ", error);
      toast.error("Error inviting friend!", toastOptions);
    }
  };

  const handleRoleChange = (selectedRole) => {
    console.log("Role", selectedRole);
    setSelectedRole(selectedRole);
  };

  const handleNameChange = (selectedOption) => {
    console.log("Name", selectedOption);
    setFriendId(selectedOption);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (user) {
        try {
          // Reference to the connections subcollection of the logged-in user
          const connectionsRef = collection(db, "users", user?.uid, "connections");
          const connectionsSnapshot = await getDocs(connectionsRef);
  
          const activeFriendIds = [];
  
          // Loop through the connections and gather active connections
          connectionsSnapshot.forEach((doc) => {
            const connectionData = doc.data();
            if (connectionData.type === "active") {
              activeFriendIds.push(doc.id); // Assuming doc.id is the friend's UID
            }
          });
  
          setFriendCount(activeFriendIds.length);
  
          // Fetch the user data for each active friend
          const userPromises = activeFriendIds.map((userId) =>
            getDoc(doc(db, "users", userId))
          );
  
          const userDocs = await Promise.all(userPromises);
  
          // Map the user data to a format suitable for the component
          const userOption = userDocs.map((userDoc) => ({
            id: userDoc.id,
            label: userDoc.data()?.userName,
          }));
  
          console.log("userOption", userOption);
          setUserOptions(userOption);
  
        } catch (error) {
          console.error("Error fetching users:", error.message);
        }
      }
    };
  
    fetchUsers();
  }, [user]);
  
  console.log("Friend Count outside", friendCount);
  const fetchFriends = async () => {
    if (!user) {
      console.error("User is undefined or null");
      return;
    }

    if (!selectedShopList) {
      console.error("SelectedShopList is undefined or null");
      return;
    }
    try {
      console.log(selectedShopList);
      const docRef = collection(
        db,
        "users",
        user?.uid,
        "shoppingList",
        selectedShopList,
        "members"
      );
      const docSnap = await getDocs(docRef);
      // console.log("DocSnap", docSnap);
      const friendsArray = docSnap.docs.map((doc) => ({
        id: doc?.id,
        role: doc?.data().role,
      }));
      // console.log("Friend", friendsArray);
      const friendPromises = friendsArray
        .filter((friend) => friend?.id) // Filter out friends with invalid IDs
        .map((friend) => getDoc(doc(db, "users", friend?.id)));

      const friendDocuments = await Promise.all(friendPromises);
      const friendData = friendDocuments.map((friendDoc) => {
        const rolesFromShopList =
          friendsArray.find((f) => f.id === friendDoc.id)?.role || {};
        return {
          id: friendDoc?.id,
          userName: friendDoc?.data()?.userName,
          role: rolesFromShopList,
        };
      });
      // console.log("FriendData", friendData);
      setFriendsData(friendData);
    } catch (error) {
      console.error("Error fetching friends:", error);
    }
  };

  useEffect(() => {
    fetchFriends();
  }, [user, selectedShopList]);
  useEffect(() => {
    if (!friendsData) return;
    console.log(selectedShopList);
    const friendIds = friendsData.map((friend) => friend.id);
    const remainingUsers = userOptions.filter(
      (user) => !friendIds.includes(user.id)
    );
    console.log("users", remainingUsers);
    setUnsharedUsers(remainingUsers);
  }, [selectedShopList, friendsData]);
  const handleRevokeUser = async (index, Id) => {
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    try {
      if (docSnap.exists) {
        // Remove the friend from the array
        await deleteDoc(docRef);
        await deleteDoc(invitedListRef);
        const newfriendsData = friendsData.filter((friend) => friend.id !== Id);
        setFriendsData(newfriendsData);
        console.log("friendsData", newfriendsData);
        toast.success("Access revoked successfully!", toastOptions);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error removing user!", toastOptions);
    }
  };
  const handleRoleUpdate = async (index, Id, newRole) => {
    // console.log(Id, newRole);
    // Update the friend's role in the database
    setShowModal(false);
    const docRef = doc(
      db,
      "users",
      user?.uid,
      "shoppingList",
      selectedShopList,
      "members",
      Id
    );
    const invitedListRef = doc(db, "users", Id, "invitedList", listId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists) {
      const newfriendsData = friendsData;
      newfriendsData[index].role = newRole;
      setFriendsData(newfriendsData);
      // If the friend exists, update the role
      await updateDoc(docRef, {
        role: newRole,
        timestamp: serverTimestamp(),
      });
      await updateDoc(invitedListRef, {
        role: newRole,
        timestamp: serverTimestamp(),
      });
      // fetchFriends();
      toast.success(`Role updated successfully!`, toastOptions);
      console.log(`Role for friend ${Id} updated successfully!`);
    } else {
      console.log(`No friend found with ID ${Id}`);
    }
  };

  return (
    <>
      <li className="nav-item" onClick={() => setShowModal(true)}>
        <Link className="nav-link icon-md btn btn-light p-0">
          <PersonPlusFill className="fs-5" />
          {/* <i className="bi bi-people-fill"></i> */}
        </Link>
        <span className="mx-2 d-sm-none h6 fw-light">Invite friends</span>
      </li>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Invite Friend</Modal.Title>
        </Modal.Header>
        {friendCount > 0 ? (
          <div>
            <form>
              <div className="p-3 pb-0 d-flex justify-content-between">
                <div className="w-50 me-1">
                  <FormSelect
                    style={{ height: "2rem" }}
                    className="pt-1"
                    onChange={(event) => handleNameChange(event.target.value)}
                    value={friendId}
                  >
                    <option className="text-body" value="">
                      Select a friend
                    </option>
                    {unsharedUsers.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        className="text-body"
                      >
                        {option.label}
                      </option>
                    ))}
                  </FormSelect>
                </div>
                <div className="w-50 ms-1">
                  <FormSelect
                    className="pt-1"
                    style={{ height: "2rem" }}
                    onChange={(event) => handleRoleChange(event.target.value)}
                    value={selectedRole?.name}
                  >
                    {rolesOptions.map((option) => (
                      <option key={option.name} value={option.name}>
                        {option.label}
                      </option>
                    ))}
                  </FormSelect>
                </div>
                <Button
                  className="ms-2"
                  size="sm"
                  variant="primary-soft"
                  onClick={() => {
                    handleClick();
                    setShowModal(false);
                  }}
                >
                  Invite
                </Button>
              </div>
              <hr />
              {friendsData?.length > 0 && (
                <>
                  <div className="ps-3">
                    <h6 className="text-body text-center">Invited Friends</h6>
                  </div>
                  <div className="d-flex flex-column mt-3 justify-content-center align-items-center pe-3 pb-4 pt-0">
                    {friendsData?.map((friend, index) => {
                      return (
                        <div
                          key={friend.id}
                          className="d-flex justify-content-center align-items-center w-100 my-1"
                        >
                          <div className="w-50 ms-3">{friend.userName}</div>
                          <FormSelect
                            style={{ height: "2rem" }}
                            className="w-50 pt-1"
                            onChange={async (event) => {
                              await handleRoleUpdate(
                                index,
                                friend.id,
                                event.target.value
                              );
                            }}
                            value={friend.role}
                          >
                            {rolesOptions.map((option) => (
                              <option key={option.name} value={option.name}>
                                {option.label}
                              </option>
                            ))}
                          </FormSelect>
                          <IoIosCloseCircle
                            className="fs-4 ms-2 "
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setData({
                                friendId: friend?.id,
                                displayName: friend?.userName,
                              });
                              console.log("data", data);
                              setShowRemoveModal(true);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </form>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center py-4">
            <div className="text-muted pb-4">
              <PersonExclamation className="display-3" />
            </div>
            <h5 className="text-body">No friends to show</h5>
          </div>
        )}
      </Modal>
      <Modal
        show={showRemoveModal}
        onHide={() => setShowRemoveModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Revoke</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <i
            style={{ fontSize: "4.5rem" }}
            className="text-body bi bi-exclamation-triangle"
          ></i>
          <div className="mt-4 fs-5 text-body">
            Are you sure you want to revoke access from {data.displayName}?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="secondary-soft"
            className="w-25 mx-1"
            onClick={() => {
              setData({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="w-25 mx-1"
            onClick={() => {
              console.log("data.friendId", data.friendId);
              handleRevokeUser("", data.friendId);
              setData({
                friendId: "",
                displayName: "",
              });
              setShowRemoveModal(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InviteOnList;
