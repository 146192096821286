import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useModalStore from "../StatesStore/ModalStore";
import { dataLike, titleLike } from "../Content/ModalContent";
import handleLikeClick from "../hooks/product/likeProduct";
import { useQueryClient } from "@tanstack/react-query";
import useLikeStore from "../StatesStore/LikeStore";

{/* Description: Handles the functionality to like or unlike a product, managing like counts and UI state related to liking a product. */}


function AddOrRemoveLike({ user, product, setLikeCount, componentName }) {
  const likedProducts = useLikeStore((state) => state.likedProducts);
  const addLike = useLikeStore((state) => state.addLike);
  const removeLike = useLikeStore((state) => state.removeLike);
  const queryClient = useQueryClient();
  const { openModal } = useModalStore();
  const [isLiked, setIsLiked] = useState(false);

  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", titleLike, dataLike); // Open modal here
    }
  };
  const handleUpdateLike = async () => {
    if (likedProducts.includes(product?.id)) {
      removeLike(product?.id);
    } else {
      addLike(product?.id);
    }
  };
  // const fetchLikeData = async () => {
  //   if (!user || !product) return;
  //   // const likeDocRef = doc(db, `users/${user?.uid}/likeList`, product?.id);
  //   const likeDocRef = doc(db, "users", user?.uid, "likeList", product?.id);
  //   const likeDocSnapshot = await getDoc(likeDocRef);
  //   setIsLiked(likeDocSnapshot?.exists());
  // };

  // useEffect(() => {
  //   fetchLikeData();
  // }, [user, product?.id]);
  useEffect(() => {
    setIsLiked(likedProducts.includes(product?.id));
  }, [likedProducts]);
  return (
    <Link
      className={`nav-link ${
        componentName == "strip" || componentName == "strip2" ? "" : ""
      } gap-2 p-0 nav-item `}
      onClick={async () => {
        if (!user) {
          handleClick();
        } else {
          setIsLiked(!isLiked);
          setLikeCount((prevLikeCount) => {
            const newLikeCount = isLiked
              ? Math.max(prevLikeCount - 1, 0)
              : prevLikeCount + 1;
            return newLikeCount;
          });
          await handleLikeClick(user?.uid, product, queryClient);
          await handleUpdateLike();
        }
      }}
    >
      {!user && (
        <Link
          className={`text-${componentName == "strip2" ? "light" : ""} p-0`}
          onClick={handleClick}
        >
          <i className={"bi bi-hand-thumbs-up fs-6"}></i>
        </Link>
      )}
      {user && (
        <i
          className={`bi ${
            isLiked ? "bi-hand-thumbs-up-fill fs-6" : "bi-hand-thumbs-up fs-6"
          }`}
        ></i>
      )}
      {/* {componentName === "strip" || componentName === "strip2" ? "" : "Like"} */}
    </Link>
  );
}

export default AddOrRemoveLike;
