import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import ProductCard from "../Products/RecentViewedCard";
import Slider from "react-slick"; // Install react-slick: npm install react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const PrevArrow = ({ style, onClick, currentSlide }) => {
  return (
    <div
      className="slick-prev"
      style={{
        ...style,
        fontSize: "24px",
        // color: "grey",
        // background: currentSlide === 0 ? "rgba(0, 0, 0, 0.5)" : "transparent", // Add background when disabled
        cursor: currentSlide === 0 ? "not-allowed" : "pointer", // Add cursor style
      }}
      onClick={currentSlide === 0 ? null : onClick} // Disable click when at the beginning
    >
      <span
        className="carousel-control-prev-icon"
        style={{ filter: "brightness(0.5)" }}
      />
    </div>
  );
};

const NextArrow = ({ style, onClick, currentSlide, slideCount }) => {
  return (
    <div
      className="slick-next"
      style={{
        ...style,
        fontSize: "24px",
        // color: "grey",
        // background: currentSlide === slideCount - 1 ? "rgba(0, 0, 0, 0.5)" : "transparent", // Add background when disabled
        cursor: currentSlide === slideCount - 1 ? "not-allowed" : "pointer", // Add cursor style
      }}
      onClick={currentSlide === slideCount - 1 ? null : onClick} // Disable click when at the end
    >
      <span
        className="carousel-control-next-icon"
        style={{ filter: "brightness(0.5)" }}
      />
    </div>
  );
};

function RecentViewed() {
  const label = "Recently Viewed Products";
  const { user } = UserAuth();
  const [products, setProducts] = useState([]);
  const [sliderSettings, setSliderSettings] = useState({
    infinite: false, // Enable infinite loop
    slidesToShow: 4, // Show 3 cards at a time
    slidesToScroll: 1, // Move by 1 card on navigation
    accessibility: true, // Enable accessibility features
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768, // Adjust breakpoint for smaller screens
        settings: {
          slidesToShow: 2, // Show 2 cards on smaller screens
          slidesToScroll: 2, // Move by 2 cards on navigation
        },
      },
    ],
  });

  useEffect(() => {
    const fetchRecentViewedProducts = async () => {
      if (!user) return;

      const userActivityRef = collection(db, "users", user.uid, "userActivity");
      const q = query(userActivityRef, where("type", "==", "view"));

      try {
        const querySnapshot = await getDocs(q);
        const viewedProductIds = querySnapshot.docs.map(
          (doc) => doc.data().product
        );
        const uniqueProductIds = Array.from(new Set(viewedProductIds));

        const productPromises = uniqueProductIds.map(async (productId) => {
          const productDoc = await getDoc(doc(db, "products", productId));
          return productDoc.exists() ? productDoc.data() : null;
        });

        const productDetails = await Promise.all(productPromises);
        setProducts(productDetails.filter((product) => product !== null));
      } catch (error) {
        console.error("Error fetching recent viewed products: ", error);
      }
    };

    fetchRecentViewedProducts();
  }, [user]);

  return (
    <div className="recent-viewed">
      <div className="card-header" style={{ paddingLeft: "20px" }}>
        <h1 className="card-title h5">{label}</h1>
      </div>
      <div
        style={{ marginTop: "30px", paddingLeft: "50px", paddingRight: "50px" }}
      >
        <Slider {...sliderSettings}>
          {products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default RecentViewed;
