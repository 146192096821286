import Masonry from "react-responsive-masonry";
import React, { useState } from "react";

import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import ProductCard3 from "../Products/ProductCard3";

function BrandPosts() {
  const { brandName } = useParams();
  const [columnCount, setColumnCount] = useState(getColumnCount());
  const { data, isLoading } = useFetch(
    process.env.REACT_APP_API + `/api/brandFeed/${brandName}`
  );

  function getColumnCount() {
    const screenWidth = window.innerWidth;
    let count = 2;
    if (screenWidth >= 440) {
      count = 3;
    }
    if (screenWidth >= 768) {
      count = 4;
    }
    if (screenWidth >= 900) {
      count = 4;
    }
    return count;
  }
  return (
    <>
      <Masonry columnsCount={columnCount} className="px-1" gutter="10px">
        {data?.map((post, index) => (
          <>
            <ProductCard3 key={post.id} product={post} />
          </>
        ))}
      </Masonry>
    </>
  );
}

export default BrandPosts;
