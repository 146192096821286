import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../FirebaseConfig";

export const saveInviteToDb = async (inviteData, user) => {
  try {
    await addDoc(collection(db, "inviteList"), {
      ...inviteData,
      invitedBy: user.uid,
      timestamp: serverTimestamp(),
      joined:false
    });
    console.log("Invite added to Firestore");
  } catch (error) {
    console.error("Error adding invite to Firestore: ", error);
  }
};
