import React from "react";
import { useFetchUserData } from "../hooks/user/useFetchUserData";
import formatTimestamp from "../lib/formatTimestamp";

function ShoppingListChatMessage({notification}) {
  const { userData } = useFetchUserData(notification?.id);
  return (
    <li>
      <div class="list-group-item list-group-item-action rounded badge-unread d-flex border-0 mb-1 p-3 position-relative">
        <div class="avatar text-center d-none d-sm-inline-block">
          <img
            class="avatar-img rounded-circle"
            src={
              userData?.photo ??
              `https://ui-avatars.com/api/?name=${userData?.userName}`
            }
            alt=""
          />
        </div>
        <div class="ms-sm-3 d-flex justify-content-between w-100">
          <div>
            <p class="small mb-0">
              <b>{userData?.userName}</b> commented on your {notification.shopList} list {" "}
            </p>
            {/* <button class="btn btn-sm btn-outline-light py-1 me-2"></button> */}
          </div>
          <p class="small mb-0 ms-3">{formatTimestamp(notification.timestamp)}</p>
        </div>
      </div>
    </li>
  );
}

export default ShoppingListChatMessage;
