const handleUnfollowBrand = async (userId, brandId) => {
  console.log("Sender of request", userId);
  console.log("Brand of request (brand to unfollow)", brandId);

  try {
    const response = await fetch(
      process.env.REACT_APP_API + "/api/unfollowBrand",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ brandId, userId }),
      }
    );

    await response.json();
    console.log("unfollowed brand successfully!");
  } catch (error) {
    console.error("Error updating document: ", error);
  }

  return true;
};
export default handleUnfollowBrand;
