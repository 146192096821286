import React, { useState, useEffect } from "react";
import fetchProducts from "../lib/productUtils";
import Masonry from "react-responsive-masonry";
import { db } from "../FirebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";

function CollumnPage() {
  const brandName = "GulAhmed";
  const collectionRef = collection(db, "brands");

  const [allProducts, setAllProducts] = useState([]);
  // const [, setBname] = useState("");
  const [lastVisible, setLastVisible] = useState(null);
  const [, setLoading] = useState(false);
  const [, setCountLeft] = useState(true);
  const [prevSubCategory] = useState(null);
  const [prevSubSubCategory] = useState(null);
  const [brandInfo, setBrandInfo] = useState();

  const getBrandDetails = async () => {
    const q = query(collectionRef, where("name", "==", brandName));
    const snapshot = await getDocs(q);
    const brandDoc = snapshot.docs[0];
    setBrandInfo(snapshot.docs[0].data());

    const brandDocRef = doc(db, "brands", brandDoc.id);
    await updateDoc(brandDocRef, {
      views: brandDoc.data().views + 1,
    });
  };

  useEffect(() => {
    getBrandDetails();
    // setBname(brandName);
    fetchProducts({
      brandName,
      prevSubSubCategory,
      prevSubCategory,
      lastVisible,
      setAllProducts,
      setLoading,
      setLastVisible,
      setCountLeft,
    });
  }, [brandName]);

  const items = allProducts.map((product, index) => (
    <div
      key={product.id}
      style={{
        position: "relative",
        height: "auto",
        margin: "10px",
        borderRadius: "8px",
      }}
    >
      <img
        src={product.imageUrl}
        alt=""
        style={{
          height: index % 2 ? "400px" : "450px",
          width: "100%",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
      {/* Brand Logo */}
      <img
        className="brand-logo"
        src={"../assets/images/brands/" + brandInfo?.logo}
        alt=""
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          height: "30px", // Adjust size as needed
          width: "auto", // Adjust size as needed
          borderRadius: "8px",
        }}
      />
    </div>
  ));

  return (
    <div className="App">
      <Masonry columnsCount={2} gutter="10px">
        {items}
      </Masonry>
    </div>
  );
}

export default CollumnPage;
