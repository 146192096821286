import { create } from "zustand";
import { devtools } from "zustand/middleware";

const LikeStore = (set) => ({
  likedProducts: [],

  addLike: (Id) => {
    set((state) => {
      if (state.likedProducts.includes(Id)) {
        return state; // No changes if the ID already exists
      }
      return {
        likedProducts: [Id, ...state.likedProducts],
      };
    });
  },

  removeLike: (Id) => {
    set((state) => ({
      likedProducts: state.likedProducts.filter((id) => id !== Id),
    }));
  },
});

const useLikeStore = create(devtools(LikeStore));

export default useLikeStore;
