import React, { useState, useEffect } from "react";
import { db } from "../FirebaseConfig";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";

function DiscountCount({ brandName }) {
    const [itemCount, setItemCount] = useState();
    const collectionRef = collection(db, "products");
  
    useEffect(() => {
      const getItemCount = async () => {
          const q = query (collectionRef, 
              where('supplier', '==', brandName),
              )
  
          const snapshot = await getCountFromServer(q)
          const totalCount = snapshot.data().count
          setItemCount(totalCount)
      }
      getItemCount();
    }, [brandName])
    return <>{itemCount}</>;
  }

export default DiscountCount