import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import handlefollowUser from "../hooks/user/followUser";
import handleUnfollowUser from "../hooks/user/unfollowUser";
import axios from "axios"; // Assuming you are using axios for HTTP requests

export default function UserActiveFollowers({ followerlist, loggedInUser }) {
  const [userData, setUserData] = useState([]);
  const { user } = UserAuth();
  const { uid } = useParams();
  const myID = user;
  const [myfollowinglist, setMyFollowinglist] = useState([]);
  useEffect(() => {
    if (!followerlist || followerlist.length === 0) {
      // If it's null or empty, you can either set the userData to an empty array or keep the existing data
      setUserData([]);
      return; // Prevents further execution of the useEffect
    }

    const fetchUserData = async () => {
      try {
        const userList = await Promise.all(
          followerlist.map(async ({ id, type }) => {
            const collection = type === "user" ? "users" : "brands";
            console.log("Fetching document", { id, collection });

            const docRef = doc(db, collection, id);
            const docSnap = await getDoc(docRef);

            console.log("Document reference:", docRef);
            console.log("Document snapshot:", docSnap);

            if (docSnap.exists()) {
              console.log("Document data:", docSnap.data());
              return { ...docSnap.data(), uid: docSnap.id };
            } else {
              console.log("No such document!", { id, collection });
              return null;
            }
          })
        );

        const filteredUserList = userList.filter((user) => user !== null);
        console.log(
          "Fetched users/brands from active friends:",
          filteredUserList
        );
        setUserData(filteredUserList);
      } catch (error) {
        console.error("Error fetching users/brands:", error);
      }
    };
    const fetchMyFollowingList = async () => {
      try {
        // const response = await axios.get(process.env.REACT_APP_API+`/api/userFollowing/${uid}`);
        const response = await axios.get(
          process.env.REACT_APP_API + `/api/userFollowing/${myID.uid}`
        );
        console.log("response.data", response.data);
        setMyFollowinglist(response.data);
      } catch (error) {
        console.error("Error fetching following list:", error);
      }
    };

    fetchUserData();
    fetchMyFollowingList();
  }, [followerlist]);
  console.log("UserDatas from active connections", userData);

  const isCurrentUserProfile = user?.uid === uid;

  return (
    <>
      {/* Connections Item */}
      {userData.map((user) => {
        return (
          <div class="d-md-flex align-items-center mb-4">
            {/* Avatar */}
            <div class="avatar me-3 mb-3 mb-md-0">
              <Link
                to={`/userProfile/${user.uid}`}
                class="avatar-img rounded-circle"
              >
                <ProfilePicture userData={user} avatarSize={42} />
              </Link>
            </div>
            {/* Info */}
            <div class="w-100">
              <div class="d-sm-flex align-items-start">
                <h6 class="mb-0">
                  <Link to={`/userProfile/${user.uid}`} className="h6 mb-0">
                    {user.userName
                      ? user.userName
                          .split(" ")
                          .slice(0, 2)
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : ""}
                  </Link>
                </h6>
                <p className="small ms-sm-2 mb-0">
                  {" "}
                  {user.userTitle || ""}
                </p>
              </div>
              {/* Connections START */}
            </div>
            {/* Button */}
            <div class="ms-md-auto d-flex">
              {/* {isCurrentUserProfile && (
              // for own profile you can block user from followers page 
    <>
             <button class="btn btn-danger-soft btn-sm mb-0" onClick={() => HandleBlockUser(user.uid, loggedInUser)}>Block</button>
    </>
  )}   */}

              {!isCurrentUserProfile && (
                <>
                  {!(myID.uid === user.uid) ? (
                    myfollowinglist.some(
                      (following) =>
                        following.id === user.uid && following.type === "user"
                    ) ? (
                      <button
                        className="btn btn-primary me-2"
                        onClick={async () => {
                          await handleUnfollowUser(loggedInUser, user.uid);
                        }}
                      >
                        Unfollow
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary me-2"
                        onClick={async () => {
                          await handlefollowUser(loggedInUser, user.uid);
                        }}
                      >
                        Follow
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
