import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { UserAuth } from "../Context";
import SharedProductCardShoppingList from "../ShoppingList/SharedProductCardShoppingList";
import SharedListComments from "../ShoppingList/SharedListComments";
import ListComments from "../ShoppingList/ListComments";
import useModalStore from "../StatesStore/ModalStore";
import EmptyList from "../ShoppingList/EmptyList";
import SideNavUserProfile from "../SideNav/SideNavUserProfile";

function SharedShoppingLists() {
  const [shoppingListData, setShoppingListData] = useState([]);
  const [selectedShopList, setSelectedShopList] = useState("");
  const [visibility, setVisibility] = useState("");
  const [friends, setFriends] = useState("");
  const { openModal, closeModal } = useModalStore();
  const { user } = UserAuth();
  let { listId, userId } = useParams();

  useEffect(() => {
    const fetchShoppingLists = async () => {
      try {
        const userRef = doc(db, "users", userId);
        const shoppingListRef = collection(userRef, "shoppingList");

        // Query for the specific document with matching listId
        const q = query(shoppingListRef, where("listId", "==", listId));

        // Set up a listener for the specific shopping list document
        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          if (!querySnapshot.empty) {
            const docSnap = querySnapshot.docs[0];
            const data = docSnap.data();
            const dateCreated =
              data.dateCreated?.seconds || new Date().getTime() / 1000;
            
              const productRef = collection(docSnap.ref, "products");
              const productDocs = await getDocs(productRef);
          
              const products = productDocs.docs.map((productDoc) => ({
                id: productDoc.id,
                ...productDoc.data(),
              }));

            const visibility = data.visibility || "";
            // Fetch friends from the members subcollection
            const membersRef = collection(docSnap.ref, "members");
            const membersSnapshot = await getDocs(membersRef);
            const friends = membersSnapshot.docs.map((memberDoc) => ({
              id: memberDoc.id,
              ...memberDoc.data(),
            }));

            setVisibility(visibility);
            setFriends(friends);

            const shoppingList = {
              name: docSnap.id,
              dateCreated,
              products,
              visibility,
              friends,
              listId: data.listId,
            };

            setShoppingListData([shoppingList]);
          } else {
            console.log("No such document with the specified listId!");
            setShoppingListData([]);
          }
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching shopping list:", error);
      }
    };

    window.scrollTo(0, 0);
    fetchShoppingLists();

    if (!user) {
      openModal("SignInModal");
    } else {
      closeModal();
    }
  }, [user, listId, userId]);

  console.log("The shoppingListData", shoppingListData);
  console.log("friends is ", friends);
  console.log("selectedShopList from Shared shopping list ", selectedShopList);
  useEffect(() => {
    if (shoppingListData?.length > 0) {
      setSelectedShopList(shoppingListData[0].name);
    } else {
      setSelectedShopList("");
    }
  }, [shoppingListData, listId, userId]);

  const handleshopListClick = (shopListName) => {
    setSelectedShopList(shopListName);
  };
  const canViewProducts =
    visibility === "Public" ||
    (visibility === "Private" && false) ||
    (visibility === "Friends" &&
      friends.some((friend) => friend.id === user.uid));
  return (
    <>
     <SideNavUserProfile />
      <div className="col-md-8 col-lg-6 vstack gap-4">
        <div className="card">
          <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pb-0">
            <h1 className="card-title h4">Shopping List</h1>
          </div>
          {/* Card body END */}
          <div className="card-footer mt-3 pt-2 pb-0">
            <ul className="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
              {shoppingListData?.map((shopList) => (
                <li className="nav-item" key={shopList?.name}>
                  <Link
                    className={`nav-link ${
                      selectedShopList === shopList?.name ? "active" : ""
                    }`}
                    to=""
                    onClick={() => handleshopListClick(shopList.name)}
                  >
                    {shopList?.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="card">
          {/* Card header START */}
          <div className="card-header d-sm-flex text-center align-items-center justify-content-between border-0 pb-0">
            <h1 className="card-title h4">{selectedShopList ?? ""}</h1>
          </div>
          {/* Card body START */}
          <div className="card-body">
            <div className="row g-4">
              {canViewProducts ? (
                shoppingListData
                  .filter((shopList) => shopList?.name === selectedShopList)
                  .map((shopList) => (
                    <SharedProductCardShoppingList
                      key={shopList?.name} // Added key for unique list identification
                      listName={shopList?.name}
                      userId={userId}
                      visibility={visibility}
                      friends={friends}
                      shoppingListData={shoppingListData}
                      selectedShopList={selectedShopList}
                      listId={listId}
                      ownerId={userId}
                    />
                  ))
              ) : (
                <EmptyList
                  content={`You do not have access to view this list`}
                />
              )}
            </div>
          </div>

          {canViewProducts && (
            <SharedListComments
              listId={listId}
              userId={user?.uid}
              visibility={visibility}
              friends={friends}
              shoppingListData={shoppingListData}
              selectedShopList={selectedShopList}
              ownerId={userId}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default SharedShoppingLists;
