import React from 'react';
import { Link } from 'react-router-dom';
import { UserAuth } from '../Context';
import { useFollowStatus } from '../hooks/brand/useFollowStatus';


function BrandFeedDropdown({ post }) {
 const { user } = UserAuth();
 const brandToFollow = post?.supplier && post.supplier[0];
 const { isFollowing, toggleFollow } = useFollowStatus(brandToFollow, user?.uid);

 return (
    <>
      <div className="dropdown">
        <Link
          className="text-secondary btn btn-secondary-soft-hover py-1 px-2"
          id="brandFeedAction"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-three-dots"></i>
        </Link>

        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="brandFeedAction">
          <li>
            <Link
              className="dropdown-item"
              onClick={toggleFollow}
            >
              {isFollowing ? (
                <>
                 <i className="bi bi-person-dash fa-fw pe-2"></i>Unfollow {brandToFollow}
                </>
              ) : (
                <>
                 <i className="bi bi-person-plus fa-fw pe-2"></i>Follow {brandToFollow}
                </>
              )}
            </Link>
          </li>
        </ul>
      </div>
    </>
 );
}

export default BrandFeedDropdown;
