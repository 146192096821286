import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const favStore = (set) => ({
    favorites: [],

    addFavorite: (favId) => {
        set((state) => {
            if (state.favorites.includes(favId)) {
                return state; // No changes if the ID already exists
            }
            return {
                favorites: [favId, ...state.favorites],
            };
        });
    },

    removeFavorite: (favId) => {
        set((state) => ({
            favorites: state.favorites.filter((id) => id !== favId),
        }));
    },
});

const useFavStore = create(
    devtools(favStore)
);

export default useFavStore;
