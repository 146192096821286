export const titleNewsFeed ='Join the Social Shopping Experience Now! 😍'

export const dataNewsFeed = `
<br>
<b>🛍️ See cool stuff together:</b> Find friends and browse products you both love.
<br><br>
<b>🤔 Ask for advice:</b> Get help picking the perfect item from your friends.
<br><br>
<b>💪 Buy with confidence:</b> Avoid mistakes by getting feedback before you buy.
<br><br>
<b>💎 Find hidden treasures:</b> Discover amazing products your friends recommend.
<br><br>
Ready to shop <b>smarter</b>? Just sign up or log in to join the fun! 😊 
<br><br>

`;


export const titleFavourite = "Join the Social Shopping Experience Now! 😍";

export const dataFavourite = `
<br>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  top: 0, left: 0, width: '100%', height: '100%',margin:'auto' }}>
<svg width="100px" height="100px" viewBox="0 0 24 24" id="star_outlined" data-name="star outlined" xmlns="http://www.w3.org/2000/svg" style="display: flex; justify-content: center; align-items: center; margin:auto;">
<rect id="Rectangle_4" data-name="Rectangle 4" width="24" height="24" fill="none"/>
<path id="Star" d="M10,15,4.122,18.09l1.123-6.545L.489,6.91l6.572-.955L10,0l2.939,5.955,6.572.955-4.755,4.635,1.123,6.545Z" transform="translate(2 3)" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="1.5"/>
</svg>
</div>

<br>
<div style="text-align: center; font-size: 24px; line-height: 1.5;">
  <b>Favourite it to share the love.</b> 
</div>
<div style="text-align: center; font-size: 14px; line-height: 1.5;">

  Join Lookflock now. ️
</div>
<br>


`;

export const titleLike = "Join the Social Shopping Experience Now! 😍";

export const dataLike = `
<br>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  top: 0, left: 0, width: '100%', height: '100%',margin:'auto' }}>
<svg fill="#000000" width="100px" height="100px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"style="display: flex; justify-content: center; align-items: center; margin:auto;">
<path d="M608.544 1023.744c-290.832 0-293.071-12.062-329.087-39.183-19.104-14.368-55.151-24.32-186.815-32.896-9.552-.624-18.64-4.288-24.735-11.68-2.8-3.408-68.592-99.36-68.592-253.04 0-151.44 47.088-220.465 49.103-223.665a31.965 31.965 0 0 1 27.12-15.04c108.112 0 257.984-138 358.736-378.896C451.698 27.68 455.298.272 519.298.272c36.4 0 77.2 26.064 97.344 59.505 41.328 68.32 20.335 215.057.927 293.473 66-.528 185.472-1.425 242.32-1.425 79.072 0 131.407 47.152 132.991 116.08.529 22.752-2.464 51.808-9.04 66.848 17.408 17.36 39.857 43.536 40.832 77.248 1.216 43.52-27.28 76.655-45.472 95.663 4.175 12.656 12.527 29.44 11.71 49.505-2 49.344-40.095 81.136-63.823 97.727 1.968 13.504 3.504 38.976-.832 58.672-17.12 78.609-132.4 110.177-317.712 110.177zM109.617 886.77c114.688 9.489 175.998 22.336 208.334 46.672 25.024 18.848 21.168 26.32 290.592 26.32 82.176 0 242.896-3.424 255.216-59.84 4.896-22.56-18.895-44.735-18.976-44.911-6.496-16.032.737-34.849 16.577-41.777.255-.128 64.143-23.007 65.6-58.72.96-22.831-14.72-36.543-15.072-37.12-9.328-14.463-5.92-34.303 8.224-44.16.16-.128 41.551-25.215 40.543-59.423-.784-27.168-36.576-46.289-37.664-46.928-8-4.576-13.824-12.496-15.648-21.552-1.792-9.04.224-18.528 5.84-25.872 0 0 16.272-25.856 15.68-50.112-1.168-51.92-57.007-53.552-68.992-53.552-80.72 0-288.03.816-288.03.816-11.184.048-20.864-5.232-26.88-14.176-6-8.945-6.448-20.048-2.928-30.224 31.263-90.032 48.72-231.28 19.727-279.536-8.544-14.224-10.496-28.432-42.496-28.432-4.432 0-14.991 3.504-25.999 29.744-106.928 255.84-266.64 403.824-397.456 417.168-11.28 25.728-32.496 79.04-32.496 175.775 0 98.737 31.28 175.12 46.305 199.84z"/></svg>
</div>

<br>
<div style="text-align: center; font-size: 24px; line-height: 1.5;">
  <b>Like a product to share the love.</b> 
</div>
<div style="text-align: center; font-size: 14px; line-height: 1.5;">

  Join Lookflock now. ️
</div>
<br>


`;

export const titleList = "Join the Social Shopping Experience Now! 😍";

export const dataList = `
<br>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  top: 0, left: 0, width: '100%', height: '100%',margin:'auto' }}>
<svg width="100px" height="100px" viewBox="0 -5 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" style="display: flex; justify-content: center; align-items: center; margin:auto;">
    

<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
    <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-206.000000, -626.000000)" fill="#000000">
        <path d="M207,626 C206.448,626 206,626.448 206,627 C206,627.553 206.448,628 207,628 C207.552,628 208,627.553 208,627 C208,626.448 207.552,626 207,626 L207,626 Z M233,634 L213,634 C212.448,634 212,634.448 212,635 C212,635.553 212.448,636 213,636 L233,636 C233.552,636 234,635.553 234,635 C234,634.448 233.552,634 233,634 L233,634 Z M233,642 L213,642 C212.448,642 212,642.448 212,643 C212,643.553 212.448,644 213,644 L233,644 C233.552,644 234,643.553 234,643 C234,642.448 233.552,642 233,642 L233,642 Z M207,634 C206.448,634 206,634.448 206,635 C206,635.553 206.448,636 207,636 C207.552,636 208,635.553 208,635 C208,634.448 207.552,634 207,634 L207,634 Z M207,642 C206.448,642 206,642.448 206,643 C206,643.553 206.448,644 207,644 C207.552,644 208,643.553 208,643 C208,642.448 207.552,642 207,642 L207,642 Z M213,628 L233,628 C233.552,628 234,627.553 234,627 C234,626.448 233.552,626 233,626 L213,626 C212.448,626 212,626.448 212,627 C212,627.553 212.448,628 213,628 L213,628 Z" id="list" sketch:type="MSShapeGroup">

</path>
    </g>
</g>
</svg>
</div>


<br>
<div style="text-align: center; font-size: 24px; line-height: 1.5;">
  <b>Save this product to your list.</b> 
  <br>
  
</div>
<div style="text-align: center; font-size: 14px; line-height: 1.5;">

  Join Lookflock now. ️
</div>
<br>

`;

export const titleFeedReaction ='Join the Social Shopping Experience Now! 😍'

export const dataFeedReaction = `
<br>
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  top: 0, left: 0, width: '100%', height: '100%',margin:'auto' }}>
<svg fill="#000000" width="100px" height="100px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"style="display: flex; justify-content: center; align-items: center; margin:auto;">
<path d="M608.544 1023.744c-290.832 0-293.071-12.062-329.087-39.183-19.104-14.368-55.151-24.32-186.815-32.896-9.552-.624-18.64-4.288-24.735-11.68-2.8-3.408-68.592-99.36-68.592-253.04 0-151.44 47.088-220.465 49.103-223.665a31.965 31.965 0 0 1 27.12-15.04c108.112 0 257.984-138 358.736-378.896C451.698 27.68 455.298.272 519.298.272c36.4 0 77.2 26.064 97.344 59.505 41.328 68.32 20.335 215.057.927 293.473 66-.528 185.472-1.425 242.32-1.425 79.072 0 131.407 47.152 132.991 116.08.529 22.752-2.464 51.808-9.04 66.848 17.408 17.36 39.857 43.536 40.832 77.248 1.216 43.52-27.28 76.655-45.472 95.663 4.175 12.656 12.527 29.44 11.71 49.505-2 49.344-40.095 81.136-63.823 97.727 1.968 13.504 3.504 38.976-.832 58.672-17.12 78.609-132.4 110.177-317.712 110.177zM109.617 886.77c114.688 9.489 175.998 22.336 208.334 46.672 25.024 18.848 21.168 26.32 290.592 26.32 82.176 0 242.896-3.424 255.216-59.84 4.896-22.56-18.895-44.735-18.976-44.911-6.496-16.032.737-34.849 16.577-41.777.255-.128 64.143-23.007 65.6-58.72.96-22.831-14.72-36.543-15.072-37.12-9.328-14.463-5.92-34.303 8.224-44.16.16-.128 41.551-25.215 40.543-59.423-.784-27.168-36.576-46.289-37.664-46.928-8-4.576-13.824-12.496-15.648-21.552-1.792-9.04.224-18.528 5.84-25.872 0 0 16.272-25.856 15.68-50.112-1.168-51.92-57.007-53.552-68.992-53.552-80.72 0-288.03.816-288.03.816-11.184.048-20.864-5.232-26.88-14.176-6-8.945-6.448-20.048-2.928-30.224 31.263-90.032 48.72-231.28 19.727-279.536-8.544-14.224-10.496-28.432-42.496-28.432-4.432 0-14.991 3.504-25.999 29.744-106.928 255.84-266.64 403.824-397.456 417.168-11.28 25.728-32.496 79.04-32.496 175.775 0 98.737 31.28 175.12 46.305 199.84z"/></svg>
</div>

<br>
<div style="text-align: center; font-size: 24px; line-height: 1.5;">
<b>Like a post to share the love.</b> 
</div>
<div style="text-align: center; font-size: 14px; line-height: 1.5;">

Join Lookflock now. ️
</div>
<br>


`;