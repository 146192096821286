import React from "react";
import { Link, useLocation } from "react-router-dom";
import useUrlState from "../StatesStore/CurrentURL";

function SignInMenuHeader() {
  const seturl = useUrlState((state) => state.seturl);
  const location = useLocation();
  return (
    <>
      <li className="nav-item ms-2">
        <Link
          className="btn btn-primary-soft"
          to="/SignIn"
          onClick={() => seturl(location.pathname)}
        >
          Log in
        </Link>
      </li>

      <li className="nav-item ms-2">
        <Link
          className="btn btn-primary-soft w-100"
          to="/SignUp"
          onClick={() => seturl(location.pathname)}
        >
          Sign up
        </Link>
      </li>
      {/* <Link
        className="nav-item btn btn-primary ms-2"
        id="contactDropdown"
        role="button"
        data-bs-auto-close="outside"
        data-bs-display="static"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        Contact us
      </Link>
      <ul
        className="dropdown-menu dropdown-animation dropdown-menu-end p-3 small me-md-n3"
        aria-labelledby="contactDropdown"
      >
        <Link
          to="/TandC"
          className="mt-2 text-body text-center  p-2 rounded-1 bg-light dropdown-item"
        >
          Terms of Services
        </Link>
        <Link
          to="/PrivacyPolicy"
          className="my-2 text-body text-center p-2 rounded-1 bg-light dropdown-item"
        >
          Privacy Policy
        </Link>
        <Link
          to="/contactUs"
          className="mb-2 text-body text-center p-2 rounded-1 bg-light dropdown-item"
        >
          Contact Us
        </Link>
      </ul> */}
    </>
  );
}

export default SignInMenuHeader;
