
import { doc, getDoc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../FirebaseConfig';

export const getBrandDoc = async (brandId) => {
 const brandDocRef = doc(db, 'brands', brandId);
 const brandDoc = await getDoc(brandDocRef);
 return brandDoc;
};

export const followBrand = async (brandId, userId) => {
 const brandDocRef = doc(db, 'brands', brandId);
 const followerDocRef = doc(brandDocRef, 'followers', userId);
 await setDoc(followerDocRef, { dateCreated: serverTimestamp() });
};

export const unfollowBrand = async (brandId, userId) => {
 const brandDocRef = doc(db, 'brands', brandId);
 const followerDocRef = doc(brandDocRef, 'followers', userId);
 await deleteDoc(followerDocRef);
};
