import React from "react";
import StaticLinks from "../components/StaticLinks";
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQs from "../old/FAQs";
import Support from "../old/Support";
import Documentation from "../old/Documentation";
import TermsOfService from "./TermsOfService";
import ScrollToTop from "./scrollToTop";
export default function StaticPages() {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <ScrollToTop />
      <Routes>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsOfService />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/support" element={<Support />} />
        <Route path="/documentation" element={<Documentation />} />
      </Routes>

      <StaticLinks />
    </div>
  );
}
