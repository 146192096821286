import React from "react";

function EmptyBox({ filename }) {
  return (
    <div className="card d-flex">
      <div className="my-sm-5 py-sm-5 d-flex justify-content-center align-items-center flex-column">
        <i class="display-1 bi bi-file-earmark-x"></i>
        <h4 className="mt-2 mb-1 text-body">{filename}</h4>
      </div>
    </div>
  );
}

export default EmptyBox;
