import React from "react";

function EmptyList({content}) {
  return (
    <div className="my-sm-5 py-sm-3 d-flex justify-content-center align-items-center flex-column">
      <i className="display-1 text-muted bi bi-bag-x"> </i>
      <h4 className="mt-2 mb-1 text-body">{content}</h4>
    </div>
  );
}

export default EmptyList;
