import React, { useState, useEffect } from "react";
import WebFont from "webfontloader";
import TermsAndConditions from "./TermsAndConditions";
import useOobCodeState from "../StatesStore/OobCode";
import useApiKeyState from "../StatesStore/ApiKey";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../FirebaseConfig";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import {
  applyActionCode,
  checkActionCode,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  updateEmail,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { UserAuth } from "../Context";
import { doc, updateDoc } from "firebase/firestore";
import { WelcomeUser } from "../EmailSystem/sendEmails";
function VerifyEmail() {
  const navigate = useNavigate();
  const oobCode = useOobCodeState((state) => state.oobCode);
  const apiKey = useApiKeyState((state) => state.apikey);
  const [message, setMessage] = useState("");
  const { user } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    console.log(user);
    WebFont.load({
      google: {
        families: ["Nunito Sans", "sans-serif"],
      },
    });

    if (oobCode && apiKey) {
      checkActionCode(auth, oobCode)
        .then((info) => {
          // Email verification code is valid
          // Apply the action code to complete the email verification
          applyActionCode(auth, oobCode)
            .then(async () => {
              try {
                console.log("VERIFYEMAILUSER", user);
                const userRef = doc(db, "users", user?.uid);
                await updateDoc(userRef, { isVerified: true });
                user.emailVerified = true;
                toast.success("Email verified successfully", toastOptions);
                setMessage("Email verified successfully!");
                await WelcomeUser(user?.displayName, user.email);
              } catch (error) {
                console.error(error);
              }
              // Email verified successfully
              console.log("Email verified successfully!");
              // You can now sign in the user or redirect them to the login page
              if (user) navigate("/SignInInput");
              else navigate("/SignIn");
            })
            .catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              setMessage(
                `Error verifying email in applying: ${errorMessage} ${errorCode}`
              );
              // console.error("Error applying action code:", errorCode, errorMessage);
            });
        })
        .catch((error) => {
          // Invalid action code
          const errorMessage = error.message;
          setMessage(`Error verifying email in checking: ${errorMessage}`);
          // console.error("Invalid action code:", errorCode, errorMessage);
        });
    } else {
      setMessage(`Hi ${user?.displayName ||
        user?.providerData[0].displayName ||
        ""}, Please verify your email address by
              clicking the link sent to ${user?.email ||
                user?.providerData[0]?.email}`);
    }
    // eslint-disable-next-line
  }, [oobCode, apiKey]);
  const resendEmailVerification = async () => {
    await sendEmailVerification(user);
    toast.success("Check your inbox for email verification link", toastOptions);
  };
  const changeEmailAddress = async () => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user?.providerData[0]?.email,
      password
    ); // Replace `currentPassword` with the user's current password

    try {
      // Reauthenticate the user

      await reauthenticateWithCredential(user, credential);
      // Verify before updating the email
      await verifyBeforeUpdateEmail(user, email);

      // Update the email
      await updateEmail(user, email);

      // Show success message
      toast.success("Email Updated Successfully");
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        toast.error("Incorrect Password. Please try again.");
      } else if (error.code === "auth/too-many-requests") {
        toast.error("Too many attempts. Please try again later.");
      } else {
        toast.error("Error Updating Email: " + error.message);
      }
    }
  };
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center vh-100 py-2 ">
        <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
          <div className="card card-body text-center rounded-3 p-4 ">
            <div className="text-center">
              <div className="text-body text-center fs-icon " to="/">
                <div
                  style={{
                    fontFamily: "Nunito Sans",
                  }}
                >
                  <i>lookflock&nbsp;</i>
                </div>
              </div>
            </div>
            <p className="mt-3 fs-4 text-body">Verify your email address</p>
            <hr className="mt-0" />
            <p className="fs-5">{message}</p>
            <div
              className="btn btn-primary w-50 m-auto mb-2"
              onClick={resendEmailVerification}
            >
              Resend Email Verification
            </div>
            {/* <div className="d-flex justify-content-center ">
              <input
                style={{ height: "2.5rem" }}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="w-75 mx-1 rounded-2 p-1"
                type="password"
                placeholder="Enter your current password"
              />
              <input
                style={{ height: "2.5rem" }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="w-75 mx-1 rounded-2 p-1"
                type="email"
                placeholder="Change email address"
              />
              <div
                className="btn btn-primary w-25 m-auto mb-2"
                onClick={changeEmailAddress}
              >
                Submit
              </div>
            </div> */}
            <TermsAndConditions />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
