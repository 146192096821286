import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import handleUnfollowUser from "../hooks/user/unfollowUser";
import handlefollowUser from "../hooks/user/followUser";
import handlefollowBrand from "../hooks/brand/followBrand";
import handleUnfollowBrand from "../hooks/brand/unfollowBrand";
import { UserAuth } from "../Context";
import axios from "axios"; // Assuming you are using axios for HTTP requests
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
export default function UserActiveFollowing({ followinglist, loggedInUser }) {
  const [userData, setUserData] = useState([]);
  const { user } = UserAuth();
  const { uid } = useParams();
  const myID = user;
  const [myfollowinglist, setMyFollowinglist] = useState([]);
  useEffect(() => {
    if (!followinglist || followinglist.length === 0) {
      setUserData([]);
      return;
    }

    const fetchUserData = async () => {
      try {
        const userList = await Promise.all(
          followinglist.map(async ({ id, type }) => {
            const collection = type === "user" ? "users" : "brands";
            console.log("Fetching document", { id, collection });

            const docRef = doc(db, collection, id);
            const docSnap = await getDoc(docRef);

            console.log("Document reference:", docRef);
            console.log("Document snapshot:", docSnap);

            if (docSnap.exists()) {
              console.log("Document data:", id, docSnap.data());
              return { ...docSnap.data(), uid: docSnap.id };
            } else {
              console.log("No such document!", { id, collection });
              return null;
            }
          })
        );

        const filteredUserList = userList.filter((user) => user !== null);
        console.log(
          "Fetched users/brands from active friends:",
          filteredUserList
        );
        setUserData(filteredUserList);
      } catch (error) {
        console.error("Error fetching users/brands:", error);
      }
    };
    const fetchMyFollowingList = async () => {
      try {
        // const response = await axios.get(process.env.REACT_APP_API+`/api/userFollowing/${uid}`);
        const response = await axios.get(
          process.env.REACT_APP_API + `/api/userFollowing/${myID.uid}`
        );
        console.log("response.data", response.data);
        setMyFollowinglist(response.data);
      } catch (error) {
        console.error("Error fetching following list:", error);
      }
    };
    fetchUserData();
    fetchMyFollowingList();
  }, [followinglist]);

  const isCurrentUserProfile = user?.uid === uid;

  return (
    <>
      {userData.map((user) => {
        return (
          <div className="d-md-flex align-items-center mb-4" key={user.uid}>
            <div className="avatar me-3 mb-3 mb-md-0">
              <Link
                className=""
                to={`${
                  user?.cover
                    ? `/BrandHome/${user.uid}`
                    : `/userProfile/${user.uid}`
                }`}
              >
                <ProfilePicture userData={user} avatarSize={42} />
              </Link>
            </div>
            <div className="w-100">
              <div className="d-sm-flex align-items-start">
                <h6 className="mb-0">
                  {user.userName ? (
                    <Link to={`/userProfile/${user.uid}`} className="h6 mb-0">
                      {user.userName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </Link>
                  ) : (
                    <Link to={`/BrandHome/${user.uid}`} className="h6 mb-0">
                      {user.displayName
                        .split(" ")
                        .slice(0, 2)
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </Link>
                  )}
                </h6>
                <p className="small ms-sm-2 mb-0"> {user.userTitle}</p>
              </div>
            </div>
            <div className="ms-md-auto d-flex">
              {isCurrentUserProfile && (
                <>
                  {user?.logo ? (
                    <button
                      className="btn btn-danger-soft btn-sm mb-0"
                      onClick={async () => {
                        await handleUnfollowBrand(loggedInUser, user.uid);
                        toast.success(
                          "Brand Unfollowed Successfully",
                          toastOptions
                        );
                      }}
                    >
                      Unfollow
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger-soft btn-sm mb-0"
                      onClick={async () => {
                        await handleUnfollowUser(loggedInUser, user.uid);
                        toast.success(
                          "Brand Unfollowed Successfully",
                          toastOptions
                        );
                      }}
                    >
                      Unfollow
                    </button>
                  )}
                </>
              )}
              {!isCurrentUserProfile && (
                <>
                  {!(myID.uid === user.uid) ? (
                    myfollowinglist.some(
                      (following) => following.id === user.uid
                    ) ? (
                      <button
                        className="btn btn-danger-soft me-2"
                        onClick={async () => {
                          user?.logo
                            ? await handleUnfollowBrand(loggedInUser, user.uid)
                            : await handleUnfollowUser(loggedInUser, user.uid);
                          toast.success(
                            "Brand Unfollowed Successfully",
                            toastOptions
                          );
                        }}
                      >
                        Unfollow
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary-soft me-2"
                        onClick={async () => {
                          user?.logo
                            ? await handlefollowBrand(loggedInUser, user.uid)
                            : await handlefollowUser(loggedInUser, user.uid);
                          toast.success(
                            "Brand followed Successfully",
                            toastOptions
                          );
                        }}
                      >
                        Follow
                      </button>
                    )
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
