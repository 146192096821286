import React, { useEffect, useState } from 'react';
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

const libraries = ['places'];

export default function GetLocation({ onLocationChange, location }) {
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (location) {
            // If location prop is provided, set it as the initial input value
            setInputValue(location);
        }
    }, [location]);

    //Function to load api key
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyDeRZ4H3Pg1YWsVb-ZX1GDqNN7hp7PaRw8", 
        libraries
    });

    //show filtered locations 
    const handlePlaceChanged = () => {
        const places = document.getElementById("location").value;
        setInputValue(places);
        onLocationChange(places);
    };

    return (
        <div className="mb-3">
            {isLoaded && (
                <StandaloneSearchBox
                    onLoad={() => { }}
                    onPlacesChanged={handlePlaceChanged}
                >
                    <>
                        <input
                            type="text"
                            id="location"
                            className="form-control"
                            placeholder="Enter Location"
                            style={{ borderRadius: 3 }}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />
                    </>
                </StandaloneSearchBox>
            )}
        </div>
    );
}
