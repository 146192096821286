import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import OneItemNews from "../NewsFeed/OneItemNews";
import ThreeItemNews from "../NewsFeed/ThreeItemNews";
import { useParams } from "react-router-dom";
import EmptyBox from "../components/EmptyBox";


export default function UserPosts({ userData,user}) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const { uid } = useParams();
  console.log("posts",posts);
  useEffect(() => {
    if (uid) {
      const fetchPosts = async () => {
        try {
          const postsRef = collection(db, "posts");
          const q = query(
            postsRef,
            where("userId", "==", uid),
            orderBy("dateCreated", "desc")
          );
          const querySnapshot = await getDocs(q);
          const posts = [];

          for (const docSnap of querySnapshot.docs) {
            const postData = { ...docSnap.data() };

            // If the post type is image, fetch images from the media subcollection
            if (postData.type === "image") {
              const mediaRef = collection(db, "posts", docSnap.id, "media");
              const mediaSnapshot = await getDocs(mediaRef);
              const images = mediaSnapshot.docs.map(
                (mediaDoc) => mediaDoc.data().url
              );
              postData.images =[ postData.thumbnail ,...images];
            }

            posts.push({ ...postData, id: docSnap.id });
          }

          if (posts.length > 0) {
            setPosts(posts);
          }
        } catch (error) {
          console.error("Error fetching posts:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPosts();
    }
  }, [uid]);

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : posts.length === 0 ? (
        <EmptyBox
          filename={user?.uid === uid? "Please create your first post"
              : `Stay tuned for ${userData?.userName}'s first post!`}/>
      ) : (
        posts.map((post, index) => (
          <div key={index}>
            {post.images?.length === 1 && <OneItemNews post={post} />}
            {post.images?.length >= 2 && <ThreeItemNews post={post} />}
          </div>
        ))
      )}
    </>
  );
}
