import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useModalStore from "../StatesStore/ModalStore";
import { dataFavourite, titleFavourite } from "../Content/ModalContent";
import { db } from "../FirebaseConfig";
import {
  collection,
  serverTimestamp,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  arrayUnion,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { toastOptions } from "../lib/toastOptions";
import uuid4 from "uuid4";
import usefavStore from "../StatesStore/FavStore";
function AddOrRemoveFavourites({ product, componentName, user, type }) {
  const { openModal } = useModalStore();
  const addFavoriteProduct = usefavStore((state) => state.addFavorite);
  const removeFavoriteProduct = usefavStore((state) => state.removeFavorite);
  const favoriteProduct = usefavStore((state) => state.favorites);
  const [isFavourite, setIsFavourite] = useState(false);
  useEffect(() => {
    setIsFavourite(favoriteProduct.includes(product?.id));
  }, [favoriteProduct]);
  // let url = process.env.REACT_APP_API+`/api/logs/${user?.uid}`

  // let data = {brand:product.supplier,category:product.category,subCategory:product.subCategory,subSubCategory:product.subSubCategory, newPrice:product.newPrice,discount:product.discount}

  // const { data: postData, loading, error, post } = usePost(url);
  // const { data: postData, loading, error, post } = usePost(url);

  const handleClick = () => {
    if (!user) {
      // Check if user is not logged in before opening modal
      openModal("SignInModal", titleFavourite, dataFavourite); // Open modal here
    }
  };
  console.log("favoriteProduct",favoriteProduct);
  const handleToggleFavorite = async (listName) => {
    if (!user) return;

    const userRef = doc(db, "users", user.uid);
    const shoppingListRef = collection(userRef, "shoppingList");

    try {
      const listDocRef = doc(shoppingListRef, listName);
      const existingListDoc = await getDoc(listDocRef);

      if (existingListDoc.exists()) {
        const productRef = doc(listDocRef, "products", product?.id);
        const productDoc = await getDoc(productRef);

        if (!productDoc.exists()) {
          // Add product to the 'products' subcollection
          await setDoc(productRef, {
            likeCount: 0,
            dateCreated: serverTimestamp(),
          });
          addFavoriteProduct(product?.id);

          // Increment the shoppingListCount
          await updateDoc(userRef, {
            shoppingListCount: increment(1),
          });

          toast.success(`${product?.name} added to ${listName}`, toastOptions);
        } else {
          // Remove the product from the 'products' subcollection
          await deleteDoc(productRef);
          removeFavoriteProduct(product?.id);

          // Decrement the shoppingListCount
          await updateDoc(userRef, {
            shoppingListCount: increment(-1),
          });

          toast.success(
            `${product?.name} removed from ${listName}`,
            toastOptions
          );
        }
      } else {
        // Create the list document if it does not exist
        await setDoc(listDocRef, {
          listId: uuid4(),
          dateCreated: serverTimestamp(),
          visibility: "Private",
        });

        // Add product to the new 'products' subcollection
        const productRef = doc(listDocRef, "products", product?.id);
        await setDoc(productRef, {
          likeCount: 0,
          dateCreated: serverTimestamp(),
        });

        addFavoriteProduct(product?.id);

        // Increment the shoppingListCount
        await updateDoc(userRef, {
          shoppingListCount: increment(1),
        });

        toast.success(`${product?.name} added to ${listName}`, toastOptions);
      }

      // Update product's favourites count
      const productRefMain = doc(
        db,
        type === "post" ? "posts" : "products",
        product?.id || product?.objectID
      );
      await updateDoc(productRefMain, {
        favourites: increment(isFavourite ? -1 : 1),
      });
    } catch (error) {
      console.error("Error handling favorites:", error.message);
    }
  };

  const iconClass = componentName === "productDescriptions" ? "me-1" : "";
  const iconClass1 = componentName === "productDescriptions" ? "icon-md" : "";

  return (
    <Link
      className={`nav-link ${
        componentName === "productDescriptions" ? "" : "icon-sm"
      } m-0 p-0 nav-item`}
      to=""
      // onClick={handleToggleFavorite}
      onClick={() => {
        handleToggleFavorite("Favorites");
        setIsFavourite(!isFavourite);
      }}
    >
      {!user && (
        <Link to="" className={`nav-link`} onClick={handleClick}>
          <i className={`bi bi-star fs-6 ${iconClass}`}></i>
          {/* {componentName === "productDescriptions" && (
            <span style={{ whiteSpace: "nowrap" }}>Add to Favorites</span>
          )} */}
        </Link>
      )}

      {user && (
        <>
          <i
            className={`${
              isFavourite
                ? `fa-solid fa-star fs-6 ${iconClass}`
                : `bi bi-star fs-6 ${iconClass}`
            }`}
          ></i>
          {/* {componentName === "productDescriptions" && (
            <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}></span>
          )} */}
        </>
      )}
    </Link>
  );
}

export default AddOrRemoveFavourites;
