import React from "react";
import { Card, Row, Col, Container } from "react-bootstrap";

const TestMasonaryCard = ({ post }) => {
  return (
    <Card className="m-2 m-sm-3 ">
      <Card.Img variant="top" src={post.image} alt="Aidan Knight" />
      <Card.Body>
        <Card.Title className="d-flex justify-content-between">
          <h3 className="fs-5 fw-normal">{post.brandName}</h3>
          <h3 className="fs-5 text-success">Rs.{post.price}</h3>
        </Card.Title>
        <Card.Text>{post.caption}</Card.Text>
      </Card.Body>
      <Card.Footer className="p-0 bg-black text-white">
        <div className="d-flex justify-content-evenly">
          <button type="button" class="btn py-3 fw-bold ">
            <i className={`bi bi-hand-thumbs-up-fill`}> </i> Like
          </button>
          <div class="vr"></div>
          <button type="button" class="btn py-3 fw-bold">
            <i class="bi bi-chat-dots-fill"></i> Comments
          </button>
          <div class="vr"></div>
          <button type="button" class="btn py-3 fw-bold">
            <i class="bi bi-list-ul"></i> Add to List
          </button>
          <div class="vr"></div>
          <button type="button" class="btn py-3 fw-bold">
            <i class="bi bi-eye-fill"></i> Views
          </button>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default TestMasonaryCard;
