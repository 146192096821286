import React, { useState } from 'react';
import { db } from './FirebaseConfig';
import { collection, addDoc } from "firebase/firestore";
import { Form, Button, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastOptions } from './lib/toastOptions';
function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    // const image = process.env.PUBLIC_URL + '/assets/images/contactUs/model.svg';
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "contactUs"), {
                name: name,
                email: email,
                subject: subject,
                message: message,
                createdAt: new Date()
            });
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');
            toast.success("Thank you for contacting us!", toastOptions)
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <div className="row justify-content-center align-items-center mt-5" >
            <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                <div className="card card-body rounded-3 p-4">
                    <h2 className='grey text-center'>Contact Us</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="my-2" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="my-2" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="my-2" controlId="formSubject">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="my-2" controlId="formMessage">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter your message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" size='sm' type="submit" className='w-100'>
                            Submit
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
