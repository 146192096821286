import React, { useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import DetailsBody from "./DetailsBody";
import "./style.css";

const TestBrandCard = ({ post, handleClick }) => {
  // const [openDetail, setOpenDetail] = useState(false);
  const product = post.images[0];
  return (
    <>
      {
        <Card>
          {product.imageUrl ? (
            <Card.Img
              onClick={handleClick}
              src={product.imageUrl}
              variant="top"
            />
          ) : (
            ""
          )}
          <Card.Body>
            <Card.Title className="d-lg-flex align-items-center justify-content-between">
              <h4 style={{ fontSize: "12px" }} className=" fw-normal  mb-0">
                {product.supplier}
              </h4>
              <h4 style={{ fontSize: "12px" }} className=" text-success mb-0">
                PKR {product.newPrice}
              </h4>
            </Card.Title>
            <Card.Text style={{ fontSize: "10px", marginBottom: "0px" }}>
              {product.name}
            </Card.Text>
            <button
              style={{
                bottom: "5%",
                right: "-5%",
                padding: "5px 8px",
                backgroundColor: "#91939c",
              }}
              className="position-absolute border border-1 border-dark  rounded-circle "
            >
              <i
                className={`bi bi-${product.imageUrl ? "image" : "quote"}`}
              ></i>
            </button>
          </Card.Body>
          <Card.Footer className="p-0 bg-black ">
            <div className="d-flex text-white justify-content-evenly">
              <button type="button" class="btn py-2">
                <i className={`bi bi-hand-thumbs-up-fill`}> </i>
              </button>
              <div class="vr "></div>
              <button type="button" class="btn py-2">
                <i class="bi bi-chat-dots-fill"></i>
              </button>
              <div class="vr"></div>
              <button type="button" class="btn py-2">
                <i class="bi bi-list-ul"></i>
              </button>
              <div class="vr"></div>
              <button type="button" class="btn py-2">
                <i class="bi bi-eye-fill "></i>
              </button>
            </div>
          </Card.Footer>
        </Card>
      }
    </>
  );
};

export default TestBrandCard;
