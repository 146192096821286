import { create } from 'zustand';


const useBrandProducts = create((set) => ({
  products: [],
  setBrandProduct: (newProducts) => set((state) => {

    // If newProducts is an empty array, reset products state to []
    if (Array.isArray(newProducts) && newProducts.length === 0) {
      return { products: [] };
    }

    // Create a map to ensure unique products based on their id
    const productMap = new Map(state.products.map(product => [product.id, product]));

    // Add new products to the map, overriding duplicates
    newProducts.forEach(product => {
      productMap.set(product.id, product);
    });

    // Convert the map back to an array
    const uniqueProducts = Array.from(productMap.values());
    return { ...state, products: uniqueProducts };
  }),
}));


export default useBrandProducts;