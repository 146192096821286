import { create } from "zustand";
import { devtools } from "zustand/middleware";

const SaveStore = (set) => ({
  savedProducts: [],

  addSave: (Id) => {
    set((state) => {
      if (state.savedProducts.includes(Id)) {
        return state; // No changes if the ID already exists
      }
      return {
        savedProducts: [Id, ...state.savedProducts],
      };
    });
  },

  removeSave: (Id) => {
    set((state) => ({
      savedProducts: state.savedProducts.filter((id) => id !== Id),
    }));
  },
});

const useSaveStore = create(devtools(SaveStore));

export default useSaveStore;
