// ProfilePicture.js
import React from "react";
import Avatar from "react-avatar";

const ProfilePicture = ({ userData, avatarSize, className }) => {
  if (!userData) {
    return null; // or return a loading state/component
  }

  const avatarInitials = userData?.userName
    ? userData?.userName[0]?.toUpperCase()
    : userData?.displayName?.toUpperCase();

  // Function to handle image load failure
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevents infinite loop in case fallback also fails
    e.target.src = `https://ui-avatars.com/api/?name=${avatarInitials}`;
  };

  if (userData?.photo) {
    // User's Image avatar
    return (
      <img
        className={`avatar-img rounded-2 ${className}`}
        src={userData?.photo}
        alt=""
        loading="lazy"
        onError={handleImageError} // Fallback on image error
      />
    );
  } else if (userData?.logo) {
    // Brand logo
    return (
      <img
        className={`avatar-img rounded-2 ${className}`}
        src={process.env.PUBLIC_URL + "/assets/images/brands/" + userData?.logo}
        alt=""
      />
    );
  } else {
    // Default initials avatar
    return (
      <img
        className={`avatar-img rounded-2 ${className}`}
        src={`https://ui-avatars.com/api/?name=${avatarInitials}`}
        alt=""
      />
    );
  }
};

export default ProfilePicture;
