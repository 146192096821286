import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  startAfter,
  limit,
  orderBy,
  doc,
  getDoc,
  deleteDoc
} from "firebase/firestore";
import { db } from "../FirebaseConfig";
import ProfilePicture from "../UserProfile/ProfilePicture";
import { onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";
import { formatTimestampForPost } from "../lib/formatTimestampForPost";
import useNotification from "../hooks/useNotification";

export default function ShowComment({ post, commentCount, setCommentCount,user }) {
  const [comments, setComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(3);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const { deleteNotification } = useNotification();

  //const [countLeft, setCountLeft] = useState(0);

  const commentsCollectionRef = collection(db, "posts", post.id, "comments");
  const fetchComments = () => {
    let q;
    if (lastVisible) {
      q = query(commentsCollectionRef, orderBy("timestamp", "desc"), startAfter(lastVisible), limit(3));
    } else {
      q = query(commentsCollectionRef, orderBy("timestamp", "desc"), limit(3));
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newComments = querySnapshot.docs.map((doc) => {
        const commentData = doc.data();
        return {
          id: doc.id,
          ...commentData,
          userName: "", // Initialize userName to empty string
          photo: "",
        };
      });

      Promise.all(
        newComments.map(async (comment) => {
          const userDocRef = doc(db, "users", comment.userId);
          const userSnapshot = await getDoc(userDocRef);
          if (userSnapshot.exists) {
            comment.userName = userSnapshot.data().userName; // Update userName
            comment.photo = userSnapshot.data().photo;
          } else {
            // Handle the case where the user document doesn't exist
            console.error(
              "User document not found for userId:",
              comment.userId
            );
          }
        })
      )
        .then(() => {
          setComments((prevComments) => {
            if (lastVisible) {
              // If there is a lastVisible, it means this is a load more operation
              return [...prevComments, ...newComments];
            } else {
              // If there is no lastVisible, it's a fresh load, so just set the new comments
              return newComments;
            }
          });
        })
        .catch((error) => {
          // Handle any errors during user data fetching
          console.error("Error fetching user data:", error);
        });
      // Ensure loading is set to false after data is fetched

      // Update lastVisible only if new comments are fetched

      if (newComments.length > 0) {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  };

  useEffect(() => {
    fetchComments();
  }, [post.id]);

  const loadMoreComments = () => {
    setLoading(true);
    fetchComments();
    setVisibleComments((prev) => prev + 3);
  };
  const handleDeleteComment = async (commentUID) => {
    console.log(commentUID)
    const commentDoc = doc(commentsCollectionRef, commentUID)
    await deleteDoc(commentDoc)
    setCommentCount((prev) => prev - 1);
    if (post.userId !== user?.uid) {
      await deleteNotification({
        userId: post?.userId,
        id: user?.uid,
        type: "postComment",
        by: "user",
        fields: {
          postId: post?.id,
          commentId:commentUID
        },
      });
    }
  }
  return (
    <>
      {/* Comment wrap START */}
      <ul className="comment-item-nested list-unstyled">
        {/* Comment item START */}
        {comments?.map((comment, index) => (
          <li key={index} className="comment-item mt-3">
            <div className="d-flex">
              {/* Avatar */}
              <div className="avatar avatar-xs ">
                <ProfilePicture
                  userData={comment}
                  className="avatar-img rounded-circle"
                />
              </div>
              {/* Comment by */}
              <div className="ms-2">
                <div className="bg-light rounded-start-top-0 p-3 rounded">
                  <div className="d-flex justify-content-end">
                    <div className="d-flex flex-column me-4 mb-1">
                      <h6 className="mb-0">
                        {" "}
                        <Link
                          to={
                            comment
                              ? `/userProfile/${comment.userId}`
                              : "/userProfile"
                          }
                        >
                          {" "}
                          {comment?.userName
                            .split(" ")
                            .slice(0, 2)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}{" "}
                        </Link>{" "}
                      </h6>
                      <small className="">
                        {formatTimestampForPost(comment?.timestamp)}
                      </small>
                    </div>
                    <Link className="text-danger ms-4" onClick={() => handleDeleteComment(comment.id)}>Delete</Link>
                  </div>
                  <p className="mb-0 text-dark">{comment?.content}</p>
                </div>
              </div>
            </div>
          </li>
        ))}

        {/* Comment item END */}
      </ul>
      {commentCount > visibleComments && !loading && (
        <div className="card-footer border-0 pt-0">
          <button
            className="btn btn-link btn-link-loader btn-sm text-secondary d-flex align-items-center"
            onClick={loadMoreComments}
            disabled={loading}
          >
            <div className="spinner-dots me-2">
              <span className="spinner-dot"></span>
              <span className="spinner-dot"></span>
              <span className="spinner-dot"></span>
            </div>
            Load more comments
          </button>
        </div>
      )}
      {/* Comment wrap END */}
    </>
  );
}
