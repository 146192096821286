import React, { useState } from "react";
import ProfilePicture from "./ProfilePicture";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from "../FirebaseConfig";

const UpdatePFP = ({ Uid, userData, avatarSize, className, onProfilePictureUpdate }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${Uid}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Update user data with the new profile picture URL in Firestore
      const userDocRef = doc(db, 'users', Uid);
      await updateDoc(userDocRef, {
        photo: downloadURL
      });

      console.log("Profile picture updated successfully:", downloadURL);

      // Call the callback to inform the parent component about the update
      onProfilePictureUpdate(); // Notify the parent to trigger re-render

    } catch (error) {
      console.error('Error uploading profile picture:', error);
    }
  };

  return (
    <div
      className="position-relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ProfilePicture userData={userData} avatarSize={avatarSize} className={className} />

      {isHovered && (
        <div
          className="position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center bg-dark bg-opacity-50 rounded-circle"
          style={{ cursor: "pointer" }}
          onClick={triggerFileInput}
        >
          <span className="text-white small">Change Picture</span>
        </div>
      )}

      <input
        type="file"
        id="fileInput"
        style={{ display: "none" }}
        onChange={handleProfilePictureChange}
        accept="image/*"
      />
    </div>
  );
};

export default UpdatePFP;