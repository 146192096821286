import React, { useState, useEffect } from "react";
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { Slider, InputNumber } from "antd";
import DiscountCount from "../Brands/DiscountCount";
import CountGender from "./CategoryProductCount";
import useSearchedProducts from "../StatesStore/searchedProducts";
import useSearchFilter from "../StatesStore/SearchFilters";
export function SideNavBrandDropdown({ dataId }) {
  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);

  const handleBrandChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = SearchFilter;
    let updatedBrands = [...currentFilter.brand];

    if (checked) {
      updatedBrands.push(name);
    } else {
      updatedBrands = updatedBrands.filter((brand) => brand !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      brand: updatedBrands,
    };

    setSearchFilter(updatedFilter);
    // console.log('Current filter state in Brands:', updatedFilter);
  };

  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);

  const getBrands = async () => {
    try {
      const brandsCollectionRef = collection(db, "brands");
      const brandsQuery = query(brandsCollectionRef, orderBy("name", "asc"));
      const brandsSnapshot = await getDocs(brandsQuery);
      const brandsData = brandsSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setBrands(brandsData);
    } catch (error) {
      console.error("Error fetching brands:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBrands();
  }, []); // Fetch brands only once on component mount

  return (
    <div className="collapse show" id={dataId}>
      <div className="overflow-auto" style={{ maxHeight: "300px" }}>
        {brands.map((brand) => (
          <div
            key={brand.id}
            className="text-wrap align-items-center my-2"
          >
            <input
              className="form-check-input"
              type="checkbox"
              name={brand.id}
              onChange={handleBrandChange}
              checked={SearchFilter.brand.includes(brand.id)}
            />
            <label className="form-check-label mx-2">
              {brand.displayName}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export function SideNavPriceRangeDropDown({ dataId }) {
  // State to hold the current value of the slider
  const [range, setRange] = useState([0, 90000]); // Initial value set to [0, 90000]

  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);

  // Function to handle changes in the slider value
  const handleRangeChange = (newRange) => {
    setRange(newRange);
    const updatedFilter = {
      ...SearchFilter,
      minprice: newRange[0],
      maxprice: newRange[1],
    };
    setSearchFilter(updatedFilter);
    // console.log('Current filter state:', updatedFilter);
  };

  // Function to handle changes in the min value input box
  const handleMinInputChange = (value) => {
    const newRange = [value, range[1]];
    setRange(newRange);
    const updatedFilter = {
      ...SearchFilter,
      minprice: value,
      maxprice: range[1],
    };
    setSearchFilter(updatedFilter);
  };

  // Function to handle changes in the max value input box
  const handleMaxInputChange = (value) => {
    const newRange = [range[0], value];
    setRange(newRange);
    const updatedFilter = {
      ...SearchFilter,
      minprice: range[0],
      maxprice: value,
    };
    setSearchFilter(updatedFilter);
  };

  return (
    <div className="collapse show" id={dataId}>
      <div className="flex-1 justify-content-center ">
        {/* Price Range Slider */}
        <div className="rounded-5 mb-3">
          <Slider
            range
            value={range}
            onChange={handleRangeChange}
            min={0}
            max={90000}
            step={500}
            tipFormatter={(value) => `${value}`}
          />
        </div>

        <div className="d-flex gap-2">
          <div className="d-flex flex-column ">
            <label>Min</label>
            <InputNumber
              className="w-100"
              min={0}
              max={90000}
              value={range[0]}
              onChange={handleMinInputChange}
              placeholder={range[0]}
            />
          </div>
          <div className="d-flex flex-column ">
            <label>Max</label>
            <InputNumber
              className="w-100"
              min={0}
              max={90000}
              value={range[1]}
              onChange={handleMaxInputChange}
              placeholder={range[1]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export function SideNavGenderDropDown({ dataId }) {
  const setSearchFilter = useSearchFilter((state) => state.setSearchFilter);
  const SearchFilter = useSearchFilter((state) => state.SearchFilter);
  const handleGenderChange = (e) => {
    const { name, checked } = e.target;
    const currentFilter = useSearchFilter.getState().SearchFilter;
    let updatedGender = [...currentFilter.gender];

    if (checked) {
      updatedGender.push(name);
    } else {
      updatedGender = updatedGender.filter((g) => g !== name);
    }

    const updatedFilter = {
      ...currentFilter,
      gender: updatedGender,
    };

    setSearchFilter(updatedFilter);
    // console.log('Current filter state in Gender', updatedFilter);
  };

  return (
    <div className="collapse show" id={dataId}>
      {/* Gender filter section */}
      <ul className="gender-filter list-unstyled">
        <li className="form-check">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Men"
                id={`${dataId}-men`}
                onChange={handleGenderChange} // Call handleGenderChange on change
                checked={SearchFilter.gender.includes("Men")} // Check if "Men" is included in the gender filter array
              />
              <label className="form-check-label" htmlFor={`${dataId}-men`}>
                Men
              </label>
            </div>
          </div>
        </li>
        <li className="form-check">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                name="Women"
                id={`${dataId}-women`}
                onChange={handleGenderChange} // Call handleGenderChange on change
                checked={SearchFilter.gender.includes("Women")} // Check if "Women" is included in the gender filter array
              />
              <label className="form-check-label" htmlFor={`${dataId}-women`}>
                Women
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}
