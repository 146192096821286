import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../FirebaseConfig";
import { useState } from "react";

export default function IncomepleteProfile({ user }) {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const checkWarningTime = async () => {
      const userDocRef = doc(db, "users", user?.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (!userDocSnap.exists()) {
        console.log("No such document!");
        return;
      }

      const userData = userDocSnap.data();
      const warningTime = userData?.warningTime;

      if (!warningTime) {
        setShowWarning(true);
        await setDoc(
          userDocRef,
          { warningTime: serverTimestamp() },
          { merge: true }
        );
        return;
      }

      const currentTime = new Date().getTime();
      const warningTimeMillis = warningTime.toDate().getTime();

      if (currentTime - warningTimeMillis >= 24 * 60 * 60 * 1000) {
        setShowWarning(true);
        await setDoc(
          userDocRef,
          { warningTime: serverTimestamp() },
          { merge: true }
        );
      } else {
        setShowWarning(false);
      }
    };

    checkWarningTime();
  }, [user?.uid]);

  if (!showWarning) {
    return null;
  }
  return (
    <>
      <div
        className="alert alert-success alert-dismissible fade show mb-0"
        role="alert"
      >
        <strong>Incomplete Profile:</strong> Complete your profile for the best
        experience.
        <Link to="/SignInInput" className="justify-content-center">
          <n /> Click here
        </Link>{" "}
        to complete your profile.
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </>
  );
}
