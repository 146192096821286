import React, { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { UserAuth } from "../Context";
export default function RightSideBarProfilePage() {
  const { user } = UserAuth();
  const { uid } = useParams();
  const [userData, setUserData] = useState(null);
  let privacy = "private";
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (uid) {
          const userprofiledocref = doc(db, "users", uid);
          onSnapshot(userprofiledocref, (doc) => {
            if (doc.exists()) {
              setUserData(doc.data());
            } else {
              console.log("User document does not exist.");
            }
          });
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchUserData();
  }, [uid]);
  privacy = userData?.privacySettings?.accountSetting || "private";
  const isCurrentUserProfile = user?.uid === uid;
  const Friendlist = userData?.friendRequests?.active || [];
  const isUserInFriendList = Friendlist.includes(user?.uid);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const year = date.getFullYear();

    // Pad the day and month with a leading zero if they are single digits
    const dayWithLeadingZero = day < 10 ? `0${day}` : day;
    const monthWithLeadingZero = month < 10 ? `0${month}` : month;

    return `${dayWithLeadingZero}-${monthWithLeadingZero}-${year}`;
  }

  // console.log('User About right side', userData);

  return (
    <>
      {/* Right sidebar START */}
      {(isCurrentUserProfile || privacy === "public" || isUserInFriendList) && (
        <div className="col-lg-3 ps-0">
          <div className="row g-4">
            {/* Card START */}
            <div className="col-sm-6 col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="card-title">About</h5>
                  {/* Button modal */}
                </div>
                {/* Card body START */}
                <div className="card-body position-relative pt-0">
                  <p>{userData?.overview || "Overview not available"}</p>
                  {/* Date time */}
                  <ul className="list-unstyled mt-3 mb-0">
                    <li className="mb-2">
                      <i className="bi bi-calendar-date fa-fw pe-1"></i>{" "}
                      Birthday:
                      <strong>
                        {" "}
                        {formatDate(userData?.birthday) || "N/A"}{" "}
                      </strong>
                    </li>
                    {/* <li className="mb-2">
                    <i className="bi bi-heart fa-fw pe-1"></i> Status:
                    <strong> Single </strong>
                  </li> */}
                    <li>
                      <i className="bi bi-envelope fa-fw pe-1"></i> Email:
                      <strong> {userData?.email || "N/A"} </strong>
                    </li>
                  </ul>
                </div>
                {/* Card body END */}
              </div>
            </div>
          </div>
          {/* Right sidebar END */}
        </div>
      )}
    </>
  );
}
