import React, { useEffect } from "react";
// import BrandsCard from "./Brands/BrandsCard";
import RightSideBar from "./SideNav/RightSideBar";
import NewsFeed from "./NewsFeed/NewsFeed";
import NewsFeed2 from "./NewsFeed/NewsFeed2";
import ClassicView from "./Experiments/Classic/Classic";
import { UserAuth } from "./Context"; // import UserAuth from your Context.js
import UnderAgeWarning from "./Notifications/UnderAgeWarning"; // import your badge component
import CollumnPage from "./Experiments/CollumnPage";
import ColumnNewsFeed from "./Experiments/ColumnNewsFeed";
import Adsense from "./Adsense";
import ShareFeed from "./NewsFeed/ShareFeed";
import NewsFeed3 from "./NewsFeed/NewsFeed3";
import useModalStore from "./StatesStore/ModalStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import useModeState from "./StatesStore/Mode";
import useOobCodeState from "./StatesStore/OobCode";
import useApiKeyState from "./StatesStore/ApiKey";
import GoToTop from "./components/gotoTop";
import IncomepleteProfile from "./Notifications/IncompleteProfile";
import NewsFeed4 from "./NewsFeed/NewsFeed4";
import NewsFeed5 from "./NewsFeed/NewsFeed5";
function Home() {
  const { user, underAge, parentalConsent, incompleteProfile } = UserAuth(); // use the UserAuth hook to access the user data
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setmode = useModeState((state) => state.setmode);
  setmode(searchParams.get("mode") || null);
  const setoobCode = useOobCodeState((state) => state.setoobCode);
  setoobCode(searchParams.get("oobCode") || null);
  const setapikey = useApiKeyState((state) => state.setapikey);
  setapikey(searchParams.get("apiKey") || null);
  const mode = useModeState((state) => state.mode);
  const { closeModal } = useModalStore();
  useEffect(() => {
    if (!user) return;
    console.log("USERRRAAAUUUUTTTHHH", user);
    console.log("USERRRAAAUUUUTTTHHH EMAIL VERIFIED", user.emailVerified);
    if (!user.emailVerified) {
      console.log("Going to verify email, byeee");
      navigate("/VerifyEmail");
    }
    // else {
    //   navigate("/");
    // }
  }, [user]);

  useEffect(() => {
    console.log("mode", mode);
    closeModal();
    if (mode === "resetPassword") navigate("/ForgotPassword");
    else if (mode === "verifyEmail") navigate("/VerifyEmail");
  }, []);

  return (
    <>
      <div className="col-md-8 col-lg-6 vstack gap-4">
        {/* <BrandsCard /> */}
        {user && underAge && !parentalConsent ? (
          <UnderAgeWarning user={user} />
        ) : null}
        {user && incompleteProfile ? <IncomepleteProfile user={user} /> : null}
        {/* <Adsense /> */}
        {/* {user &&<ShareFeed/>} */}
        {/* <NewsFeed /> */}
        {/* <NewsFeed3/> */}
        <GoToTop />
        {/* <NewsFeed2 /> */}
        <NewsFeed4 />
        {/* <NewsFeed5 /> */}
        {/* <ColumnNewsFeed /> */}
        {/* <CollumnPage /> */}
        {/* <ClassicView /> */}
      </div>
      {/* <RightSideBar /> */}
    </>
  );
}

export default Home;
